import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikRating } from "../../../../../components/FormikFields";
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'


export default class ReviewedProjectForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {

    const { data, onSubmit, mode, t } = this.props;

    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            enableReinitialize
            initialValues={{
              ...data,
              currency: data.currency || "" ,
              valueOfProject: data.valueOfProject || "" ,
              personalContribution: data.personalContribution || "" ,
              loanAmount: data.loanAmount || "",
              mainExpenses: data.mainExpenses || "",
              unitPrice: data.unitPrice || "",
              salesPlanSecondyear: data.salesPlanSecondyear || "",
              salesPlanFirstyear: data.salesPlanFirstyear || "",
            }}
            onSubmit={onSubmit}>
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-12 px-5 text-center" style={{ fontWeight: 'bold' }}>{t("project.general")}</FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("title")}
                    name="title"
                    disabled={mode === "view" || 'edit'}
                    value={values.title}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.country")}
                    name="country"
                    disabled={mode === "view" || 'edit'}
                    value={values.country}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.city")}
                    name="city"
                    disabled={mode === "view" || 'edit'}
                    value={values.city}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("currency")}
                    name="currency"
                    disabled={mode === "view" || 'edit'}
                    value={values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.value")}
                    name="valueOfProject"
                    disabled={mode === "view" || 'edit'}
                    value={values.valueOfProject+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("ad.category")}
                    name="activity"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.activity && values.activity.map(item => ` ${item.name} \n`)}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("prject.personal-c")}
                    name="personalContribution"
                    disabled={mode === "view" || 'edit'}
                    value={values.personalContribution+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.isCompany")}
                    name="isCompany"
                    disabled={mode === "view" || 'edit'}
                    value={values.isCompany && values.isCompany === true ? 'Yes' : 'No'}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                {
                  values.isCompany === true &&
                  <>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.companyName")}
                        name="companyName"
                        disabled={mode === "view" || 'edit'}
                        value={values.companyName}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("project.creation")}
                        name="creationDate"
                        disabled={mode === "view" || 'edit'}
                        value={values.creationDate && values.creationDate.substring(0, 10)}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("project.company-type")}
                        name="typeOfCompany"
                        disabled={mode === "view" || 'edit'}
                        value={values.typeOfCompany}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("ad.website")}
                        name="companyWebsite"
                        disabled={mode === "view" || 'edit'}
                        value={values.companyWebsite}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("project.business")}
                        name="businessPlan"
                        disabled={mode === "view" || 'edit'}
                        value={values.businessPlan}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("project.co")}
                        name="isCoFounders"
                        disabled={mode === "view" || 'edit'}
                        value={values.isCoFounders && values.isCoFounders === true ? 'Yes' : 'No'}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    {
                      values.isCoFounders === true &&
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("project.co-how")}
                          name="countOfCoFounders"
                          disabled={mode === "view" || 'edit'}
                          value={values.countOfCoFounders}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                    }
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("project.employee")}
                        name="isEmployees"
                        disabled={mode === "view" || 'edit'}
                        value={values.isEmployees && values.isEmployees === true ? 'Yes' : 'No'}
                        onChange={setFieldValue}
                      />
                    </FormGroup>
                    {
                      values.isEmployees === true &&
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("project.employes-how")}
                          name="countOfEmployees"
                          disabled={mode === "view" || 'edit'}
                          value={values.countOfEmployees}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                    }
                  </>
                }
                <FormGroup className="col-12 px-5 text-center" style={{ fontWeight: 'bold' }}>{t("project.profile")}</FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.firstName")}
                    name="firstName"
                    disabled={mode === "view" || 'edit'}
                    value={values.firstName}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.lastName")}
                    name="lastName"
                    disabled={mode === "view" || 'edit'}
                    value={values.lastName}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("user.dob")}
                    name="dateOfBirth"
                    disabled={mode === "view" || 'edit'}
                    value={values.dateOfBirth && values.dateOfBirth.substring(0, 10)}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.nationality")}
                    name="currentNationality"
                    disabled={mode === "view" || 'edit'}
                    value={values.currentNationality}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.origin-county")}
                    name="countyOfOrigin"
                    disabled={mode === "view" || 'edit'}
                    value={values.countyOfOrigin}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.telephone")}
                    name="telephone"
                    disabled={mode === "view" || 'edit'}
                    value={values.telephone}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.phone")}
                    name="phone"
                    disabled={mode === "view" || 'edit'}
                    value={values.phone}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.email")}
                    name="email"
                    disabled={mode === "view" || 'edit'}
                    value={values.email}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.address")}
                    name="address"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.address}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.professional")}
                    name="professionalTraining"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.professionalTraining && values.professionalTraining.map(item => item).join('\n')}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-5 text-center" style={{ fontWeight: 'bold' }}>{t("project.operation")}</FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("project.products")}
                    name="services"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.services}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("project.unit-price")}
                    name="unitPrice"
                    disabled={mode === "view" || 'edit'}
                    value={values.unitPrice+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("project.firstyear")}
                    name="salesPlanFirstyear"
                    disabled={mode === "view" || 'edit'}
                    value={values.salesPlanFirstyear+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("project.second")}
                    name="salesPlanSecondyear"
                    disabled={mode === "view" || 'edit'}
                    value={values.salesPlanSecondyear+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.expenses")}
                    name="mainExpenses"
                    disabled={mode === "view" || 'edit'}
                    value={values.mainExpenses+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.hours")}
                    name="hoursOfOperation"
                    disabled={mode === "view" || 'edit'}
                    value={values.hoursOfOperation}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.suppliers")}
                    name="keySuppliers"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.keySuppliers}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.customers")}
                    name="mainCustomers"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.mainCustomers}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("project.clients")}
                    name="clientData"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.clientData}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-5 text-center" style={{ fontWeight: 'bold' }}>{t("project.financial")}</FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.isBank")}
                    name="isBusinessWithBank"
                    disabled={mode === "view" || 'edit'}
                    value={values.isBusinessWithBank && values.isBusinessWithBank === true ? 'Yes' : 'No'}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.which-bank")}
                    name="whichBank"
                    disabled={mode === "view" || 'edit'}
                    value={values.whichBank}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.loan")}
                    name="isLoanForYourProject"
                    disabled={mode === "view" || 'edit'}
                    value={values.isLoanForYourProject && values.isLoanForYourProject === true ? 'Yes' : 'No'}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("project.loan-how")}
                    name="loanAmount"
                    disabled={mode === "view" || 'edit'}
                    value={values.loanAmount+ " " + values.currency}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-5 text-center" style={{ fontWeight: 'bold' }}>{t("project.other")}</FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("project.details")}
                    name="details"
                    multiline={true}
                    disabled={mode === "view" || 'edit'}
                    value={values.details}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <label className="px-4">{t("job.rating")}</label>
                <FormikRating
                  name="rating"
                  emptyIcon={<FaStar size={30} />}
                  halfIcon={<FaStarHalfAlt size={40} />}
                  filledIcon={<FaStar size={30} />}
                  value={values.rating}
                  size={70}
                  count={5}
                  color={"#E0E0E0"}
                  activeColor={"#005288"}
                  a11y={true}
                  isHalf={true}
                  onChange={(e) => values.rating = e}
                  error={errors.rating}
                />
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("job.review")}
                    name="ratingComments"
                    value={values.ratingComments}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    error={errors.ratingComments}
                  />
                </FormGroup>
                {mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button type="submit" color="success" className="my-4 mx-auto">
                      {t("price.update")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}


