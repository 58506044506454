import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Slider,
  TextField,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import clsx from "clsx";
import React, { forwardRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import PhoneInput from "react-phone-number-input/input";
import { CustomInput } from "reactstrap";
import ReactStars from "react-rating-stars-component";
import { components } from "react-select";

const phoneInput = (props, ref) => <TextField {...props} inputRef={ref} />;
const PhoneInputWrapper = forwardRef(phoneInput);

export const FormikMaskInput = (props) => {
  const handleChange = (value) => {
    !props.disabled && props.onChange && props.onChange(props.name, value);
  };

  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };

  return (
    <PhoneInput
      label={props.label}
      error={Boolean(props.error)}
      helperText={props.error}
      inputProps={{ style: { height: "1.75em" } }}
      style={{ ...(props.disabled && { pointerEvents: "none" }) }}
      fullWidth
      inputComponent={PhoneInputWrapper}
      value={props.value}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
};

export const FormikSlider = (props) => {
  const handleChange = (_e, value) => {
    !props.disabled && props.onChange && props.onChange(props.name, value);
  };

  return (
    <FormControl
      fullWidth
      style={{ ...(props.disabled && { pointerEvents: "none" }) }}
    >
      <FormLabel className="mt-3">{`${props.label} : ${props.value}`}</FormLabel>
      <Slider className="pt-3" value={props.value} onChange={handleChange} />
    </FormControl>
  );
};

export class FormikCodeInput extends React.Component {
  handleChange = (value) => {
    !this.props.disabled &&
      this.props.onChange &&
      this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    this.props.onBlur && this.props.onBlur(this.props.name, true);
  };

  render() {
    const inputStyle = {
      MozAppearance: "textfield",
      height: "35px",
      width: "35px",
      textAlign: "center",
      fontSize: "18px",
      lineHeight: "21px",
      color: "#4f4f4f",
      border: "none",
      borderBottom: "1px solid #d7d7d7",
    };
    const props = {
      inputStyle,
      inputStyleInvalid: {
        ...inputStyle,
        borderBottom: "1px solid #e2142d",
      },
    };
    const { fields, split, value, name, error } = this.props;
    return (
      <>
        <ReactCodeInput
          name={name}
          className={clsx(split && "split", this.props.className)}
          type="number"
          inputMode="number"
          fields={fields || 5}
          autoFocus={false}
          onChange={this.handleChange}
          touch={this.handleBlur}
          untouch={() => {}}
          value={value}
          isValid={!error}
          {...props}
        />
        <div className="invalid-feedback text-center">{error}</div>
      </>
    );
  }
}

export const FormikInput = (props) => {
  const handleChange = (e) => {
    !props.disabled &&
      props.onChange &&
      props.onChange(
        props.name,
        props.type === "number"
          ? parseFloat(e.target.value) || ""
          : e.target.value
      );
  };

  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };

  const [showText, setToggle] = useState(false);

  const toggle = () => setToggle(!showText);

  return (
    <>
      <TextField
        className={props.className || ""}
        minRows={props.multiline && (props.rows || 2)}
        required={props.required ? true : false}
        multiline={props.multiline}
        type={
          props.type === "password"
            ? showText
              ? "text"
              : "password"
            : props.type || "text"
        }
        error={Boolean(props.error)}
        helperText={props.error}
        variant={props.variant}
        inputProps={{ min: "0", step: "any" }}
        InputProps={{
          ...(props.icon && {
            startAdornment: (
              <InputAdornment position="start">
                <i className={"fi " + props.icon} />
              </InputAdornment>
            ),
          }),
          ...(props.type === "password" && {
            endAdornment: (
              <InputAdornment position="end">
                <i
                  className="fi flaticon-view-1 pointer p-1 h5 mb-0"
                  onClick={toggle}
                />
              </InputAdornment>
            ),
          }),
        }}
        style={{ ...(props.disabled && { pointerEvents: "none" }) }}
        fullWidth
        label={props.label}
        value={props.value}
        onChange={handleChange}
        onBlur={handleBlur}
        maxrows={5}
      />
      {/* <div className="invalid-feedback text-center">{props.error}</div> */}
    </>
  );
};

export function FormikSelect(props) {
  const handleChange = (e) => {
    !props.disabled &&
      props.onChange &&
      props.onChange(props.name, e.target.value);
  };

  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };

  return (
    <FormControl
      fullWidth
      error={Boolean(props.error)}
      style={{ ...(props.disabled && { pointerEvents: "none" }) }}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        multiple={props.multiple}
        onChange={handleChange}
        defaultValue={props.defaultValue}
        onClose={handleBlur}
        {...(props.disabled && { IconComponent: () => <span /> })}
        input={
          <Input
            inputProps={{
              ...(props.status &&
                props.value && {
                  className: clsx("status", props.value),
                }),
            }}
          />
        }
        value={props.value}
      >
        {props.showNone && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {props.options.map((item, i) => (
          <MenuItem
            className={clsx(
              props.status && "status",
              props.status && item.value
            )}
            key={i}
            value={item.value || item.id}
          >
            {item.label || item.name}
          </MenuItem>
        ))}
      </Select>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </FormControl>
  );
}

export class FormikCheckboxGroup extends React.Component {
  handleChange = (val) => {
    let valueArray = [...this.props.value] || [];
    if (!valueArray.includes(val)) {
      valueArray.push(val);
    } else {
      valueArray.splice(valueArray.indexOf(val), 1);
    }
    this.props.onChange && this.props.onChange(this.props.name, valueArray);
  };

  handleBlur = () => {
    this.props.onBlur && this.props.onBlur(this.props.name, true);
  };

  render() {
    const { name, value, options, inline = false } = this.props;
    return (
      <React.Fragment>
        {options.map((child, index) => {
          return (
            <div
              key={`${name}_${child.value}_${index}`}
              className={`position-relative form-check ${
                inline ? "form-check-inline" : ""
              }`}
            >
              <input
                id={child.value}
                name={name}
                type="checkbox"
                className="form-check-input"
                onChange={() => this.handleChange(child.value)}
                onBlur={this.handleBlur}
                checked={value.includes(child.value)}
                disabled={child.disabled}
              />
              <label
                className="form-check-label"
                onClick={() => this.handleChange(child.value)}
              >
                {child.label}
              </label>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export const FormikCheckbox = (props) => {
  const handleChange = () => {
    props.onChange && props.onChange(props.name, !props.value);
  };
  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };
  return (
    <div className={`position-relative form-check form-check-inline`}>
      <input
        name={props.name}
        type="checkbox"
        className="form-check-input"
        onChange={handleChange}
        onBlur={handleBlur}
        checked={props.value}
        disabled={props.disabled}
      />
      <label onClick={handleChange} className="form-check-label">
        {props.label}
      </label>
    </div>
  );
};

export const FormikCustomCheckbox = (props) => {
  const handleChange = () => {
    !props.disabled &&
      props.onChange &&
      props.onChange(props.name, !props.value);
  };

  const handleBlur = () => {
    props.onBlur && props.onBlur(props.name, true);
  };

  return (
    <CustomInput
      type="checkbox"
      id={props.name}
      name={props.name}
      label={props.label}
      onChange={handleChange}
      onBlur={handleBlur}
      checked={props.value}
      disabled={props.disabled}
      inline={props.inline}
    />
  );
};

export class FormikRadioButtonGroup extends React.Component {
  handleChange = (val) => {
    !this.props.disabled &&
      this.props.onChange &&
      this.props.onChange(this.props.name, val);
  };

  handleBlur = () => {
    this.props.onBlur && this.props.onBlur(this.props.name, true);
  };

  render() {
    const { name, value, options, inline = false } = this.props;
    return (
      <React.Fragment>
        {options.map((child, index) => {
          return (
            <div
              key={`${name}_${child.value}_${index}`}
              className={`position-relative form-check ${
                inline ? "form-check-inline" : ""
              }`}
            >
              <input
                name={name}
                type="radio"
                className="form-check-input"
                onChange={() => this.handleChange(child.value)}
                onBlur={this.handleBlur}
                checked={value === child.value}
                disabled={child.disabled}
              />
              <label
                onClick={() => this.handleChange(child.value)}
                className="form-check-label"
              >
                {child.label}
              </label>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

export class FormikDatePicker extends React.Component {
  handleChange = (val) => {
    this.props.onChange && this.props.onChange(this.props.name, val);
  };

  handleBlur = () => {
    this.props.onBlur && this.props.onBlur(this.props.name, true);
  };

  render() {
    const { name, value, disabled } = this.props;
    return (
      <DatePicker
        disabled={disabled}
        name={name}
        variant="inline"
        selected={value}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        format="DD/MM/yyyy"
      />
    );
  }
}

export class FormikServiceCheckboxGroup extends React.Component {
  handleChange = (val) => {
    let valueArray = [...this.props.value] || [];
    if (!valueArray.includes(val)) {
      valueArray.push(val);
    } else {
      valueArray.splice(valueArray.indexOf(val), 1);
    }
    this.props.onChange && this.props.onChange(this.props.name, valueArray);
  };

  render() {
    const { name, value, options, disabled, inline = false } = this.props;
    return (
      <React.Fragment>
        {options.map((child, index) => {
          return (
            <div
              key={`${name}_${child.terminalId}_${index}`}
              className={`position-relative my-4 col-4 service-check ${
                inline ? "service-check-inline" : ""
              }`}
            >
              <div
                id={child.terminalId}
                onClick={() => !disabled && this.handleChange(child.terminalId)}
                className={clsx(
                  "btn w-50",
                  value.includes(child.terminalId) ? "btn-dark" : "btn-success",
                  disabled && "disabled"
                )}
              >
                {child.terminalName}
              </div>
            </div>
          );
        })}
      </React.Fragment>
    );
  }
}

/* formik rating component */
export const FormikRating = (props) => {
  const handleChange = (e) => {
    props.onChange(e);
  };

  const ratingComponent = {
    className: props.className,
    size: props.size,
    count: props.count,
    color: props.color,
    activeColor: props.activeColor,
    value: props.value,
    a11y: props.a11y,
    isHalf: props.isHalf,
    emptyIcon: props.emptyIcon,
    halfIcon: props.halfIcon,
    filledIcon: props.filledIcon,
    disabled: props.disabled,
    onChange: (e) => handleChange(e),
  };

  return <ReactStars {...ratingComponent} />;
};

export const CustomSelectInput = (props) => {
  var customProps = Object.assign({}, props);
  delete customProps.autoCorrect;
  delete customProps.autoCapitalize;
  return <components.Input {...customProps} />;
};
