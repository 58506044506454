import React, { Component } from "react";
import { connect } from "react-redux";
import AppLayout from "../../layout/AppLayout";
import { setUserData } from "../../redux/user/actions";
import { history } from "../../utils/Utils";
import Admin from "../Admin";

class AppDashboard extends Component {
  constructor() {
    super();
    this.state = {
      timeout: 1000 * 60 * 30, // 30 minutes
      isTimedOut: false
    };
  }

  logout = () => {
    setUserData();
    history.push("/");
  };

  renderItems = () => {
    const {
      match: { url },
      userData: { userRole }
    } = this.props;
    switch (userRole) {
      case "admin":
        return <Admin url={url} />;
      default:
        return null;
    }
  };

  render() {
    return <AppLayout>{this.renderItems()}</AppLayout>;
  }
}

export default connect(({ user: { userData } }) => ({ userData }))(AppDashboard);
