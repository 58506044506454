import * as Yup from "yup";
import {} from "./Utils";
const message = "Field is required!";
const emailMessage = "Invalid email address";

//Login
export const loginSchema = Yup.object().shape({
  email: Yup.string().email(emailMessage).required(message),
  password: Yup.string().required(message),
});

//Announcement
export const announceSchema = Yup.object().shape(
  {
    title: Yup.string().required(message),
    description: Yup.string().required(message),
    titleinFr: Yup.string().required(message),
    descriptioninFr: Yup.string().required(message),
    category: Yup.string().required(message),
    websiteLink: Yup.string()
      .required(message)
      .matches(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
        "please enter a valid Url"
      ),
    videoUrlEn: Yup.string().when("videoUrlEn", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          //if address exist then apply min max else not
          return Yup.string()
            .required(message)
            .matches(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
              "please enter a valid Url"
            );
        } else {
          return Yup.string().notRequired();
        }
      } else {
        return Yup.string().notRequired();
      }
    }),
    videoUrlFr: Yup.string().when("videoUrlFr", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          //if address exist then apply min max else not
          return Yup.string()
            .required(message)
            .matches(
              /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
              "please enter a valid Url"
            );
        } else {
          return Yup.string().nullable().notRequired();
        }
      } else {
        return Yup.string().nullable().notRequired();
      }
    }),
  },
  [
    // Add Cyclic deps here because when require itself
    ["videoUrlEn", "videoUrlEn"],
    ["videoUrlFr", "videoUrlFr"],
  ]
);

export const courseSchema = Yup.object().shape({
  courseName: Yup.string().required(message),
  courseNameInFr: Yup.string().required(message),
  category: Yup.string().required(message),
  // price: Yup.string().matches(/(?=.*\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$/, "Invalid price")
});

export const lessonSchema = Yup.object().shape({
  lessonName: Yup.string().required(message),
  lessonNameInFr: Yup.string().required(message),
});

export const questionSchema = Yup.object().shape({
  question: Yup.string().required(message),
  questionInFr: Yup.string().required(message),
  option1: Yup.string().required(message),
  option2: Yup.string().required(message),
  option1InFr: Yup.string().required(message),
  option2InFr: Yup.string().required(message),
  validAnswer: Yup.string().required(message),
  validAnswerInFr: Yup.string().required(message),
});

export const adsSchema = Yup.object().shape({
  // duration: Yup.string().required(message),
  category: Yup.string().required(message),
  subject: Yup.string().required(message),
  // price: Yup.string().required(message).matches(/(?=.*\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$/, "Invalid price"),
});

export const blockSchema = Yup.object().shape({
  // comment: Yup.string().required(message)
});

export const categorySchema = Yup.object().shape({
  name: Yup.string().required(message),
  module: Yup.string().required(message),
  nameinFr: Yup.string().required(message),
});

export const consolePriceSchema = Yup.object().shape({
  price: Yup.string()
    .required(message)
    .matches(
      /(?=.*\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|0)?(\.\d{1,2})?$/,
      "Invalid price"
    ),
  productId: Yup.string().required(message),
});

// change password
export const changePassSchema = Yup.object().shape({
  old_password: Yup.string().required(message),
  password: Yup.string().required(message),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Password must match")
    .required(message),
});

// update version
export const updateVersionSchema = Yup.object().shape({
  androidVersion: Yup.string().required(message),
  iosVersion: Yup.string().required(message),
});

// jobSchema
// update version
export const jobSchema = Yup.object().shape({
  // rating: Yup.string().required(message),
  // ratingComments: Yup.string().required(message),
});

export const projectsSchema = Yup.object().shape(
  {
    rating: Yup.string().when("rating", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          //if address exist then apply min max else not
          return Yup.string().required(message);
        } else {
          return Yup.string().notRequired();
        }
      } else {
        return Yup.string().notRequired();
      }
    }),
    ratingComments: Yup.string().when("ratingComments", (val, schema) => {
      if (val) {
        if (val.length > 0) {
          //if address exist then apply min max else not
          return Yup.string().required(message);
        } else {
          return Yup.string().notRequired();
        }
      } else {
        return Yup.string().notRequired();
      }
    }),
  },
  [
    // Add Cyclic deps here because when require itself
    ["rating", "rating"],
    ["ratingComments", "ratingComments"],
  ]
);

export const projectSchema = Yup.object().shape({
  activity: Yup.string().required(message),
  countyOfOrigin: Yup.string().required(message),
  valueOfProject: Yup.string().required(message),
});

export const addUserSchema = Yup.object().shape({
  email: Yup.string().email(emailMessage).required(message),
  name: Yup.string().required(message),
  area: Yup.string().required(message),
  type: Yup.string().required(message),
});

export const customUserFilterSchema = Yup.object().shape(
  {
    area: Yup.string().when("type", {
      is: (val) => !!val,
      then: Yup.string(),
      otherwise: Yup.string().required(message),
    }),
    type: Yup.string().when("area", {
      is: (val) => !!val,
      then: Yup.string(),
      otherwise: Yup.string().required(message),
    }),
  },
  ["area", "type"]
);
