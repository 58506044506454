import React from "react";
import CampaignCompanyList from './CampaignCompanyList'
import { useTranslation } from "react-i18next";

export default function Compaign({ heading }) {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("company.company")}</span>
      <CampaignCompanyList type="terminals" label={t("company.company")} t={t} />
    </div>
  );
}
