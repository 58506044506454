import React, { Component } from 'react';

export default class TabNav extends Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <ul className="nav nav-tabs">
          {this.props.tabs.map((tab , i) => {
            const active = tab === this.props.selected ? 'active ' : '';
            return (
              <div className="subscription-management" key={i}>
                <li className="nav-item" key={i}>
                  <a
                    className={'nav-link'}
                    style={{
                      backgroundColor: active ? '#0076ba' : '#e0e0e0',
                      borderRadius: '10px',
                      color: active ? '#fff' : '#000',
                      cursor: 'pointer',
                    }}
                    onClick={() => this.props.setSelected(tab)}
                  >
                    {tab}
                  </a>
                </li>
              </div>
            );
          })}
        </ul>
        {this.props.children}
      </div>
    );
  }
}
