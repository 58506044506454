import React from "react";
import Category from './Category'
import { useTranslation } from "react-i18next";

export default function Categories({ heading }) {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("ct.title")}</span>
      <Category type="terminals" label={t("ct.title")} t={t} />
    </div>
  );
}
