import Swal from "sweetalert2/src/sweetalert2.js";
import withReactContent from "sweetalert2-react-content";
import { useTranslation } from "react-i18next";

var MySwal = withReactContent(Swal);

export default {
  info: (title = false, text = false, onAccept = false, onReject = false) => {
    // const { t } = useTranslation();
    // console.log(t("ad.yes"))
    MySwal.fire({
      customClass: { container: "alert-container" },
      title,
      text,
      icon: "info",
      iconHtml: '<i class="fi flaticon-info" />',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then(({ isConfirmed, isDismissed }) => {
      if (isConfirmed) onAccept && onAccept();
      else if (isDismissed) onReject && onReject();
    });
  },
  success: (title = false, text = false, timer = 3000) => {
    MySwal.fire({
      customClass: { container: "alert-container" },
      title,
      text,
      timer,
      icon: "success",
      iconHtml: '<i class="fi flaticon-check-1" />',
      showConfirmButton: false,
    });
  },
  checkIdle: (onStay, onLogout) => {
    MySwal.fire({
      customClass: { container: "alert-container" },
      title: "You Have Been Idle!",
      text: "You will get logout. You want to stay?",
      icon: "info",
      iconHtml: '<i class="fi flaticon-info" />',
      showCancelButton: true,
      confirmButtonText: "Stay",
      cancelButtonText: "Logout",
    }).then(({ isConfirmed, isDismissed }) => {
      if (isConfirmed) onStay && onStay();
      else if (isDismissed) onLogout && onLogout();
    });
  },
};
