import clsx from "clsx";
import React, { useState } from "react";
import Pagination from "./Pagination";
import { useHistory } from "react-router-dom";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
  Collapse,
  Button,
  UncontrolledTooltip,
  CustomInput,
} from "reactstrap";
import { pageSizes } from "../utils/Config";
import { useTranslation } from "react-i18next";

const sortableFields = [
  "category",
  "subject",
  "module",
  "price",
  "name",
  "nameinFr",
  "transaction_date",
  "type",
  "projectStatus",
  "isApplicableForVerification",
];

export default function CardList({
  column = [],
  data = [],
  actions = [],
  key,
  options: {
    expandActions = false,
    isCollapsable = false,
    cardHeader = true,
    header = true,
    subHeader = false,
    handleCheckBox,
    checkboxWithActions,
    customCheck = true,
    pagination: {
      showPagination = false,
      pageSelection = false,
      size = 5,
      total_page = 1,
      page = 1,
      totalCount = 0,
    } = {},
  } = {},
  title = "",
  subTitle = "",
  headerComponent = null,
  subHeaderComponent = null,
  containerClassName = "",
  handlePageChange = () => { },
  handleSortingChange = () => { },
  clearFilterHandler = () => { },
  handleDelete = ()=>{},
  category,
  subject,
  valueOfProject,
  countyOfOrigin,
  country,
  city,
  area,
  type,
  rating,
  onCheckItem,
  selectedItems=[],
  onCheckEveryone,
  isSelectAll,
  isSelectEveryone,
  allData,
}) {
  const [isOpen, setOpen] = useState(true);
  const [isAscending, setIsAscending] = useState(true);

  let history = useHistory();
  let getPathFormHistory = history.location.pathname.substr(1);

  const total_count = data.length;
  const startIndex = (page - 1) * size;
  const endIndex = page * size;
  const toggle = () => setOpen(!isOpen);
  const { t } = useTranslation();
  

  return (
    <div
      className={clsx(
        "collapse-container",
        !header && "no-header",
        containerClassName
      )}
    >
      {header && (
        <label
          className={clsx("header", isCollapsable && "pointer")}
          onClick={isCollapsable ? toggle : null}
        >
          <span>{title}</span>
          {headerComponent}
          {isCollapsable && (
            <i
              className={clsx(
                "fi flaticon-down-arrow-2 d-flex my-auto ml-auto",
                isOpen && "flip"
              )}
            />
          )}
        </label>
      )}
      <Collapse isOpen={!isCollapsable || isOpen}>
        <div className="card-list">
          <div className="row">
            {subHeader && (
              <span className="font-weight-bold my-auto">
                Total {subTitle || title}: {totalCount}
              </span>
            )}
            {subHeaderComponent}
          </div>

          {/* <span className="no-data"
            style={
              !subject && !category
                ? { display: "none" }
                : {
                  marginLeft: "90%",
                  cursor: "pointer",
                  position: "relative",
                  top: "10px",
                }
            }
            onClick={() => clearFilterHandler()}>
            {" "}
            Clear Filter{" "}
          </span> */}

          {/* /* clear for project filter */}
          <span
            className="no-data"
            style={
              category ||
                valueOfProject ||
                countyOfOrigin ||
                rating ||
                country ||
                city ||
                area ||
                type
                ? {
                  marginLeft: "90%",
                  cursor: "pointer",
                  position: "relative",
                  top: "10px",
                }
                : { display: "none" }
            }
            onClick={() => clearFilterHandler()}
          >
            {" "}
            Clear Filter{" "}
          </span>
          {pageSelection && (
            <div className="row page-size">
              <span>{t("table.show")}</span>
              <UncontrolledDropdown className="d-inline-block mx-2">
                <DropdownToggle caret outline color="primary" size="xs">
                  {size}
                </DropdownToggle>
                <DropdownMenu right>
                  {pageSizes.map((item, i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() =>
                          handlePageChange && handlePageChange(page, item)
                        }
                      >
                        {item}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </UncontrolledDropdown>
              <span>{t("table.entries")}</span>
            </div>
          )}
          <div className="table-responsive">
            <table className="table table-borderless">
              {cardHeader && (
                <thead>
                  <tr className="text-nowrap">
                    {column.map((columnData, i) =>
                      sortableFields.find(
                        (sField) => sField == columnData.field
                      ) ? (
                        <td
                          key={columnData.field}
                          className={clsx(columnData.headerClassName)}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setIsAscending(!isAscending);
                            handleSortingChange(columnData.field, isAscending);
                          }}
                        >
                          {columnData.title}&nbsp;&nbsp;
                          <i className="fi flaticon-two-curved-arrows-up-and-down" />
                        </td>
                      ) : handleCheckBox == true ? (
                        <>
                          <td
                            key={columnData.field}
                            className={clsx(columnData.headerClassName)}
                          // style={{ width: "33%" }}
                          >
                            {columnData.title}
                          </td>
                        </>
                      ) : (
                        <td
                          key={columnData.field}
                          className={clsx(columnData.headerClassName)}
                        // style={{ width: "40%" }}
                        >
                          {columnData.title}
                        </td>
                      )
                    )}
                    {handleCheckBox == true && (
                      <td style={{ width: "20%" }}>
                        <CustomInput
                          className="item-check mb-0"
                          type="checkbox"
                          id={`check_${key}`}
                          onChange={() => onCheckEveryone(allData)}
                          label={isSelectEveryone ? "Remove All" : "All Users"}
                        />
                      </td>
                    )}
                    {actions.length > 0 && (
                      <td className="">{t("table.actions")}</td>
                    )}
                  </tr>
                  
                </thead>
              )}
              <tbody>
                {data.map((rowData, index) => (
                  <tr key={rowData.id}>
                    {column.map((columnData) => (
                      <td
                        key={columnData.field}
                        className={clsx(columnData.className)}
                      >
                        {columnData.render
                          ? columnData.render(rowData)
                          : rowData[columnData.field]}
                      </td>
                    ))}
                    {expandActions ? (
                      <>
                        <td
                          className={
                            handleCheckBox
                              ? "text-right text-nowrap campaignc"
                              : "text-center text-nowrap "
                          }
                        >
                          {handleCheckBox == true ? (
                            isSelectEveryone == false ? (
                              <div
                                className={clsx("d-flex flex-row campaignc", {
                                  active: selectedItems.includes(rowData.email),
                                })}
                              >
                                <CustomInput
                                  className="item-check mb-0 "
                                  // style=""
                                  type="checkbox"
                                  id={`check_${rowData.email}`}
                                  onChange={(event) =>
                                    onCheckItem(event,rowData.email)
                                  }
                                  label=""
                                  checked={selectedItems.includes(
                                    rowData.email
                                  )}
                                  // checked={selectedItems.some((item) => item.id === rowData.id && item.title === rowData.title)}
                                />
                              </div>
                            ) : (
                              <div
                                className={clsx("d-flex flex-row", {
                                  active: selectedItems.includes(rowData.email),
                                })}
                              >
                                <CustomInput
                                  className="item-check mb-0"
                                  type="checkbox"
                                  id={`check_${rowData.email}`}
                                  onChange={(event) =>
                                    onCheckItem(event,rowData.email)
                                  }
                                  label=""
                                  checked={selectedItems.includes(
                                    rowData.email
                                  )}
                                  // checked={selectedItems.some((item) => item.id === rowData.id && item.title === rowData.title)}
                                />
                              </div>
                            )
                          ) : (
                            actions.map((actionsData, i) =>
                              actionsData.render ? (
                                actionsData.render(rowData, i)
                              ) : (
                                <>
                                  <span key={i}>
                                    <Button
                                      id={
                                        actionsData.tooltip &&
                                        "action-tooltip-" + rowData.id
                                      }
                                      onClick={() =>
                                        actionsData.onClick &&
                                        actionsData.onClick(rowData)
                                      }
                                      color={clsx(actionsData.className)}
                                    >
                                      <i className={actionsData.icon} />
                                    </Button>
                                    {actionsData.tooltip && (
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target={"action-tooltip-" + rowData.id}
                                      >
                                        {actionsData.tooltip}
                                      </UncontrolledTooltip>
                                    )}
                                  </span>
                                </>
                              )
                            )
                          )}
                        
                        </td>
                        {checkboxWithActions && (
                          <td className="text-center text-nowrap">
                            {actions.map((actionsData, i) =>
                              actionsData.render ? (
                                actionsData.render(rowData, i)
                              ) : (
                                <>
                                  <span key={i}>
                                    <Button
                                      id={
                                        actionsData.tooltip &&
                                        "action-tooltip-" + rowData.id
                                      }
                                      onClick={() =>
                                        actionsData.onClick &&
                                        actionsData.onClick(rowData)
                                      }
                                      color={clsx(actionsData.className)}
                                    >
                                      <i className={actionsData.icon} />
                                    </Button>
                                    {actionsData.tooltip && (
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target={"action-tooltip-" + rowData.id}
                                      >
                                        {actionsData.tooltip}
                                      </UncontrolledTooltip>
                                    )}
                                  </span>
                                </>
                              )
                            )}
                          </td>
                        )}
                         {/* <td className="text-center text-nowrap">
                        <Button
                          color="danger"
                          onClick={() => handleDelete(rowData,true)}
                        >
                          <i className="fi flaticon-delete" />
                        </Button>
                      </td> */}
                      </>
                    ) : (
                      <td>
                        <UncontrolledDropdown className="text-center">
                          <DropdownToggle tag="span" className="px-2 pointer">
                            <i className="fi flaticon-menu-4 menu-icon" />
                          </DropdownToggle>
                          <DropdownMenu right>
                            {actions.map((actionsData, i) =>
                              actionsData.render ? (
                                actionsData.render(rowData, i)
                              ) : (
                                <DropdownItem
                                  key={i}
                                  onClick={() =>
                                    actionsData.onClick &&
                                    actionsData.onClick(rowData)
                                  }
                                >
                                  {actionsData.icon && (
                                    <i
                                      className={clsx(
                                        "mr-2",
                                        actionsData.className,
                                        actionsData.icon
                                      )}
                                    />
                                  )}
                                  {actionsData.title}
                                </DropdownItem>
                              )
                            )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {data.length == 0 && <span className="no-data">{t("no_data")}</span>}
          {showPagination && (
            <div className="row align-items-center">
              <span>
                {t("table.showing")}{" "}
                {`${startIndex + 1} to ${endIndex} of ${totalCount} `}{" "}
                {t("table.entries")}
              </span>
              <Pagination
                className="ml-auto"
                onChangePage={(value) => handlePageChange(value, size)}
                totalPage={total_page}
                currentPage={page}
              />
            </div>
          )}
        </div>
      </Collapse>
    </div>
  );
}
