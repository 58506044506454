import MomentUtils from "@date-io/moment";
import { createMuiTheme, darken, lighten, MuiThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "bootstrap";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./assets/scss/styles.scss";
import variables from "./assets/scss/_variables.scss";
import MainApp from "./MainApp";
import { store } from "./redux/store";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: lighten(variables.primary_color, 0.8),
      main: variables.primary_color,
      dark: darken(variables.primary_color, 0.8)
    },
    secondary: {
      light: lighten(variables.secondary_color, 0.8),
      main: variables.secondary_color,
      dark: darken(variables.secondary_color, 0.8)
    },
    success: {
      light: lighten(variables.success_color, 0.8),
      main: variables.success_color,
      dark: darken(variables.success_color, 0.8)
    },
    error: {
      light: lighten(variables.danger_color, 0.8),
      main: variables.danger_color,
      dark: darken(variables.danger_color, 0.8)
    }
  },
  overrides: {
    MuiInput: {
      underline: {
        "&:after": {
          borderBottomWidth: "1px"
        },
        "&:hover&:before,&:before,&:not(.Mui-disabled):before": {
          borderBottom: "1px solid #DEDEDE"
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        "&:hover $notchedOutline": {
          borderColor: "#DEDEDE"
        },
        "&$focused $notchedOutline": {
          borderWidth: "1px"
        }
      },
      notchedOutline: {
        borderColor: "#DEDEDE"
      },
      multiline: {
        padding: "18.5px 28px"
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(26px, 20px) scale(1)"
      }
    },
    MuiSlider: {
      root: {
        color: "#C8C8C8"
      },
      rail: {
        borderRadius: "20px",
        height: "5px"
      },
      track: {
        borderRadius: "20px",
        height: "5px",
        color: "#045990"
      },
      thumb: {
        marginTop: "-7.5px",
        height: "19px",
        width: "19px",
        color: "white",
        boxShadow: "0 2px 4px 0 rgba(0,0,0,0.5);"
      }
    }
  },
  typography: {
    fontFamily: "sans-serif"
  }
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MainApp />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById("root")
);
