import { Form, Formik } from "formik";
import React from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikSelect } from "../../../../components/FormikFields";
import { addUserSchema } from "../../../../utils/validationSchema";
import { areaOptions, typeOptions } from "../../../../utils/Config";

function AddUser({ onSubmit, t, data }) {
  /* get only updated values */
  const getModifiedValues = (values, initialValues) => {
    let modifiedValues = {};

    if (values) {
      Object.entries(values).forEach((entry) => {
        let key = entry[0];
        let value = entry[1];

        if (value !== initialValues[key]) {
          modifiedValues[key] = value;
        }
      });
    }

    return modifiedValues;
  };

  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik
          enableReinitialize
          initialValues={data || { email: "", name: "", area: "", type: "" }}
          validationSchema={addUserSchema}
          onSubmit={(values) => {
            const modifiedValues = getModifiedValues(values, data);
            onSubmit(modifiedValues);
          }}
        >
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              <FormGroup className="col-6 px-4 mt-2">
                <FormikInput
                  label={t("name")}
                  name="name"
                  error={touched.name && errors.name}
                  value={values.name || ""}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4 mt-2">
                <FormikInput
                  label={t("approval.email")}
                  name="email"
                  error={touched.email && errors.email}
                  value={values.email || ""}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("area")}
                  name="area"
                  defaultValue=""
                  className="react-select"
                  classNamePrefix="react-select"
                  value={values.area || ""}
                  onChange={setFieldValue}
                  error={touched.area && errors.area}
                  onBlur={setFieldTouched}
                  options={areaOptions}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("type")}
                  defaultValue=""
                  name="type"
                  className="react-select"
                  classNamePrefix="react-select"
                  value={values.type || ""}
                  onChange={setFieldValue}
                  error={touched.type && errors.type}
                  onBlur={setFieldTouched}
                  options={typeOptions}
                />
              </FormGroup>

              <div className="col-12 d-flex my-4">
                <Button type="submit" color="success" className="my-4 mx-auto">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default AddUser;
