import React, { useState } from "react";
import Users from './Users'
import HeaderCard from '../../../../components/HeaderCards'
import TabNav from '../../../../components/TabNav'
import Tab from '../../../../components/Tab'
import UnBlockUsers from './UnBlockUsers'
import { useTranslation } from "react-i18next";
import ScreenAccess from '../ScreenAccess/ScreenAccessList'

export default function Courses({ heading }) {
  const { t } = useTranslation();
  const [selected, setselected] = useState(t("company.company"))

  const setSelected = (tab) => {
    setselected(tab)
  }
  return (
    <div className="subscription-management">
      <span className="page-header">{t("company.company")}</span>
      <TabNav tabs={[t("company.company"), t("company.blocked"), t("student.Access")]} selected={selected} setSelected={setSelected} color={'white'}>
        <Tab isSelected={selected === t("company.company")}>
          <Users type="terminals" label={t("company.company")} t={t} />
        </Tab>
        <Tab isSelected={selected === t("company.blocked")}>
          <UnBlockUsers type="terminals" label={t("company.blocked")} t={t} />
        </Tab>
        <Tab isSelected={selected === t("student.Access")}>
          <ScreenAccess type="terminals" label={t("student.Access")} t={t} />
        </Tab>
      </TabNav>
    </div>
  );
}
