import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import CompanyStudForm from "./CompanyStudForm";
import Alert from "../../../../components/Alert";
import i18n from "../../../../i18n";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { progress } from "../../../../redux/user/actions";

export default class CompanyStudList extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      progress: '',
      loader: false,
      rowDatas: []
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, size } = this.state;
    apiClient.get("announcement/getAnnouncements/" + "company", { params: { page, size } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      }
    });
  };

  handleDelete = (rowData) => {
    apiClient.delete("announcement/deleteAnnouncement/" + rowData._id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSubmit = (data) => {


   
    const rowData = this.state.edit_row
    
    var form_data = new FormData();
    form_data.append("title", data.title);
    form_data.append("description", data.description);
    form_data.append("titleinFr", data.titleinFr);
    form_data.append("descriptioninFr", data.descriptioninFr);
    form_data.append("category", data.category);
    form_data.append("forRole", 'company');

    if (data.both == undefined) {
      form_data.append("both", false);
    } else {
      form_data.append("both", data.both);
    }
    /* english video */
    if (data.announcementVideo && data.announcementVideo.length > 0) {
      form_data.append("announcementVideo", data.announcementVideo[0]);
    }

    /* french video */
    if (data.announcementVideoInFr && data.announcementVideoInFr.length > 0) {
      form_data.append("announcementVideoInFr", data.announcementVideoInFr[0]);
    }

    /* english video url */
    if (data.videoUrlEn) {
      form_data.append("videoUrlEn", data.videoUrlEn);
    }

    /* french video url */
    if (data.videoUrlFr) {
      form_data.append("videoUrlFr", data.videoUrlFr);
    }

    form_data.append("websiteLink", data.websiteLink);
    if (data.announcementImage && data.announcementImage.length > 0) {
      form_data.append("announcementImage", data.announcementImage[0]);
    }
    const { mode } = this.state;
    var options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        this.setState({
          progress: percent
        })
        console.log(`${loaded} kb of ${total} kb | ${percent}%`)
      }
    }
    if (mode === "add") {
      apiClient.post("announcement/createAnnouncement", form_data,
        options,
        this.setState({
          loader: true
        }),
        progress(true)
      ).then(({ status }) => {
        this.setState({ loader: false })
        if (status === 200) {
          progress(false)
          this.getData();
          toast.success("Announcement Added Successfully");
        }
      })
    } else {
      apiClient.put("announcement/updateAnnouncement/" + data._id, form_data,
        options,
        this.setState({
          loader: true
        }),
        progress(true)
      ).then(({ status }) => {
        this.setState({ loader: false })
        progress(false)
        if (status === 200) {
          this.getData();
          toast.success("Announcement Updated Successfully");
        }
      });
    }
    this.toggleModal();
  };

  handleChange = (show_date) => {
    this.setState({ show_date });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };

  render() {
    const { mode, data, edit_row, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;
    return (
      <>
        {this.state.loader ?
          <div className="progressBar">
            <CircularProgressbar value={this.state.progress} text={`${this.state.progress}%`} />
          </div>
          :
          <>
            <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
              <ModalHeader toggle={() => this.toggleModal()}>{t("company.cAnnouncement")}</ModalHeader>
              <ModalBody>
                {edit_row && (
                  <div className="fields">
                    <CompanyStudForm data={edit_row} title="Announcement" mode={mode} onSubmit={this.handleSubmit} t={t} />
                  </div>
                )}
              </ModalBody>
            </Modal>
            <CardList
              containerClassName="mx-5 mb-5 mt-4"
              title={label}
              subHeaderComponent={
                <Button className="ml-auto" color="success" onClick={() => this.toggleModal("add")}>
                  {"+ " + t("price.add")}
                </Button>
              }
              column={[
                { title: t("title"), field: i18n.language == 'en' ? "title" : "titleinFr" },
                { title: t("ad.category"), field: "category" },
                { title: t("ad.description"), field: i18n.language == 'en' ? "description" : "descriptioninFr" },
                // { title: t("ad.forRole"), field: "both" },
              ]}
              data={data}
              actions={[
                {
                  icon: "fi flaticon-view-1",
                  tooltip: "View",
                  className: "btn-white text-primary p-0",
                  onClick: (rowData) => this.toggleModal("view", rowData)
                },
                {
                  icon: "fi flaticon-edit",
                  className: "btn-white text-primary p-0",
                  onClick: (rowData) => this.toggleModal("edit", rowData) && this.setState({ edit_row_id: rowData._id, rowDatas: rowData })
                },
                {
                  icon: "fi flaticon-delete-1",
                  className: "btn-white text-danger p-0",
                  onClick: (rowData) =>
                    Alert.info(
                      t("delete.msg"),
                      t('delete.d'),
                      () => this.handleDelete(rowData)
                    )
                }
              ]}
              handlePageChange={this.onPageChange}
              options={{
                subHeader: false,
                isCollapsable: true,
                expandActions: true,
                pagination: { showPagination: true, pageSelection: true, total_page, page, size, totalCount }
              }}
            />
          </>
        }
      </>
    );
  }

}
