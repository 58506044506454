import React from "react";

export default function Pagination({
  onChangePage: onChange,
  className = "",
  totalPage = 0,
  currentPage = 1,
  numberLimit = 5,
  firstLast = false,
}) {
  const onChangePage = (e) => {
    onChange(e);
  };

  let startPoint = 1;
  let endPoint = numberLimit;

  if (numberLimit > totalPage) {
    startPoint = 1;
    endPoint = totalPage;
  } else if (currentPage <= parseInt(numberLimit / 2, 10)) {
    startPoint = 1;
    endPoint = numberLimit;
  } else if (currentPage + parseInt(numberLimit / 2, 10) <= totalPage) {
    startPoint = currentPage - parseInt(numberLimit / 2, 10);
    endPoint = currentPage + parseInt(numberLimit / 2, 10);
  } else {
    startPoint = totalPage - (numberLimit - 1);
    endPoint = totalPage;
  }
  startPoint = startPoint === 0 ? 1 : startPoint;
  const points = [];
  for (var i = startPoint; i <= endPoint; i++) {
    points.push(i);
  }

  let firstPageButtonClassName = currentPage <= 1 ? "disabled" : "";
  let lastPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
  let prevPageButtonClassName = currentPage <= 1 ? "disabled" : "";
  let nextPageButtonClassName = currentPage >= totalPage ? "disabled" : "";
  return totalPage > 1 ? (
    <div className={className}>
      <ul className="nav pagination justify-content-center align-items-center">
        {firstLast && (
          <li className={`${firstPageButtonClassName}`} onClick={() => onChangePage(1)}>
            <i className="fa fa-angle-double-left" />
          </li>
        )}

        <li className={`${prevPageButtonClassName}`} onClick={() => onChangePage(currentPage - 1)}>
          <i className="fi flaticon-left-arrow-line-symbol" />
        </li>
        {points.map((i) => {
          return (
            <li key={i} className={`${currentPage === i && "active"}`} onClick={() => onChangePage(i)}>
              {i}
            </li>
          );
        })}
        <li className={`${nextPageButtonClassName}`} onClick={() => onChangePage(currentPage + 1)}>
          <i className="fi flaticon-next" />
        </li>
        {firstLast && (
          <li className={`${lastPageButtonClassName}`} onClick={() => onChangePage(totalPage)}>
            <i className="fa fa-angle-double-right" />
          </li>
        )}
      </ul>
    </div>
  ) : (
    <div className={className} />
  );
}
