import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import ApprovalForm from "./ApprovalForm";

export default class Approval extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      userData: [],
      edit_row: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    const { page, size } = this.state;
    apiClient.get("company/get-company", { params: { page, size } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          data,
          page,
          size,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      } else {
        // toast.error("No Data Found")
      }
      this.getData(data.length > 0 ? data[0].userId : null);
    });
  };

  getData = (id) => {
    apiClient.get("users/getUser/" + id,).then(({ status, data: { data, } }) => {
      if (status === 200) {
        this.setState({
          userData: data,
        });
      }
    });
  };


  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSubmit = (data) => {
    this.toggleModal();
    this.getUserData()
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getUserData());
  };

  render() {
    const { mode, data, edit_row, size, total_page, page, totalCount, userData } = this.state;
    const { label = "", t } = this.props;
    const userDatas = [];
    userDatas.push(userData && userData)
    return (
      <>
        <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>{t("approval.approval")}</ModalHeader>
          <ModalBody>
            {edit_row && (
              <div className="fields">
                <ApprovalForm data={edit_row} title="Send Password" mode={mode} userData={userDatas} onSubmit={this.handleSubmit} t={t} />
              </div>
            )}
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          column={[
            { title: t("approval.email"), field: "email" },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("view", rowData)
            },
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: false,
            isCollapsable: true,
            expandActions: true,
            pagination: { showPagination: true, pageSelection: true, total_page, page, size, totalCount }
          }}
        />
      </>
    );
  }

}
