import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, FormGroup } from "reactstrap";
import { CustomSelectInput, FormikInput } from "../../../../components/FormikFields";
import countries from "i18n-iso-countries";
import Select from "react-select";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";

function FilterCampaign({ onSubmit, t }) {
    const [countryData, setcountryData] = useState([])
    const [country, setCountry] = useState('')

    countries.registerLocale(enLocale);
    // Returns an object not a list
    const countryObj = countries.getNames("en", { select: "official" });

    useEffect(() => {
        getCountry();
    }, [module])


    function handleChange(e) {
        console.log(e);
        setCountry(e.value)
    }


    const getCountry = () => {
        const countries = Object.entries(countryObj).map(([key, value]) => ({ label: value, value: value }))
        setcountryData(countries)
    }

    console.log(country);

    const brandColor = '#005288';

    const customStyles = {
        control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderColor: state.isFocused
                ? brandColor
                : base.borderColor,
            '&:hover': {
                borderColor: state.isFocused
                    ? brandColor
                    : base.borderColor,
            }
        })
    };

    return (
        <div className="add-terminal">
            <div className="fields col-11 mx-auto">
                <Formik
                    enableReinitialize
                    initialValues={{
                        country: country['value'],
                        city: "",
                    }}
                    // validationSchema={projectSchema}
                    onSubmit={onSubmit}>
                    {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                        <Form className="row">
                            <FormGroup className="col-6 px-4">
                                <label>
                                    Country
                                </label>
                                <Select
                                    styles={customStyles}
                                    components={{ Input: CustomSelectInput }}
                                    label={t("student.country")}
                                    name="country"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    value={country}
                                    onChange={val => {
                                        setCountry(val)
                                    }}
                                    options={countryData}
                                />
                            </FormGroup>
                            <FormGroup className="col-6 px-4 mt-2">
                                <FormikInput
                                    label={t('approval.city')}
                                    name="city"
                                    error={touched.city && errors.city}
                                    value={values.city}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                />
                            </FormGroup>

                            <div className="col-12 d-flex my-4">
                                <Button type="submit" color="success" className="my-4 mx-auto">
                                    Apply Filter
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}

export default FilterCampaign