import React from "react";
import CustomList from "./CustomList";
import { useTranslation } from "react-i18next";

export default function Custom({ heading }) {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("campaign.custom")}</span>
      <CustomList type="terminals" label={t("campaign.custom")} t={t} />
    </div>
  );
}
