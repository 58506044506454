import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikSelect, } from "../../../../components/FormikFields";
import apiClient from "../../../../utils/apiClient";
import { subjectData } from "../../../../utils/Config";
import axios from "axios";

export default function FilterFormPlan({ onSubmit, t }) {
    const [categoryData, setcategoryData] = useState([])
    const [module, setmodule] = useState('')

    useEffect(() => {
        getAdsPrice()
    }, [module])

    const getAdsPrice = () => {
        apiClient.get("category/get-categories", { params: { module: module } }).then(({ status, data }) => {
            if (status === 200) {
                setcategoryData(data.data)
            } else {
                setcategoryData([])
            }
        });
    };

    const touchedData = categoryData.map((item) => ({ label: item.name, value: item.name }));

    return (
        <div className="add-terminal">
            <div className="fields col-11 mx-auto">
                <Formik
                    enableReinitialize
                    initialValues={{
                        subject: null,
                        category: null
                    }}
                    onSubmit={onSubmit}>
                    {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                        <Form className="row">
                            {
                                values.subject && values.subject.length > 0 &&
                                setmodule(values.subject)
                            }
                            <FormGroup className="col-6 px-4">
                                <FormikSelect
                                    label={t("ct.module")}
                                    name="subject"
                                    error={touched.subject && errors.subject}
                                    value={values.subject}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    options={subjectData}
                                />
                            </FormGroup>
                            <FormGroup className="col-6 px-4">
                                <FormikSelect
                                    label={t("ad.category")}
                                    name="category"
                                    value={values.category}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    options={touchedData}
                                />
                            </FormGroup>
                            <div className="col-12 d-flex my-4">
                                <Button type="submit" color="success" className="my-4 mx-auto">
                                    Apply Filter
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    )
}
