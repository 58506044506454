const dotenv = require("dotenv");
dotenv.config({ path: ".env" });
const client = require('@sendgrid/client');
client.setApiKey(process.env.REACT_APP_SENDGRID_API_KEY);
// console.log("process.env.REACT_APP_SENDGRID_API_KEY",process.env.REACT_APP_SENDGRID_API_KEY)
export const sendGridApi = async () => {
    try {
        const request = {
            url: `https://api.sendgrid.com/v3/templates?generations=dynamic`,
            method: 'GET',
        }
        const [response, body] = await client.request(request);
        console.log("body", body);
        if (response.statusCode === 200) {
            const result = body.templates.map(item => {
                return {
                    id: item.id,
                    name: item.name
                };
            });
            return result;
        } else {
            throw new Error("Failed to fetch templates");
        }
    } catch (error) {
        console.error(error);
        throw error; // Re-throw the error to handle it outside if needed
    }
};
