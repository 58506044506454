import { store } from "../redux/store";

export const getMenu = (t, jobOffers, advertisement) => {

  switch (store.getState().user.userData.userRole) {
    case "admin":
      return [
        {
          name: t("sidebar.company"),
          icon: "fi flaticon-office-building",
          children: [
            {
              name: t("sidebar.job"),
              icon: "fi flaticon-office-building",
              to: "/app/job-offers",
              badge: true,
              count: jobOffers
            },
            {
              name: t("ad.title"),
              icon: "fi flaticon-office-building",
              to: "/app/addvertisement",
              badge: true,
              count: advertisement
            },
            // {
            //   name: "Update Prices",
            //   icon: "fi flaticon-office-building",
            //   to: "/app/prices"
            // },
            {
              name: t("sidebar.company"),
              icon: "fi flaticon-office-building",
              to: "/app/block-company"
            },
            {
              name: t("company.announcement"),
              icon: "fi flaticon-office-building",
              to: "/app/announcement-company"
            },
          ]
        },
        {
          name: t("student.Student"),
          icon: "fi flaticon-group",
          children: [
            {
              name: t("course.title"),
              icon: "fi flaticon-group",
              to: "/app/course/1"
            },
            {
              name: t("sidebar.individual"),
              icon: "fi flaticon-group",
              to: "/app/block-students"
            },
            {
              name: t("company.announcement"),
              icon: "fi flaticon-group",
              to: "/app/announcement-students"
            },
            {
              name: t("project.title"),
              icon: "fi flaticon-group",
              to: "/app/projects"
            },
          ]
        },
        // <Badge color="secondary">4</Badge>,
        // {
        //   name: t("approval.request"),
        //   icon: "fi flaticon-group",
        //   to: "/app/approval",
        //   badge: true,
        // },
        {
          name: "Price",
          name: t("price.price"),
          icon: "fi flaticon-upload",
          to: "/app/prices",
        },
        {
          name: "ProductId",
          name: t("inapp"),
          icon: "fi flaticon-invoice-1",
          to: "/app/price-productId",

        },
        {
          name: t("ct.title"),
          icon: "fi flaticon-menu-5",
          to: "/app/category",

        },
        {
          name: t("pay.title"),
          icon: "fi flaticon-info",
          to: "/app/payment-history",
        },
        {
          name: "Faq",
          icon: "fi flaticon-check-list-5",
          to: "/app/faq-admin",
        },
        {
          name: t("campaign.title"),
          icon: "fi flaticon-group",
          children: [
            {
              name: t("student.Student"),
              icon: "fi flaticon-group",
              to: "/app/campaign-student"
            },
            {
              name: t("sidebar.company"),
              icon: "fi flaticon-group",
              to: "/app/campaign-company"
            },
            {
              name: t("campaign.custom"),
              icon: "fi flaticon-group",
              to: "/app/campaign-custom"
            },
          ]
        },
      ];
    default:
      return [];
  }
};


// import React from 'react'

// function routes() {
//   return (

//   )
// }

// export default routes
