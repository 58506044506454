import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Button, Input } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import CampaignCompanyForm from "./CampaignCompanyForm";
import Alert from "../../../../components/Alert";
import { progress } from "../../../../redux/user/actions";
import clsx from "clsx";
import { CircularProgressbar } from "react-circular-progressbar";
import { sendGridApi } from "../sendGrid";
import FilterCampaign from "./FilterCampaign";
import FilterListIcon from "@material-ui/icons/FilterList";

export default class CampaignCompanyList extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      edit_row_id: "",
      totalCount: 10,
      size: 5,
      page: 1,
      total_page: 1,
      country: "",
      city: "",
      progress: "",
      loader: false,
      selectedItems: [],
      lastChecked: null,
      finalSelectedItems: [],
      openFilterPopup: false,
      allData: [],
      sendGridData: [],
      isSearch: false,
      search: "",
      allData: [],
      isSelectEveryone: false,
    };
  }

  componentDidMount() {
    this.getData();
    this.getsendGridData();
  }

  /* fetch send grid data */
  getsendGridData = async () => {
    const result = await sendGridApi();
    if (result?.length > 0) {
      this.setState({ sendGridData: result });
    }
  };

  getData = () => {
    let { page, size, search, country, city } = this.state;
    apiClient
      .get("users/get-users/company", {
        params: { page, size, search, country, city },
        optional: Boolean(search),
      })
      .then(({ status, data: { page, size, data, allData, totalCount } }) => {
        if (status === 200) {
          size = size < 5 ? 5 : 5;
          this.setState({
            page,
            size,
            data: data,
            totalCount,
            allData,
            isSearch: Boolean(search),
            total_page: Math.ceil(totalCount / size),
          });
        }
      });
  };

  /* filter model */
  handleFilterModal = () => {
    this.setState({ openFilterPopup: !this.state.openFilterPopup });
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => this.getData());
  };
  onCheckEveryone = (allData) => {
    if (this.state.isSelectEveryone) {
      this.setState({
        selectedItems: [],
        isSelectEveryone: false,
      });
    }
    if (!this.state.isSelectEveryone && this.state.isSearch) {
      let allEmails = this.state.selecetedItems.concat(allData);
      this.setState({
        selectedItems: allEmails,
        isSelectEveryone: true,
      });
    } else if (!this.state.isSelectEveryone && !this.state.isSearch) {
      this.setState({
        selectedItems: allData,
        isSelectEveryone: true,
      });
    }
  };

  /* on change for checkbox */
  onCheckItem = (event, id) => {
    let selectedItems = this.state.selectedItems;

    if (selectedItems.includes(id)) {
      selectedItems = selectedItems.filter((item) => item !== id);
      this.setState({
        selectedItems,
      });
    } else {
      selectedItems.push(id);
    }
    this.setState({
      ...selectedItems,
    });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  handleSubmit = (data) => {
    var lowercaseArray = this.state.selectedItems.map((x) => x.toLowerCase());

    /* remove duplicates from arrray */
    var uSet = new Set(lowercaseArray);

    /* unique values */
    this.setState({ selectedItems: [...uSet] });

    var bodyData = {
      question: data.question,
      questionInFr: data.questionInFr,
      answer: data.answer,
      answerInFr: data.answerInFr,
    };
    const rowData = this.state.edit_row;
    var form_data = new FormData();
    if (data) {
      form_data.append("mailIds", this.state.selectedItems);
      form_data.append("subject", data.subject);
      form_data.append("message", data.message);
      form_data.append("templateId", data.templateId);
      if (data.attachmentsinEn) {
        form_data.append("attachments", data.attachmentsinEn[0]);
      }
    }
    if (data.language == "french") {
      form_data.append("mailIds", this.state.selectedItems);
      form_data.append("subject", data.subjectinFr);
      form_data.append("message", data.messageinFr);
      form_data.append("templateId", data.templateId);
      if (data.attachmentsinFr)
        form_data.append("attachements", data.attachmentsinFr[0]);
    }
    var options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.round((loaded * 100) / total);
        this.setState({
          progress: percent,
        });
        console.log(`${loaded} kb of ${total} kb | ${percent}%`);
      },
    };
    // if (mode === "add") {
    apiClient
      .put(
        "users/campaign-mail",
        form_data,
        options,
        this.setState({
          loader: true,
        }),
        progress(true)
      )
      .then(({ status }) => {
        this.setState({
          loader: true,
        });
        if (status === 200) {
          this.setState({ loader: false });
          progress(false);
          this.getData();
          toast.success("Mail Sent Successfully");
        }
      });
    this.toggleModal();
  };

  /* apply filter */
  handleApplyFilter = (rowData) => {
    this.setState({
      country: rowData.country,
      city: rowData.city,
      openFilterPopup: false,
    });

    this.getData();
  };

  /*clear filter */
  clearFilterHandler = () => {
    this.setState(
      {
        country: "",
        city: "",
      },
      () => {
        this.getData();
      }
    );
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };

  render() {
    const {
      mode,
      edit_row,
      data,
      size,
      total_page,
      page,
      totalCount,
      sendGridData,
    } = this.state;
    const { label = "", t } = this.props;
    const touchedData = sendGridData.map((item) => ({
      label: item.name,
      value: item.id,
    }));

    return (
      <>
        {this.state.loader ? (
          <div className="progressBar">
            <CircularProgressbar
              value={this.state.progress}
              text={`${this.state.progress}%`}
            />
          </div>
        ) : (
          <>
            <Modal
              className="course-modal"
              isOpen={Boolean(mode)}
              toggle={() => this.toggleModal()}
            >
              <ModalHeader toggle={() => this.toggleModal()}>
                {mode
                  ? mode === "add"
                    ? "Compose email for " + label
                    : mode === "edit"
                    ? t("ad.edit") + " " + label
                    : t("ad.view") + " " + label
                  : ""}
              </ModalHeader>
              <ModalBody>
                <div className="fields">
                  {mode && (
                    <CampaignCompanyForm
                      data={edit_row}
                      mode={mode}
                      title="Add Faq"
                      onSubmit={this.handleSubmit}
                      touchedData={touchedData}
                      t={t}
                    />
                  )}
                </div>
              </ModalBody>
            </Modal>
            <Modal
              className="terminals-modal"
              isOpen={this.state.openFilterPopup}
              toggle={() => this.handleFilterModal()}
            >
              <ModalHeader toggle={() => this.handleFilterModal()}>
                Filter Record
              </ModalHeader>
              <ModalBody>
                <div className="fields">
                  <FilterCampaign
                    data={edit_row}
                    mode={mode}
                    title="Filter"
                    onSubmit={this.handleApplyFilter}
                    t={t}
                  />
                </div>
              </ModalBody>
            </Modal>
            <div className="search-box row col-5">
              <i className="fi flaticon-loupe" />
              <Input
                type="search"
                placeholder="Search here.."
                // value={search}
                onChange={(e) => this.handleChange("search", e.target.value)}
              />
            </div>
            <CardList
              containerClassName="mx-5 mb-5 mt-4"
              title={label}
              subHeaderComponent={
                <>
                  <Button
                    className="ml-auto"
                    color="success"
                    onClick={() => {
                      if (this.state.selectedItems?.length === 0) {
                        return toast.error("Please select atleast one Company");
                      } else {
                        this.toggleModal("add");
                      }
                    }}
                  >
                    {"+ " + "Compose"}
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    className=""
                    color="success"
                    onClick={() => this.handleFilterModal()}
                  >
                    {/* {"+ " + t("ct.add")} */}
                    <FilterListIcon /> {" Filter"}
                  </Button>
                </>
              }
              column={[
                {
                  title: t("approval.companyName"),
                  field: "companyName",
                  render: (rowData) => (
                    <span className={clsx("companyName", rowData.companyName)}>
                      {rowData.companyName}
                    </span>
                  ),
                },
                {
                  title: t("approval.email"),
                  field: "email",
                  render: (rowData) => (
                    <span className={clsx("email", rowData.email)}>
                      {rowData.email}
                    </span>
                  ),
                },
              ]}
              data={data}
              allData={this.state.allData}
              onCheckItem={this.onCheckItem}
              clearFilterHandler={this.clearFilterHandler}
              country={this.state.country}
              city={this.state.city}
              onCheckEveryone={this.onCheckEveryone}
              selectedItems={this.state.selectedItems}
              isSelectEveryone={this.state.isSelectEveryone}
              handlePageChange={this.onPageChange}
              options={{
                subHeader: true,
                isCollapsable: true,
                expandActions: true,
                handleCheckBox: true,
                pagination: {
                  showPagination: true,
                  pageSelection: true,
                  total_page,
                  totalCount,
                  page,
                  size,
                },
              }}
            />
          </>
        )}
      </>
    );
  }
}
