import React from "react";
import AnnounceMents from './CompanyStudList'
import HeaderCard from '../../../../components/HeaderCards'
import { useTranslation } from "react-i18next";

export default function Courses({ heading }) {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("company.announcement")}</span>
      <AnnounceMents type="terminals" label={t("company.announcement")} t={t} />
    </div>
  );
}
