import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput } from "../../../components/FormikFields";
import { categorySchema } from "../../../utils/validationSchema";

export default class PaymentHistoryForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { data, onSubmit, mode, t } = this.props;
    return (
      <div className="add-counter">
        <div className="fields col-11 mx-auto">
          <Formik
            validationSchema={categorySchema}
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("payment.id")}
                      name="paymentId"
                      disabled={mode === "view"}
                      // error={touched.name && errors.name}
                      value={values.paymentId}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.email")}
                      name="email"
                      disabled={mode === "view"}
                      // error={touched.name && errors.name}
                      value={values.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("pay.date")}
                      name="transaction_date"
                      disabled={mode === "view"}
                      // error={touched.name && errors.name}
                      value={values.transaction_date}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("pay.type")}
                      name="type"
                      disabled={mode === "view"}
                      error={touched.name && errors.name}
                      value={values.type}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("pay.status")}
                      name="status"
                      disabled={mode === "view"}
                      error={touched.name && errors.name}
                      value={values.status}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("pay.role")}
                      name="role"
                      disabled={mode === "view"}
                      error={touched.name && errors.name}
                      value={values.role}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("pay.amount")}
                      name="amountReceived"
                      disabled={mode === "view"}
                      error={touched.name && errors.name}
                      value={values.amountReceived}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("pay.currency")}
                      name="currency"
                      disabled={mode === "view"}
                      error={touched.name && errors.name}
                      value={values.currency}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                </>
                {mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button
                      type="submit"
                      color="success"
                      className="my-4 mx-auto"
                    >
                      {mode === "edit" ? t("price.update") : t("ct.add")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
