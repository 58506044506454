import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikRating } from "../../../../../components/FormikFields";
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'
import { jobSchema } from '../../../../../utils/validationSchema'


export default class JobOffersForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {


    const { data, onSubmit, mode, t } = this.props;
    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            validationSchema={jobSchema}
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}>
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                {
                  mode === 'reject' ?
                    <>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("jobs.comment")}
                          name="adminComment"
                          value={values.adminComment}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      {mode !== "view" && (
                        <div className="col-12 d-flex my-4">
                          <Button type="submit" color="success" className="my-4 mx-auto">
                            {t("jobs.reject")}
                          </Button>
                        </div>
                      )}
                    </>
                    :
                    <>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("title")}
                          name="title"
                          disabled={mode === "view" || 'edit'}
                          value={values.title}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("jobs.location")}
                          name="location"
                          disabled={mode === "view" || 'edit'}
                          value={values.location}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("approval.city")}
                          name="city"
                          disabled={mode === "view" || 'edit'}
                          value={values.city}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("student.country")}
                          name="country"
                          disabled={mode === "view" || 'edit'}
                          value={values.country}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("jobs.designation")}
                          name="designation"
                          disabled={mode === "view" || 'edit'}
                          value={values.designation}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      <FormGroup className="col-6 px-4">
                        <FormikInput
                          label={t("jobs.postedBy")}
                          name="uploadedBy"
                          disabled={mode === "view" || 'edit'}
                          value={values.uploadedBy}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      <FormGroup className="col-12 px-4">
                        <FormikInput
                          label={t("ad.description")}
                          name="description"
                          multiline={true}
                          disabled={mode === "view" || 'edit'}
                          value={values.description}
                          onChange={setFieldValue}
                        />
                      </FormGroup>
                      {
                        data['jobStatus'] == 'ACTIVE' &&
                        <>
                          <label className="px-4">{t("job.rating")}</label>
                          <FormikRating
                            name="rating"
                            emptyIcon={<FaStar size={30} />}
                            halfIcon={<FaStarHalfAlt size={40} />}
                            filledIcon={<FaStar size={30} />}
                            value={values.rating}
                            size={70}
                            count={5}
                            color={"#E0E0E0"}
                            activeColor={"#005288"}
                            a11y={true}
                            isHalf={true}
                            onChange={(e) => values.rating = e}
                            // error={errors.rating}
                          />
                          <FormGroup className="col-12 px-4">
                            <FormikInput
                              label={t("job.review")}
                              name="ratingComments"
                              value={values.ratingComments}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                              // error={errors.ratingComments}
                            />
                          </FormGroup>
                        </>
                      }

                      {mode !== "view" && (
                        <div className="col-12 d-flex my-4">
                          <Button type="submit" color="success" className="my-4 mx-auto">
                            {t("jobs.approve")}
                          </Button>
                        </div>
                      )}
                    </>
                }

              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
