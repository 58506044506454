import React from 'react'
import PriceList from './PriceList'
import { useTranslation } from "react-i18next";

function Price() {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("inapp")}</span>
      <PriceList type="terminals" label={t("inapp")} t={t} />
    </div>
  );
}

export default Price
