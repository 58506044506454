import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import UserForm from "./UserForm";

export default class Users extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      search: ''
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    const { page, size, search } = this.state;
    apiClient
      .get("admin-company/get-company", { params: { page, size, search }, optional: Boolean(search) })
      .then(({ status, data: { page, size, data, totalCount } }) => {
        if (status === 200) {
          size = size < 10 ? 10 : size;
          this.setState({
            page,
            size,
            data: data,
            totalCount,
            total_page: Math.ceil(totalCount / size)
          });
        }
      });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };


  handleSubmit = (rowData) => {
    var bodyData = {
      isActive: false,
      comment: rowData.comment
    };
    apiClient.put("admin-company/block/" + rowData.id, bodyData).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getUserData();
      }
    });
    this.toggleModal();
  };


  handleChange = (name, value) => {
    this.setState({ [name]: value }, () => this.getUserData());
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getUserData());
  };

  render() {
    const { mode, data, edit_row, size, total_page, page, totalCount, search } = this.state;
    const { label = "", t } = this.props;
    return (
      <>
        <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>{t("company.block")}</ModalHeader>
          <ModalBody>
            {edit_row && (
              <div className="fields">
                <UserForm data={edit_row} title="Send Password" mode={this.state.mode} onSubmit={this.handleSubmit} t={t} />
              </div>
            )}
          </ModalBody>
        </Modal>
        <div className="search-box row col-5">
          <i className="fi flaticon-loupe" />
          <Input
            type="search"
            placeholder="Search here.."
            value={search}
            onChange={(e) => this.handleChange("search", e.target.value)}
          />
        </div>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          column={[
            { title: t("approval.companyName"), field: "companyName" },
            { title: t("approval.email"), field: "email" },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("view", rowData)
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData)
            },
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: false,
            isCollapsable: true,
            expandActions: true,
            pagination: { showPagination: true, pageSelection: true, total_page, page, size, totalCount }
          }}
        />
      </>
    );
  }
}
