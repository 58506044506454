import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          title: "Title",
          "table.show": "Show",
          "table.entries": "Entries",
          "table.actions": "Actions",
          "table.showing": "Showing",
          "jobs.location": "Location",
          "jobs.offers": "Jobs offers",
          "jobs.heading": "Jobs",
          "jobs.postedBy": "PostedBy",
          "jobs.designation": "Designation",
          "jobs.comment": "Comment",
          "jobs.approve": "Approve",
          "jobs.reject": "Reject",
          "jobs.msg": "Are you sure want to Reject the Job Offer",
          "project.msg": "Are you sure want to delete the project",
          "approval.request": "Approval Request",
          "approval.companyName": "Company Name",
          "approval.email": "Email",
          "approval.comment": "Add a comment",
          "approval.approval": "Company Approval",
          "approval.address": "Address Line",
          "approval.city": "City",
          "approval.state": "State",
          "approval.zipCode": "Zip Code",
          "ad.title": "Advertisement",
          "ad.category": "Category",
          "ad.duration": "Duration",
          "ad.subject": "Subject",
          "ad.postedBy": "Posted By",
          "ad.approve": "Approve",
          "ad.reject": "Reject",
          "ad.website": "Website Link",
          "ad.rejectConform": "Confirm Reject",
          "ad.deleteConfirm": "Confirm Delete",
          "ad.company": "Are you sure want to Reject",
          "ad.cancel": "Cancel",
          "ad.yes": "Yes",
          "ad.forRole": "Both Role",
          "ad.description": "Description",
          "ad.edit": "Approve",
          "ad.both": "Post this Announcement for student also",
          "ad.boths": "Post this Announcement for company also",
          "ad.view": "View",
          "price.title": "Payment Plans",
          "price.id": "Product Id",
          "price.doller": "Price in USD",
          "price.titleM": "View Price",
          "price.titleMe": "Edit Price",
          "price.update": "Update",
          "price.delete": "Delete",
          "price.add": "Add New",
          "price.addPrice": "Add Price",
          "price.price": "Payment Plan",
          "company.block": "Block",
          "company.unBlock": "Un Block",
          "company.access": "Block Access",
          "company.company": "Companies",
          "company.blocked": "Blocked Company",
          "approval.company": "Company Request",
          "approval.student": "Student Request",
          "company.reason": "Reason",
          "company.announcement": "Announcements",
          "company.cAnnouncement": "Company Announcements",
          "img.upload": "Upload Image",
          "img.update": "Update Image",
          "ct.title": "Category",
          "ct.category": "Category Name",
          "ct.module": "Module",
          "ct.addCategory": "Add Category",
          "ct.add": "Add",
          "ct.msg": "Are you sure want to delete this Category",
          "ct.deleteTitle": "Conform Delete",
          "val.msg": "Field is required",
          "student.Student": "Students",
          "student.blocked": "Blocked Students",
          "student.Access": "App Access",
          "student.country": "Country",
          "student.firstName": "First Name",
          "student.lastName": "Last Name",
          "student.mobile": "Mobile Number",
          "course.title": "Courses",
          "course.name": "Course Name",
          "course.document": "Document Not Attached",
          "announcement.document": "Video Not Attached",
          "course.category": "Category",
          "course.Description": "Description",
          "course.newCourse": "Add New Course",
          "course.price": "Price",
          "course.documentUrl": "Document URL",
          "course.documentUrlF": "Document URL for French",
          "course.uploadNew": "Upload New Document",
          "course.update": "Update",
          "course.add": "Add New Course",
          "lesson.title": "Lesson",
          "lesson.add": "Add New Lesson",
          "lesson.name": "Lesson Name",
          "delete.msg": "Confirm Deletion!",
          "delete.d": "Are you sure want to delete",
          "lesson.deletemsg": "Are you sure want to delete the",
          "course.deletemsg": "Are you sure want to delete the course",
          "quiz.title": "Quiz Questions",
          "quiz.add": "Add New Question",
          "quiz.name": "Quiz Name",
          "quiz.question": "Question",
          "quiz.option1": "Option 1",
          "quiz.option2": "Option 2",
          "quiz.option3": "Option 3",
          "quiz.option4": "Option 4",
          "quiz.answer": "Valid Answer",
          "quiz.addQuestion": "Add Question",
          "pay.title": "Payment History",
          "pay.id": "Payment Id",
          "pay.date": "Transaction Date",
          "pay.type": "Type",
          "pay.status": "Status",
          "pay.role": "Role",
          "pay.amount": "Amount Received",
          "pay.currency": "Currency",
          "quiz.questionName": "Question Name",
          "quiz.answers": "Answer",
          "quiz.uploadVideo": "Upload video less than 100 mb",
          "announcement.videoUrl": "Video URL",
          "announcement.videoUrlinFrench": "Video URL in French",
          "sidebar.job": "Jobs",
          "sidebar.company": "Company",
          "sidebar.individual": "Individuals",
          "course.free": "Publish a free course",
          "user.dob": "Date of Birth",
          Advertisement: "Advertisement",
          old_details: "Old details",
          new_details: "New details",
          gender: "Gender",
          no_data: "No Data Found",
          answer: "Answer",
          "price.productId": "Price",
          name: "Name",
          inapp: "In App Prices",
          "faq-video": "Add a Video Or Image",
          screenRecord: "Enable Screen Recording Features for this User",
          "user-disable": "Disable User Access",
          "ad.free": "Make this Advertisement for Free",
          "password.old": "Old Password",
          "password.new": "New Password",
          "password.confirm": "Re-enter New Password",
          "password.button": "Change Password",
          "password.back": "Back",
          logout: "Logout",
          "adStatus.active": "Active Advertisement",
          "adStatus.blocked": "Blocked Advertisement",
          configurable_count: "Report Limit",
          "ad-link": "Video Link",
          "ad.email": "Agent email",
          "ad.imageurl": "Image URL",
          "version.android": "Android Version",
          "version.ios": "IOS Version",
          "version.update": "Update Version",
          "campaign.title": "Campaign",
          "campaign.message": "Message",
          attachment: "attachment",
          "mail.send": "send",
          "jobs.new": "New Jobs",
          "jobs.reviewed": "Reviewd Jobs",
          "job.review": "Reviews",
          "job.rating": "Rating",
          "ad.new": "New Advertisement",
          "ad.reviewed": "Reviewed Advertisement",
          "project.title": "Project",
          "project.new": "All Projects",
          "project.reviewed": "Reviewed Projects",
          "project.pending": "Approval Pending Projects",
          "project.approve": "Approve",
          "project.value": "Value of Project",
          "prject.personal-c": "Personal Contribution",
          "project.isCompany": "Do you have a company",
          "project.creation": "Creation date",
          "project.company-type": "Type of company",
          "project.business": "Do you have a business plan",
          "project.co": "Do you have co-founders",
          "project.co-how": "How Many Co founders",
          "project.employee": "Do you have employees",
          "project.employes-how": "How Many Employees",
          "project.nationality": "Current nationality",
          "project.origin-county": "Country of origin",
          "project.telephone": "Telephone",
          "project.phone": "Phone",
          "project.professional": "Professional training ",
          "project.products": "What are your products / services",
          "project.unit-price":
            "What is the average unit price of your products / services(usd)",
          "project.firstyear":
            "How much sales do you plan on generating in the first year(usd)",
          "project.second":
            "How much sales do you plan on generating in the second year(usd)",
          "project.expenses": "What will the main expenses be(usd)",
          "project.hours": "What will your hours of operation be",
          "project.suppliers": "Who are your key suppliers",
          "project.customers": "Who are your main customers",
          "project.clients": "How will you find your clients",
          "project.isBank": "Do you do business with a bank",
          "project.which-bank": "If yes which one",
          "project.loan": "Did you take a loan for your project",
          "project.loan-how": "If so, how much",
          "project.details": "Details",
          "project.general": "Genaral Information",
          "project.profile": "Profile of the main founder",
          "project.operation": "Operations",
          "project.financial": "Financial informations",
          "project.other": "Other important details",
          "project.ratingdisabled": "Can't Rate this project",
          templateId: "Template Id",
          ispaidVerification: "Paid for verification",
          isPaid: "Paid",
          approve: "Approved",
          "project.paid": "Share in priority",
          "campaign.custom": "Campaign Contacts",
          adduser: "Add User",
          area: "Area",
          type: "Type",
        },
      },
      fe: {
        translations: {
          title: "Titre",
          "table.show": "Spectacle",
          "table.entries": "Entrées",
          "table.actions": "Actions",
          "table.showing": "Montrant",
          "jobs.location": "emplacement",
          "jobs.offers": "Offres d'emploi",
          "jobs.heading": "Travaux",
          "jobs.postedBy": "Posté par",
          "jobs.designation": "La désignation",
          "jobs.comment": "Commenter",
          "jobs.approve": "Approuver",
          "jobs.reject": "Rejeter",
          "jobs.msg": "Voulez-vous vraiment rejeter l'offre d'emploi",
          "approval.request": "Demande d'approbation",
          "approval.companyName": "Nom de la compagnie",
          "delete.d": "Voulez-vous vraiment supprimer",
          "approval.email": "E-mail",
          "approval.comment": "Ajouter un commentaire",
          "approval.approval": "Approbation de l'entreprise",
          "approval.address": "Ligne d'adresse",
          "approval.city": "Ville",
          "approval.state": "État",
          "approval.zipCode": "Code postal",
          "ad.title": "Publicité",
          "ad.approve": "Approuver",
          "ad.reject": "Rejeter",
          "ad.rejectConform": "Conforme Rejeter",
          "ad.company": "Voulez-vous vraiment Rejeter",
          "ad.cancel": "Annuler",
          "ad.yes": "Oui",
          "ad.category": "Catégorie",
          "ad.duration": "Durée",
          "ad.subject": "Matière",
          "ad.website": "Lien de site Web",
          "ad.postedBy": "posté par",
          "ad.description": "La description",
          "ad.edit": "Éditer",
          "ad.forRole": "Les deux Rôle",
          "ad.both": "Publier cette annonce pour les étudiants aussi",
          "ad.boths": "Publiez également cette annonce pour l'entreprise",
          "ad.view": "Vue",
          "price.title": "Plans de paiement",
          "price.id": "Identifiant du produit",
          "price.doller": "Prix ​​en USD",
          "price.titleM": "Voir le prix",
          "price.titleMe": "Modifier le prix",
          "price.update": "Mettre à jour",
          "price.add": "Ajouter un nouveau",
          "price.addPrice": "Ajouter un prix",
          "price.price": "Plan de paiement",
          "company.block": "Bloquer",
          "company.access": "Bloquer l'accès",
          "company.company": "Entreprises",
          "company.blocked": "Entreprise bloquée",
          "company.reason": "Raison",
          "company.unBlock": "Débloquer",
          "company.announcement": "Annonces",
          "company.cAnnouncement": "Annonces de l'entreprise",
          "img.upload": "Télécharger une image",
          "img.update": "Mettre à jour l'image",
          "ct.title": "Catégorie",
          "ct.category": "Nom de catégorie",
          "ct.module": "Module",
          "ct.addCategory": "ajouter une catégorie",
          "ct.add": "Ajouter",
          "ct.msg": "Voulez-vous vraiment supprimer cette catégorie",
          "ct.deleteTitle": "Conforme Supprimer",
          "val.msg": "Champ requis",
          "student.Student": "Étudiantes",
          "student.blocked": "Étudiants bloqués",
          "student.Access": "Accès à l'application",
          "student.country": "Pays",
          "student.firstName": "Prénom",
          "student.lastName": "Nom de famille",
          "student.mobile": "Numéro de portable",
          "course.title": "Cours",
          "course.name": "Nom du cours",
          "course.category": "Catégorie",
          "course.Description": "La description",
          "course.newCourse": "Ajouter un nouveau cours",
          "course.price": "Prix",
          "course.document": "Document non joint",
          "course.documentUrl": "URL du document",
          "announcement.document": "Vidéo non attachée",
          "course.documentUrlF": "URL du document pour le français",
          "course.uploadNew": "Télécharger un nouveau document",
          "course.update": "Mettre à jour",
          "course.add": "Ajouter un nouveau cours",
          "lesson.title": "Leçon",
          "lesson.add": "Ajouter une nouvelle leçon",
          "lesson.name": "Nom de la leçon",
          "lesson.deletemsg": "Voulez-vous vraiment supprimer le",
          "course.deletemsg": "Voulez-vous vraiment supprimer le cours",
          "quiz.title": "Questions de quiz",
          "quiz.add": "Ajouter une nouvelle question",
          "quiz.question": "Question",
          "quiz.option1": "Option 1",
          "quiz.option2": "Option 2",
          "quiz.option3": "Option 3",
          "quiz.option4": "Option 4",
          "quiz.answer": "Réponse valide",
          "quiz.addQuestion": "Ajouter une question",
          "delete.msg": "Confirmer la suppression!",
          "quiz.name": "Quiz Name",
          "pay.title": "historique de paiement",
          "pay.id": "Identifiant de paiement",
          "pay.date": "Date de la transaction",
          "pay.type": "Taper",
          "pay.status": "Statut",
          "pay.role": "Rôle",
          "pay.amount": "Montant reçu",
          "pay.currency": "Devise",
          "quiz.questionName": "Nom de la question",
          "quiz.answers": "Répondre",
          "quiz.uploadVideo": "Télécharger une vidéo de moins de 100 Mo",
          "announcement.videoUrl": "URL de la vidéo",
          "announcement.videoUrlinFrench": "URL de la vidéo en français",
          "sidebar.job": "travaux",
          "sidebar.company": "Compagnie",
          "sidebar.individual": "Personnes",
          "course.free": "Publier un cours gratuit",
          "user.dob": "Date de naissance",
          Advertisement: "Publicité",
          old_details: "Détails anciens",
          new_details: "Nouveaux détails",
          gender: "Genre",
          no_data: "Aucune donnée disponible",
          "approval.student": "Demande d'étudiant",
          "approval.company": "Demande d'entreprise",
          answer: "réponse",
          "price.productId": "Prix",
          name: "Nom",
          inapp: "Dans les prix de l'application",
          "faq-video": "Ajouter une vidéo ou une image",
          screenRecord:
            "Activer les fonctionnalités d'enregistrement d'écran pour cet utilisateur",
          "user-disable": "Désactiver l'accès utilisateur",
          "ad.free": "Faire cette publicité gratuitement",
          "password.old": "ancien mot de passe",
          "password.new": "nouveau mot de passe",
          "password.confirm": "Ré-entrez le nouveau mot de passe",
          "password.button": "Changer le mot de passe",
          "password.back": "Arrière",
          logout: "Se déconnecter",
          "adStatus.active": "Publicité active",
          "adStatus.blocked": "Publicité bloquée",
          configurable_count: "Limite de rapport",
          "ad-link": "Lien vidéo",
          "ad.email": "Courriel de l'agent",
          "ad.imageurl": "URL de l'image",
          "version.android": "Version Android",
          "version.ios": "Version IOS",
          "version.update": "Version de mise à jour",
          "campaign.title": "Campagne",
          "campaign.message": "Message",
          "mail.send": "envoyer",
          "jobs.new": "Nouveau travail",
          "jobs.reviewed": "Emplois évalués",
          "job.review": "Commentaires",
          "job.rating": "Évaluation",
          "ad.new": "Nouvelle annonce",
          "ad.reviewed": "Annonce examinée",
          "project.title": "Projet",
          "project.new": "Tous les projets",
          "project.reviewed": "Projets examinés",
          "project.value": "Valeur du projet",

          "prject.personal-c": "Contribution personnelle",
          "project.isCompany": "Avez-vous une entreprise",
          "project.creation": "Date de création",
          "project.company-type": "Type de compagnie",
          "project.business": "Avez-vous un plan d'affaires",
          "project.co": "Avez-vous des co-fondateurs",
          "project.co-how": "Combien de co-fondateurs",
          "project.employee": "Avez-vous des employés",
          "project.employes-how": "Combien d'employés",
          "project.nationality": "Nationalité actuelle",
          "project.origin-county": "Pays d'origine",
          "project.telephone": "Téléphone",
          "project.phone": "Téléphoner 2",
          "project.professional": "Formation professionnelle",
          "project.products": "Quels sont vos produits/services",
          "project.unit-price":
            "Quel est le prix unitaire moyen de vos produits/services (usd)",
          "project.firstyear":
            "Combien de ventes prévoyez-vous de générer au cours de la première année (USD)",
          "project.second":
            "Combien de ventes prévoyez-vous de générer au cours de la deuxième année (USD)",
          "project.expenses": "Quelles seront les principales dépenses (USD)",
          "project.hours": "Quelles seront vos heures d'ouverture",
          "project.suppliers": "Quels sont vos principaux fournisseurs",
          "project.customers": "Quels sont vos principaux clients",
          "project.clients": "Comment allez-vous trouver vos clients",
          "project.isBank": "Faites-vous affaire avec une banque",
          "project.which-bank": "Si oui lequel",
          "project.loan": "Avez-vous contracté un prêt pour votre projet",
          "project.loan-how": "Si oui, combien",
          "project.details": "Des détails",
          "project.general": "Informations générales",
          "project.profile": "Profil du principal fondateur",
          "project.operation": "Opérations",
          "project.financial": "Informations financières",
          "project.other": "Autres détails importants",
          "project.ratingdisabled": "Impossible d'évaluer ce projet",
          templateId: "Identifiant du modèle",
          ispaidVerification: "Est payé pour la vérification",
          isPaid: "est payé",
          approve: "a approuvé",
          "project.paid": "Partager en priorité",
          "campaign.custom": "Coordonnées de la campagne",
          adduser: "Ajouter un utilisateur",
          area: "Zone",
          type: "Taper",
        },
      },
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: false, // we use content as keys
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
