import React, { useState } from "react";
import BlockList from './BlockList'
import HeaderCard from '../../../../components/HeaderCards'
import TabNav from '../../../../components/TabNav'
import Tab from '../../../../components/Tab'
import UnBlock from "./UnBlock";
import ScreenAccess from '../ScreenAccess/ScreenList'
import { useTranslation } from "react-i18next";

export default function Block({ heading }) {
  const { t } = useTranslation();
  const [selected, setselected] = useState(t("student.Student"))
  const setSelected = (tab) => {
    setselected(tab)
  }

  return (
    <div className="subscription-management">
      <span className="page-header">{t("student.Student")}</span>
      <TabNav tabs={[t("student.Student"), t("student.blocked"), t("student.Access")]} selected={selected} setSelected={setSelected}>
        <Tab isSelected={selected === t("student.Student")}>
          <BlockList type="terminals" label={t("student.Student")} t={t} />
        </Tab>
        <Tab isSelected={selected === t("student.blocked")}>
          <UnBlock type="terminals" label={t("student.blocked")} t={t} />
        </Tab>
        <Tab isSelected={selected === t("student.Access")}>
          <ScreenAccess type="terminals" label={t("student.Access")} t={t} />
        </Tab>
      </TabNav>
    </div>
  );
}
