import React, { Component } from "react";
import { connect } from "react-redux";
import { Nav } from "reactstrap";
import { getMenu } from "../utils/routes";
import MenuNavItem from "./MenuNavItem";
import { Badge } from '@material-ui/core'
import apiClient from "../utils/apiClient";

class SideBar extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      datas: [],
      jobOffers: [],
      advertisement: []
    };
  }

  componentDidMount() {
    this.getData();
    this.getUserData();
    this.getJobOffers();
    this.getAdvertisement()
  }

  getData = () => {
    const { page, size } = this.state;
    apiClient.get("students/get-student", { params: { page, size } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 5 ? 5 : size;
        this.setState({
          data,
        });
      } else {
        console.log('error')
      }
    });
  };

  getJobOffers = () => {
    const { page, size } = this.state;
    apiClient.get("admin-company/getJob",).then(({ status, data: { data } }) => {
      if (status === 200) {
        this.setState({
          jobOffers: data
        })
      }
      else {
        console.log('error');
      }
    });
  };

  getAdvertisement = () => {
    apiClient.get('dashboard/getsA').then(({ status, data }) => {
      if (status === 200) {
        this.setState({
          advertisement: data.data
        });
      } else {
        console.log('error');
      }
    });
  };

  getUserData = () => {
    const { page, size } = this.state;
    apiClient.get("company/get-company", { params: { page, size } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 5 ? 5 : size;
        this.setState({
          datas: data,

        });
      } else {
        console.log('error');
      }
    });
  };


  render() {
    const {
      show,
      toggle,
      userData: { companyLogo },
      t
    } = this.props;
    var jobOffers = this.state.jobOffers && this.state.jobOffers.length
    var advertisement = this.state.advertisement && this.state.advertisement.length
    return (
      <div className={`sidebar ${show && `show`}`}>
        <Nav vertical className="pt-4">
          {getMenu(t, jobOffers, advertisement).map((item, i) => (
            item.badge ?
              <Badge badgeContent={this.state.data.length + this.state.datas.length} color="primary" >
                <MenuNavItem
                  key={i}
                  to={item.to}
                  children={item.children}
                  toggle={toggle}
                  name={item.name}
                  icon={item.icon}
                  count={"12"}
                >
                  {/* {console.log('hello')} */}
                  {/* <Badge badgeContent={item.count} color="primary" /> */}
                </MenuNavItem>
              </Badge>
              :
              <MenuNavItem
                key={i}
                to={item.to}
                children={item.children}
                toggle={toggle}
                name={item.name}
                icon={item.icon}
              >
                {/* {console.log('hi')} */}
              </MenuNavItem>


          ))}
        </Nav>
      </div>
    );
  }
}
export default connect(({ user: { userData } }) => ({ userData }))(SideBar);
