import React, { useEffect, useState } from "react";
import CourseList from '../Courses/CoursesList'
import HeaderCard from '../../../../components/HeaderCards'
import { useTranslation } from "react-i18next";
import apiClient from '../../../../utils/apiClient'
export default function Courses({ heading }) {
  const [productId, setproductId] = useState('')
  const [priceProductId, setpriceProductId] = useState('')
  const [duration, setduration] = useState('')
  useEffect(() => {
    getProductId()
  }, [productId])
  const { t } = useTranslation();
  const getProductId = () => {
    apiClient
      .put(`dashboard/get-productIds/${productId}`)
      .then(({ status, data }) => {
        if (status === 200) {
          data.data && setpriceProductId(data.data[0].productId)
          data.data && setduration(data.data[0].duration)
        } else {
          setpriceProductId("")
          setduration("")
        }
      });
  };
  return (
    <div className="subscription-management">
      <span className="page-header">{t("course.title")}</span>
      <CourseList type="terminals" label={t("course.title")} t={t} courseProductId={setproductId} productId={priceProductId} duration={duration} durationProductId={setduration} />
    </div>
  );
}
