import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import ScreenAccessForm from "./ScreenAccessForm";

export default class ScreenList extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      show_date: moment(),
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      search: ''
    };
  }

  componentDidMount() {
    this.getData();
  }


  getData = () => {
    const { page, size, search } = this.state;
    apiClient.get("admin-company/get-students", { params: { page, size, search }, optional: Boolean(search) }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      } else {
        console.log('error')
      }
    });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  handleSubmit = (rowData) => {
    console.log(rowData)
    var bodyData = {
      isScreenRecording: rowData.isScreenRecording,
    };
    apiClient.put("admin-company/block/" + rowData._id, bodyData).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
    this.toggleModal();
  };


  handleChange = (show_date) => {
    this.setState({ show_date });
  };

  handleDelete = (id) => {
    apiClient.delete("complaints/delete/" + id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
  };

  /* handle change for search */
  handleSearchChange = (name, value) => {
    this.setState({ [name]: value });

    if (value.length === 0) {
      this.setState({ [name]: "" }, () =>
        this.getData());

    } else if (value.length >= 3) {
      this.getData()
    }
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };
  render() {
    const { mode, edit_row, data, total_page, size, page, totalCount, search } = this.state;
    const { label = "", t } = this.props;
    return (
      <>
        <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>
            {t("company.block")}
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              {mode && (
                <ScreenAccessForm
                  data={edit_row}
                  mode={mode}
                  title="Block Students"
                  t={t}
                  onSubmit={this.handleSubmit}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
        <div className="search-box row col-5">
          <i className="fi flaticon-loupe" />
          <Input
            type="search"
            placeholder="Search here.."
            value={search}
            onChange={(e) => this.handleSearchChange("search", e.target.value)}
          />
        </div>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          key={data.id}
          column={[
            { title: t("student.firstName"), field: "firstName" },
            { title: t("approval.email"), field: "email" },
            { title: t("student.country"), field: "country" },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("view", rowData)
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData)
            },
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: false,
            isCollapsable: true,
            expandActions: true,
            pagination: { showPagination: true, pageSelection: true, total_page, page, size, totalCount }
          }}
        />
      </>
    );
  }
}
