import clsx from "clsx";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CardList from "../../../../../components/CardList";
import apiClient from "../../../../../utils/apiClient";
import NewAdsForm from "./NewAdsForm";
import { progress } from "../../../../../redux/user/actions";
import {
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { countsData } from '../../../../../utils/Config'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default class NewAds extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      edit_row_id: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      configurable_count: 3,
      blockingLimitId: '',
      progress: '',
      loader: false,
      categoryData: [],
    };
  }

  componentDidMount() {
    this.getData();
    this.getBlockingLimit();
    this.getCategory()
  }


  /* get list of category */
  getCategory = () => {
    apiClient.get("category/get-category", { params: { module: 'advertisement' } }).then(({ status, data }) => {
      if (status === 200) {
        this.setState({ categoryData: data.data })
      }
      else {
        this.setState({ categoryData: [] })
      }
    });
  };


  getData = () => {
    const { page, size } = this.state;
    const status = 'new'
    apiClient.get('dashboard/getsA', { params: { page, size, status } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      }
    });
  };

  getBlockingLimit = () => {
    const { page, size } = this.state;
    apiClient.get('company/ads/report-count', { params: { page, size } }).then(({ data: { size, data, status } }) => {
      if (status === 200) {
        this.setState({
          configurable_count: data.count,
          blockingLimitId: data._id
        });
      }
    });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  handleSubmit = (data) => {
    const { mode, categoryData } = this.state
    const rowData = this.state.edit_row
    if (mode == 'edit') {

      /* find the category objectId based on category name */
      const category = categoryData.find(
        (categoryItem) => categoryItem.name == data['adCategory']
      )._id;

      var form_data = new FormData();
      form_data.append("adCategory", category);
      form_data.append("description", data['description']);
      form_data.append("title", data['title']);
      form_data.append("adLink", data['adLink']);
      form_data.append("companyName", data['companyName']);
      if (data.adVideo.length > 0) {
        form_data.append("adVideo", data.adVideo[0]);
      }
      if (data.adVideoInFr.length > 0) {
        form_data.append("adVideoInFr", data.adVideoInFr[0]);
      }
      if (data.imageAd.length > 0) {
        form_data.append("imageAd", data.imageAd[0]);
      } else {
        form_data.append("imageAd", rowData['imageAd']);
      }
      form_data.append("phone", data['phone']);
      form_data.append("referalEmail", data['referalEmail']);
      form_data.append("rating", data['rating']);
      form_data.append("ratingComments", data['ratingComments']);
      var options = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          let percent = Math.round((loaded * 100) / total)
          this.setState({
            progress: percent
          })
          console.log(`${loaded} kb of ${total} kb | ${percent}%`)
        }
      }
      apiClient.put(`company/ads/ads-edit-admin/${data['id']}`, form_data,
        options,
        this.setState({
          loader: true
        }),
      ).then(({ status, data }) => {
        this.setState({ loader: false })
        if (status === 200) {
          progress(false)
          this.getData();
          toast.success(data.message);
        } else {
          progress(false)
          this.getData();
          toast.error(data.message);
        }
        this.toggleModal();
      })
    } else {
      var bodyData
      data.adminblockingStatus == true ?
        bodyData = {
          approved: false,
          blockingStatus: true,
          status: 'waiting',
          reportCount: 0,
          productId: data['productId']
        } :
        bodyData = {
          approved: true,
          status: 'approved',
          madeFreeByAdmin: data['madeFreeByAdmin'],
          blockingStatus: false,
          adminblockingStatus: false,
          productId: data['productId']
        }
      apiClient.put("admin-company/approve-ad/" + data.id, bodyData).then(({ status, data }) => {
        if (status === 200) {
          this.getData();
          toast.success('Updated Successfully');
        } else {
          toast.error(data.message);
        }
      });
      this.toggleModal();
    }
  };

  handleDelete = (data) => {
    this.setState({ mode: 'reject' })
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };


  handleCountchange = (e) => {
    const { blockingLimitId } = this.state
    this.setState({ configurable_count: e.currentTarget.textContent })
    var bodyData = {
      count: e.currentTarget.textContent
    }
    apiClient.put("company/ads/report-count/" + blockingLimitId, bodyData).then(({ status, data }) => {
      if (status === 200) {
        this.getData();
        toast.success('Updated Successfully');
      } else {
        toast.error(data.message);
      }
    });
  }

  render() {

    const { mode, edit_row, data, size, total_page, page, totalCount, configurable_count, categoryData } = this.state;
    const { label = "", t } = this.props;
    const touchedData = categoryData && categoryData.map((item) => ({ label: item.name, value: item.name }));

    return (
      <>
        {this.state.loader ?
          <div className="progressBar">
            <CircularProgressbar value={this.state.progress} text={`${this.state.progress}%`} />
          </div>
          :
          <>
            <Modal className="course-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
              <ModalHeader toggle={() => this.toggleModal()}>
                {mode
                  ? mode === "add"
                    ? "Add New " + label
                    : mode === "edit"
                      ? t("ad.approve") + " " + label
                      : t("ad.view") + " " + label
                  : ""}
              </ModalHeader>
              <ModalBody>
                <div className="fields">
                  {mode && (
                    <NewAdsForm
                      data={edit_row}
                      categorData={touchedData}
                      mode={mode}
                      title="Add new counter"
                      onSubmit={this.handleSubmit}
                      t={t}
                      onDelete={this.handleDelete}
                    />
                  )}
                </div>
              </ModalBody>
            </Modal>
            <CardList
              containerClassName="mx-5 mb-5 mt-4"
              title={label}
              subHeaderComponent={
                <>
                  <UncontrolledDropdown className="ml-auto">
                    <span className="ml-auto">{t("configurable_count")} </span>
                    <DropdownToggle caret outline color="primary" size="xs">
                      {configurable_count}
                    </DropdownToggle>
                    <DropdownMenu right>
                      {countsData.map((item, i) => {
                        return (
                          <DropdownItem key={i} onClick={this.handleCountchange}>
                            {item.label}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </>
              }
              column={
                [
                  {
                    title: t("title"),
                    field: "title",
                    render: (rowData) => <span className={clsx("title", rowData.title)}>{rowData.title}</span>
                  },
                  {
                    title: t("ad.category"),
                    field: "adCategory",
                    render: (rowData) => <span className={clsx("adCategory", rowData.adCategory)}>{rowData.adCategory}</span>
                  },
                  {
                    title: t("ad.duration"),
                    field: "duration",
                    render: (rowData) => <span className={clsx("duration", rowData.duration)}>{rowData.duration}</span>
                  },
                  {
                    title: t("ad.postedBy"),
                    field: "postedBy",
                    render: (rowData) => <span className={clsx("postedBy", rowData.postedBy)}>{rowData.postedBy}</span>
                  },
                  {
                    title: t("pay.status"),
                    field: "blockingStatus",
                    render: (rowData) => <span className={clsx(rowData.blockingStatus == 'active' ? 'status-success' : 'status', rowData.blockingStatus)}>{rowData.blockingStatus == 'active' ? "Active" : "Blocked"}</span>
                  },
                ]}
              data={data}
              key={data && data.id}
              actions={
                [
                  {
                    icon: "fi flaticon-view-1",
                    tooltip: "View",
                    className: "btn-white text-primary p-0",
                    onClick: (rowData) => this.toggleModal("view", rowData)
                  },
                  {
                    icon: "fi flaticon-lock",
                    className: "btn-white text-primary p-0",
                    // tooltip: "Block",
                    onClick: (rowData) => this.toggleModal("block", rowData)
                  },
                  {
                    icon: "fi flaticon-edit",
                    className: "btn-white text-primary p-0",
                    onClick: (rowData) => this.toggleModal("edit", rowData)
                  },
                ]}
              handlePageChange={this.onPageChange}
              options={{
                subHeader: true,
                isCollapsable: true,
                expandActions: true,
                pagination: {
                  showPagination: true,
                  pageSelection: true,
                  total_page,
                  totalCount,
                  page,
                  size
                }
              }}
            />
          </>
        }
      </>
    );
  }
}
