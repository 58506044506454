import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput } from "../../../../components/FormikFields";
import { blockSchema } from "../../../../utils/validationSchema";

export default class BlockForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: ""
    };
  }

  render() {
    const { onSubmit, mode, data, t } = this.props;
    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            validationSchema={blockSchema}
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}>
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.firstName")}
                    name="firstName"
                    disabled
                    value={values.firstName}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.lastName")}
                    name="lastName"
                    disabled
                    value={values.lastName}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.email")}
                    name="email"
                    disabled
                    // error={touched.module && errors.module}
                    value={values.email}
                    onChange={setFieldValue}
                  // onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.mobile")}
                    name="mobileNumber"
                    disabled
                    value={values.mobileNumber}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.address")}
                    name="addressLine1"
                    disabled
                    value={values.addressLine1}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.city")}
                    name="city"
                    disabled
                    value={values.city}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.state")}
                    name="state"
                    disabled
                    value={values.state}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("student.country")}
                    name="country"
                    disabled
                    value={values.country}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.zipCode")}
                    name="zipCode"
                    disabled
                    value={values.country}
                    onChange={setFieldValue}
                  />
                </FormGroup>
                {
                  mode === 'edit' &&
                  <FormGroup className="col-12 px-4">
                    <FormikInput
                      label={t("company.reason")}
                      name="comment"
                      multiline
                      error={touched.comment || errors.comment}
                      value={values.comment}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                }
                {mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button type="submit" color="success" className="my-4 mx-auto">
                      {t("company.access")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
