import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, } from "../../../components/FormikFields";
import { consolePriceSchema } from "../../../utils/validationSchema";
import i18n from "../../../i18n";

export default class PriceForm extends Component {
  render() {
    const { data, onSubmit, mode, t } = this.props;
    return (
      <div className="add-counter">
        <div className="fields col-11 mx-auto">
          <Formik
            validationSchema={consolePriceSchema}
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={t("price.productId")}
                      name="price"
                      disabled={mode === "view"}
                      error={touched.price && errors.price}
                      value={values.price}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={t("price.id")}
                      name="productId"
                      disabled={mode === "view" || mode === "edit"}
                      error={errors.productId && !values.productId ? errors.productId : false}
                      value={values.productId}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={t("name")}
                      name="name"
                      disabled={mode === "view"}
                      error={errors.name && !values.name ? errors.name : false}
                      value={values.name}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                </>
                {mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button
                      type="submit"
                      color="success"
                      className="my-4 mx-auto"
                    >
                      {mode === "edit" ? t("price.update") : t("ct.add")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
