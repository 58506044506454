import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import TabNav from '../../../../components/TabNav';
import Tab from '../../../../components/Tab';
import NewProject from './NewProjects/NewProject';
import ReviewedProject from './ReviewedProjects/ReviewdProject';
import PendingProject from './PendingProjects/PendingProject';
import apiClient from '../../../../utils/apiClient';
import { toast } from 'react-toastify';

function Project() {
  const { t } = useTranslation();
  const [selected, setselected] = useState(t('project.pending'));

  /* select the job */
  const setSelected = (tab) => {
    setselected(tab);
  };

  /* sync projects api call */
  function syncProjects() {
    apiClient.post('admin-project/sync').then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
      }
    });
  }

  return (
    <div className="subscription-management">
      <div className="d-flex">
        <span className="page-header">{t('project.title')}</span>
        <Button className="ml-auto mr-5" style={{ cursor: 'pointer' }} color="success" onClick={syncProjects}>
          Sync all Projects
        </Button>
      </div>

      <TabNav tabs={[t('project.pending'), t('project.new'), t('project.reviewed')]} selected={selected} setSelected={setSelected}>
        <Tab isSelected={selected === t('project.pending')}>
          <PendingProject type="terminals" label={t('project.pending')} t={t} />
        </Tab>

        <Tab isSelected={selected === t('project.new')}>
          <NewProject type="terminals" label={t('project.new')} t={t} />
        </Tab>

        <Tab isSelected={selected === t('project.reviewed')}>
          <ReviewedProject type="terminals" label={t('project.reviewed')} t={t} />
        </Tab>
      </TabNav>
    </div>
  );
}

export default Project;
