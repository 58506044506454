import { USER_DATA, LOADING, COURSES, PROGRESS } from "../actions";
import { getLocalStorage } from "../../utils/Utils";

const INIT_STATE = {
  userData: getLocalStorage("userData") || "",
  isLoading: false,
  course: '',
  progress: false
};

export default (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case USER_DATA:
      return { ...state, userData: payload };
    case LOADING:
      return { ...state, isLoading: payload };
    case COURSES:
      return { ...state, courses: payload };
    case PROGRESS:
      return { ...state, progress: payload };
    default:
      return { ...state };
  }
};
