import React, { useEffect, useState } from 'react'
import Logo from '../assets/images/JPG.jpg'
import apiClient from '../utils/apiClient'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import "../i18n";
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  typography: {
    align: "center"
  }
}));

function Faq() {
  const classes = useStyles();
  const [data, setdata] = useState([])
  const [selectedLanguage, setselectedLanguage] = useState('en')

  const handleChange = (e) => {
    setselectedLanguage(e.target.value)
    window.location.reload();
  };
  const getData = () => {
    let language = selectedLanguage
    apiClient.get('faq/get-faq-mobile', { params: { language } }).then(({ status, data: { data } }) => {
      if (status === 200) {
        setdata(data)
      }
    });
  };
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className="container">
      <nav className="navbar navbar-light bg-light">
        <span className="navbar-brand" >
          <img src={Logo} width="50" height="50" alt="logo" />
        </span>
        <FormControl style={{ marginRight: "50px" }}>
          <InputLabel id="demo-simple-select-label">Language</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            onChange={handleChange}
            value={selectedLanguage}
          >
            <MenuItem>Select</MenuItem>
            <MenuItem value="en">English</MenuItem>
            <MenuItem value="fe">French</MenuItem>
          </Select>
        </FormControl>
      </nav>
      <h3 className="faq">Frequently Asked Questions</h3>
      <div className="text-center">
        <br />
        <div className={classes.root}>
          {
            data.map(item => {
              return <Accordion key={item._id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>{item.question}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography component={'div'} className={classes.typography}>
                    {item.answer}
                    {
                      item.answerVideo &&
                      <h4>below the {item.mediaFormat == 'video' ? "video" : "image"} answer</h4>
                    }
                    {
                      item.mediaFormat == 'video' ?
                        item.answerVideo ?
                          <div>
                            <video controls width="100%">
                              <source src={item.answerVideo}
                                type="video/mp4" />
                            </video>
                          </div> : null
                        : item.answerVideo ? <img alt="answervideo" src={item.answerVideo} width="100%" height="fit-content" /> : null

                    }
                  </Typography>
                </AccordionDetails>
              </Accordion>
            })
          }
        </div>
      </div>
    </div>

  )
}

export default Faq
