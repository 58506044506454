import { Form, Formik } from "formik";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, FormGroup } from "reactstrap";
import Alert from "../../../../components/Alert";
import { FormikInput } from "../../../../components/FormikFields";
import apiClient from "../../../../utils/apiClient";
export default class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      isCommentModalOpen: false,
      rejectionComment: null,
    };
  }

  handleApproveRequest(id) {
    const { onSubmit } = this.props;
    var bodyData = {
      isActive: true,
      status: "approved",
    };
    apiClient
      .put("admin-company/approve-student/" + id, bodyData)
      .then(({ status, data }) => {
        if (status === 200) {
          toast.success("Approved Successfully");
          onSubmit();
        } else {
          toast.error(data);
        }
      });
  }

  handleConfirmRejectRequest(id) {
    const { t } = this.props;
    Alert.info(t("ad.rejectConform"), t("ad.company"), () => {
      this.setState({
        isCommentModalOpen: true,
      });
    });
  }

  handleRejectRequest(id) {
    console.log(id);
    const { onSubmit } = this.props;
    const bodyData = {
      userComments: this.state.rejectionComment,
      status: "rejected",
    };
    apiClient
      .put("admin-company/approve-student/" + id, bodyData)
      .then(({ status, data }) => {
        if (status === 200) {
          onSubmit();
          toast.success("Rejected Successfully");
        }
      });
  }
  render() {
    const { mode, data, t, userData } = this.props;
    const oldUser = userData[0]
    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik enableReinitialize initialValues={data}>
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                {this.state.isCommentModalOpen ? (
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.comment")}
                      name="userComments"
                      value={this.state.rejectionComment}
                      onChange={(e, value) =>
                        this.setState({
                          rejectionComment: value,
                        })
                      }
                    />
                    <Button
                      onClick={() => this.handleRejectRequest(data.userId)}
                      color="success"
                      className="my-4 mx-auto"
                    >
                      Reject
                    </Button>
                  </FormGroup>
                ) : (
                  <>
                    <FormGroup className="col-6 px-4 text-center">{t("old_details")}</FormGroup>
                    <FormGroup className="col-6 px-4 text-center">{t("new_details")}</FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.firstName")}
                        name="firstName"
                        disabled={mode === "view"}
                        error={touched.ticketNo && errors.ticketNo}
                        value={oldUser.firstName}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.firstName")}
                        name="firstName"
                        disabled={mode === "view"}
                        error={touched.ticketNo && errors.ticketNo}
                        value={values.firstName}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.lastName")}
                        name="lastName"
                        disabled={mode === "view"}
                        error={touched.ticketNo && errors.ticketNo}
                        value={oldUser.lastName}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.lastName")}
                        name="lastName"
                        disabled={mode === "view"}
                        error={touched.ticketNo && errors.ticketNo}
                        value={values.lastName}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.mobile")}
                        name="mobileNumber"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={oldUser.mobileNumber}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.mobile")}
                        name="mobileNumber"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={values.mobileNumber}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("user.dob")}
                        name="dateofBirth"
                        disabled={mode === "view"}
                        error={touched.dateOfBirth && errors.dateOfBirth}
                        value={oldUser.dateofBirth}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("user.dob")}
                        name="dateofBirth"
                        disabled={mode === "view"}
                        error={touched.dateOfBirth && errors.dateOfBirth}
                        value={values.dateofBirth}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("gender")}
                        name="gender"
                        disabled={mode === "view"}
                        error={touched.gender && errors.gender}
                        value={oldUser.gender}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("gender")}
                        name="gender"
                        disabled={mode === "view"}
                        error={touched.gender && errors.gender}
                        value={values.gender}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.address")}
                        name="addressLine1"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={oldUser.addressLine1}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.address")}
                        name="addressLine1"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={values.addressLine1}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.city")}
                        name="city"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={oldUser.city}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.city")}
                        name="city"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={values.city}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.state")}
                        name="state"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={oldUser.state}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.state")}
                        name="state"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={values.state}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.country")}
                        name="country"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={oldUser.country}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.country")}
                        name="country"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={values.country}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.zipCode")}
                        name="zipCode"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={oldUser.zipCode}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("approval.zipCode")}
                        name="zipCode"
                        disabled={mode === "view"}
                        error={touched.description && errors.description}
                        value={values.zipCode}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    {
                      oldUser.profileimg && oldUser.profileimg ?
                        <FormGroup className="col-6 px-4 text-center">
                          <img className="col-5" alt="profile img" src={oldUser.profileimg} width={100} />
                        </FormGroup>
                        :
                        <FormGroup className="col-6 px-4 text-center mt-4">
                          No profile Img Found
                        </FormGroup>
                    }
                    {
                      data.profileimg && data.profileimg ?
                        <FormGroup className="col-6 px-4 text-center">
                          <img className="col-5" alt="profile img" src={data.profileimg} width={100} />
                        </FormGroup>
                        :
                        <FormGroup className="col-6 px-4 text-center mt-4">
                          No Image Found
                        </FormGroup>
                    }
                    <FormGroup className="col-12 px-5 text-center">
                      <FormikInput
                        label={t("approval.email")}
                        name="email"
                        disabled={mode === "view"}
                        error={touched.module && errors.module}
                        value={oldUser.email}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <Button
                      onClick={() => this.handleApproveRequest(oldUser.id)}
                      color="success"
                      className="my-4 mx-auto"
                    >
                      Accept
                    </Button>
                    <Button
                      onClick={() => this.handleConfirmRejectRequest(data.userId)}
                      color="danger"
                      className="my-4 mx-auto"
                    >
                      Reject
                    </Button>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
