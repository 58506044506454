import { Form, Formik } from "formik";
import React, { useState, useEffect, useRef } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikSelect, } from "../../../../components/FormikFields";
import { durationData, subjectData, subjectDataFr, durationDataFr, priceData, durationDataAds, durationDataFrAds } from "../../../../utils/Config";
import { adsSchema } from "../../../../utils/validationSchema";
import apiClient from "../../../../utils/apiClient";
import i18n from "../../../../i18n";

export default function AdUpdateForm({ data, onSubmit, mode, t, }) {
  const [module, setmodule] = useState('')
  const [categoryData, setcategoryData] = useState([])
  const [priceData, setpriceData] = useState([])
  const [subject, setsubject] = useState('')
  const [productId, setproductId] = useState('')
  const [priced, setpriced] = useState('')
  const [price, setprice] = useState('')
  const language = i18n.language
  useEffect(() => {
    getAdsPrice()
    getProductPrice()
  }, [module])

  useEffect(() => {
    getProductId()
  }, [priced])

  const getAdsPrice = () => {
    apiClient.get("category/get-categories", { params: { module: module } }).then(({ status, data }) => {
      if (status === 200) {
        setcategoryData(data.data)
      }
      else {
        setcategoryData([])
      }
    });
  };

  const getProductPrice = () => {
    apiClient.get("console-price/get-consolePrice", { params: { module: module } }).then(({ status, data }) => {
      if (status === 200) {
        setpriceData(data.data)
      }
      else {
        setpriceData([])
      }
    });
  };

  const getProductId = () => {
    apiClient.get("console-price/get-consoleProductId/" + priced).then(({ status, data }) => {
      if (status === 200) {
        if (data.data.length > 0) {
          setproductId(data.data[0].productId)
        }
      }
      else {
        setproductId("")
      }
    });
  };
  const touchedData = categoryData.map((item) => ({ label: item.name, value: item.name }));
  const pricedData = priceData.map((item) => ({ "label": item.price, "value": item._id }));
  // const prize = pricedData.find(p => p.label == data.price)
  let defaultSelected = pricedData.find(p => p.label == data.price)
  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik
          enableReinitialize
          initialValues={
            mode == "view" || "edit" ? {
              ...data,
              price: defaultSelected && defaultSelected.value
            } : data
          }
          validationSchema={adsSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              {
                values.subject && values.subject.length > 0 &&
                setmodule(values.subject)
              }
              {/* {
                console.log(pricedData.find(p => p.label == data.price))
              } */}
              {mode === 'add' ?
                <FormGroup className="col-6 px-4">
                  <FormikSelect
                    label={t("ct.module")}
                    name="subject"
                    disabled={mode === "view"}
                    subject
                    error={touched.subject && errors.subject}
                    value={values.subject}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={language === 'en' ? subjectData : subjectDataFr}
                  />
                </FormGroup>
                :
                <FormGroup className="col-6 px-4">
                  <FormikSelect
                    label={t("ct.module")}
                    name="subject"
                    disabled={mode === "view"}
                    error={touched.subject && errors.subject}
                    value={values.subject}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={language === 'en' ? subjectData : subjectDataFr}
                  />
                </FormGroup>
              }
              {
                mode === 'add' ?
                  <FormGroup className="col-6 px-4">
                    <FormikSelect
                      label={t("ad.category")}
                      name="category"
                      disabled={mode === "view"}
                      // subject
                      error={touched.category && errors.category}
                      value={values.category}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={touchedData}
                    />
                  </FormGroup>
                  :
                  <FormGroup className="col-6 px-4">
                    <FormikSelect
                      label={t("ad.category")}
                      name="category"
                      disabled={mode === "view"}
                      error={touched.category && errors.category}
                      value={values.category}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={touchedData}
                    />
                  </FormGroup>
              }
              {/* {
                values.price = pricedData.find(p => p.label == values.price)
              } */}
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("price.doller")}
                  name="price"
                  disabled={mode === "view"}
                  // price
                  error={touched.price && errors.price}
                  value={values.price ? values.price : priced}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={pricedData}
                />
              </FormGroup>
              {/* {console.log(pricedData.find(p => p.label == values.price).value)} */}
              {mode === 'add' ? values.price && values.price.length > 1 && setpriced(values.price) : setpriced(values.consoleId) || values.price && values.price.length > 8 && setpriced(values.price)}
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("price.id")}
                  name="productId"
                  disabled={mode === "view" || mode === "edit" || mode === "add"}
                  // error={errors.productId && !values.productId ? errors.productId : false}
                  value={values.productId = productId}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("ad.duration")}
                  name="duration"
                  disabled={mode === "view" || values.subject === 'Course' || values.productId.includes('verification')}
                  duration
                  error={touched.duration && errors.duration}
                  value={values.duration}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={language === 'en' ? values.subject === 'Advertisement' ? durationDataAds : durationData :
                    values.subject === 'Advertisement' ? durationDataFrAds : durationDataFr}
                />
              </FormGroup>
              {mode !== "view" && (
                <div className="col-12 d-flex my-4">
                  <Button type="submit" color="success" className="my-4 mx-auto">
                    {mode === "edit" ? t("price.update") : t("ct.add")}
                  </Button>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
