import React from "react";
import PaymentHistories from './PaymentHistory'
import { useTranslation } from "react-i18next";

export default function PaymentHistory({ heading }) {
    const { t } = useTranslation();

    return (
        <div className="subscription-management">
            <span className="page-header">{t("pay.title")}</span>
            <PaymentHistories type="terminals" label={t("pay.title")} t={t} />
        </div>
    );
}
