import { USER_DATA, LOADING, COURSES, PROGRESS } from "../actions";
import { setLocalStorage } from "../../utils/Utils";
import { store } from "../store";

export const setUserData = async (payload = "", token = "") => {
  setLocalStorage("userData", payload);
  setLocalStorage("token", token);
  store.dispatch({ type: USER_DATA, payload });
};

export const loading = (payload) => {
  store.dispatch({ type: LOADING, payload });
};

export const courses = (payload) => {
  store.dispatch({ type: COURSES, payload });
};

export const progress = (payload) => {
  store.dispatch({ type: PROGRESS, payload });
};
