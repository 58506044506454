import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Error404 from "../components/Error404";
import Login from "./Login";
import Faq from './Faq'
import '../i18n'
import AppUserLogin from "./appUserLogin";
import Home from "./Home";

export default function App() {
  return (
    <Switch>
      <Route exact path="/login" render={(e) => <Login {...e} />} />
      <Route exact path="/login/appUser" render={(e) => <AppUserLogin {...e} />} />
      <Route exact path="/home" render={(e) => <Home {...e} />} />
      <Route exact path="/admin/login" render={(e) => <Login {...e} label="Admin" />} />
      <Route exact path="/faq" render={(e) => <Faq {...e} label="FAQ" />} />
      {/* <Route exact path="/error" component={Error404} /> */}
      <Redirect from="/app" to="/login" />
      <Redirect from="/admin" to="/admin/login" />
      <Redirect exact from="/" to="/login" />
      <Redirect to="/error" />
    </Switch>
  );
}
