import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader, FormGroup } from "reactstrap";
import Alert from "../../../../components/Alert";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import { Form, Formik } from "formik";
import { FormikInput } from "../../../../components/FormikFields";
import FileUpload from "../../../../components/FileUpload";
import { lessonSchema } from "../../../../utils/validationSchema";
export default class LessonList extends Component {
  constructor() {
    super();
    this.state = {
      courseId: "",
      lessonName: "",
      lessonNameInFr: "",
      description: "",
      descriptionInFr: "",
      lessonDocument: "",
      lessonDocumentInFr: '',
      selectedRowId: "",
      mode: "",
      data: [],
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
    };
  }

  componentDidMount() {
    if (this.props.courseId) {
      this.setState({ courseId: this.props.courseId });
    }
    this.getData();
  }

  handleDelete = (id) => {
    apiClient
      .delete(`course/delete-lesson/${id}/${this.state.courseId}`)
      .then(({ status, data }) => {
        if (status === 200) {
          toast.success(data.message);
          this.getData();
        }
      });
  };

  getData = () => {
    const { page, size } = this.state;
    apiClient
      .get(`course/getCoursebyId/${this.props.courseId}`, {
        params: { page, size },
      })
      .then(({ status, data: { page, size, data, totalCount } }) => {
        size = size < 10 ? 10 : size;
        if (status === 200) {
          this.setState({
            page,
            size,
            data: data.lessons,
            totalCount,
            total_page: Math.ceil(totalCount / size),
          });
        }
      });
  };

  onFileChange = (event) => {
    this.setState({ quizzesDocument: event.target.files[0] });
  };

  toggleModal = (mode = "", rowData) => {
    if (rowData) {
      const { lessonName, lessonNameInFr, description, descriptionInFr, lessonDocument, lessonDocumentInFr, _id } =
        rowData;
      this.setState({
        selectedRowId: _id,
        lessonName,
        lessonNameInFr,
        description,
        descriptionInFr,
        lessonDocument,
        lessonDocumentInFr
      });
    }
    this.setState({
      mode,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = ({
    lessonName,
    lessonNameInFr,
    description,
    descriptionInFr,
    lessonDocument,
    lessonDocumentInFr
  }) => {
    if (this.state.mode === "edit") {
      // console.log(lessonName,
      //   lessonNameInFr,
      //   description,
      //   descriptionInFr,
      //   lessonDocument,
      //   lessonDocumentInFr);
      var form_data = new FormData();
      form_data.append("lessonName", lessonName);
      form_data.append("lessonNameInFr", lessonNameInFr);
      form_data.append("description", description);
      form_data.append("descriptionInFr", descriptionInFr);
      form_data.append("courseId", this.state.courseId);
      if (lessonDocument) {
        form_data.append("lessonDocument", lessonDocument[0]);
      }
      if (lessonDocumentInFr) {
        form_data.append("lessonDocumentInFr", lessonDocumentInFr[0]);
      }
      // console.log(typeof lessonDocument);
      // console.log(typeof lessonDocumentInFr);
      // console.log(form_data);
      apiClient
        .put(
          `course/update-lesson/${this.state.selectedRowId}/${this.state.courseId}`,
          form_data,
          {
            onUploadProgress: (progressEvent) => {
              console.log(
                "upload Progress" +
                Math.round(
                  (progressEvent.loaded / progressEvent.total) * 100
                ) +
                "%"
              );
            },
          }
        )
        .then(({ status }) => {
          if (status === 200) {
            this.setState({
              lessions: [],
            });
            this.getData();
            toast.success("Lesson Updated Successfully");
          }
          this.toggleModal();
        });
    } else {
      var form_data = new FormData();
      form_data.append("lessonName", lessonName);
      form_data.append("lessonNameInFr", lessonNameInFr);
      form_data.append("description", description);
      form_data.append("descriptionInFr", descriptionInFr);
      form_data.append("courseId", this.state.courseId);
      if (lessonDocument) {
        form_data.append("lessonDocument", lessonDocument[0]);
      }
      if (lessonDocumentInFr) {
        form_data.append("lessonDocumentInFr", lessonDocumentInFr[0]);
      }
      apiClient.post("course/create-lesson", form_data).then(({ status }) => {
        if (status === 200) {
          this.setState({
            lessions: [],
          });
          this.getData();
          toast.success("Lesson created successfully");
        }
        this.toggleModal();
      });
    }
  };

  formUI(t) {
    const { lessonName, lessonNameInFr, description, descriptionInFr, lessonDocument, lessonDocumentInFr } =
      this.state;
    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            enableReinitialize
            initialValues={{
              lessonName,
              lessonNameInFr,
              description,
              descriptionInFr,
              lessonDocument,
              lessonDocumentInFr,
            }}
            onSubmit={this.handleSubmit}
            validationSchema={lessonSchema}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("lesson.name")}
                    name="lessonName"
                    disabled={this.state.mode === "view"}
                    value={values.lessonName}
                    error={touched.lessonName && errors.lessonName}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("lesson.name")}
                    name="lessonNameInFr"
                    disabled={this.state.mode === "view"}
                    value={values.lessonNameInFr}
                    error={touched.lessonNameInFr && errors.lessonNameInFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("course.Description")}
                    name="description"
                    multiline={true}
                    disabled={this.state.mode === "view"}
                    value={values.description}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("course.Description")}
                    name="descriptionInFr"
                    multiline={true}
                    disabled={this.state.mode === "view"}
                    value={values.descriptionInFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                {this.state.mode !== "add" ? (
                  <FormGroup className="col-12 px-4">
                    {t("course.documentUrl")}
                    <br />{" "}
                    <a href={lessonDocument}>
                      {lessonDocument ? (
                        lessonDocument
                      ) : (
                        <b>{t("course.document")}</b>
                      )}
                    </a>
                  </FormGroup>
                ) : (
                  ""
                )}
                {this.state.mode !== "add" ? (
                  <FormGroup className="col-12 px-4">
                    {t("course.documentUrlF")}
                    <br />{" "}
                    <a href={lessonDocumentInFr}>
                      {lessonDocumentInFr ? (
                        lessonDocumentInFr
                      ) : (
                        <b>{t("course.document")}</b>
                      )}
                    </a>
                  </FormGroup>
                ) : (
                  ""
                )}
                {this.state.mode !== "view" && (
                  <>
                    <FormGroup className="col-6 px-5 my-4 text-center">
                      <FileUpload
                        name="lessonDocument"
                        // type="image/*"
                        profile
                        label={t("course.uploadNew")}
                        disabled={this.state.mode === "view"}
                        value={
                          typeof values.lessonDocument != "string"
                            ? values.lessonDocument
                            : []
                        }
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-5 my-4 text-center">
                      <FileUpload
                        name="lessonDocumentInFr"
                        // type="image/*"
                        profile
                        label={t("course.uploadNew")}
                        disabled={this.state.mode === "view"}
                        value={
                          typeof values.lessonDocumentInFr != "string"
                            ? values.lessonDocumentInFr
                            : []
                        }
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <div className="col-12 d-flex my-4">
                      <Button
                        type="submit"
                        color="success"
                        className="my-4 mx-auto"
                      >
                        {this.state.mode === "edit"
                          ? t("course.update")
                          : t("lesson.add")}
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };

  render() {
    const { mode, data, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;
    const selectedLanguage = localStorage.getItem('selectedLanguage')
    return (
      <>
        <Modal
          className="course-modal"
          isOpen={Boolean(mode)}
          toggle={() => this.toggleModal()}
        >
          <ModalHeader toggle={() => this.toggleModal()}>
            {mode
              ? mode === "add"
                ? "Add New " + label
                : mode === "edit"
                  ? "Edit " + label
                  : "View " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">{mode && <>{this.formUI(t)}</>}</div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          subHeaderComponent={
            <Button
              className="ml-auto"
              color="success"
              onClick={() => this.toggleModal("add")}
            >
              + {t("lesson.add")}
            </Button>
          }
          column={[
            { title: t("lesson.name"), field: selectedLanguage == 'fe' ? "lessonNameInFr" : "lessonName" },
            { title: t("course.Description"), field: selectedLanguage == 'fe' ? "descriptionInFr" : "description" },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) =>
                this.toggleModal("view", rowData) && this.formUI(t),
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData),
            },
            {
              icon: "fi flaticon-delete-1",
              className: "btn-white text-danger p-0",
              onClick: (rowData) =>
                Alert.info(
                  "Confirm Deletion!",
                  `Are you sure want to delete the ${label}`,
                  () => this.handleDelete(rowData._id)
                ),
            },
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: true,
            isCollapsable: true,
            expandActions: true,
            pagination: {
              showPagination: true,
              pageSelection: true,
              total_page,
              page,
              size,
              totalCount,
            },
          }}
          key={data && data._id}
        />
      </>
    );
  }
}
