import { TextField } from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput } from "../../../../components/FormikFields";


export default function UnBlockUserForm({ data, onSubmit, mode , t}) {

  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik
          enableReinitialize
          initialValues={data}
          onSubmit={onSubmit}>
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.companyName")}
                  name="companyName"
                  disabled
                  // error={touched.firstName && errors.firstName}
                  value={values.companyName}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                 label={t("approval.email")}
                  name="email"
                  disabled
                  // error={touched.lastName && errors.lastName}
                  value={values.email}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                 label={t("approval.address")}
                  name="addressLine1"
                  disabled
                  // error={touched.lastName && errors.lastName}
                  value={values.addressLine1}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.city")}
                  name="city"
                  disabled
                  // error={touched.lastName && errors.lastName}
                  value={values.city}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                 label={t("approval.zipCode")}
                  name="zipCode"
                  disabled
                  // error={touched.lastName && errors.lastName}
                  value={values.zipCode}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                label={t("approval.state")}
                  name="state"
                  disabled
                  // error={touched.lastName && errors.lastName}
                  value={values.state}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4 reasonText">
                <TextField
                label={t("company.reason")}
                  name="comment"
                  // className="reasonText"
                  disabled
                  error={touched.lastName && errors.lastName}
                  value={values.comment}
                  style={{
                    // backgroundColor: "blue"
                  }}
                  InputProps={{
                    style: {
                      color: "red"
                    }
                  }}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              {mode !== 'view' &&
                <div className="col-12 d-flex my-4">
                  <Button type="submit" color="success" className="m-auto">
                  {t("company.unBlock")}
                </Button>
                </div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
