// import React, { Component } from "react";
import SideBar from "../components/SideBar";
import UserBar from "../navs/UserBar";
import $ from "jquery";
import { history } from "../utils/Utils";
import { useTranslation } from "react-i18next";



import React, { useState } from 'react'

function AppLayout({ children }) {
  const [show, setshow] = useState(false)
  const { t } = useTranslation();
  const toggle = () => {
    setshow(!show)
  };

  history.listen(() => {
    if ($("#app-view")[0]) $("#app-view")[0].scrollIntoView();
  });

  return (
    <div className="row app-layout">
      <SideBar show={show} toggle={toggle} t={t} />
      <div className="app-content">
        <UserBar t={t} />
        <div className="content">
          <span id="app-view" />
          <div className="content-body">{children}</div>
          <div className="footer">Copyright © 2021 GBT. All Rights Reserved.</div>
        </div>
      </div>
    </div>
  )
}

export default AppLayout

// class AppLayout extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       show: false,
//     };
//   }

//   toggle = () => {
//     this.setState({ show: !this.state.show });
//   };


//   render() {
//     const { show } = this.state;
//     const { children } = this.props;
//     const { t } = this.props;

//     history.listen(() => {
//       if ($("#app-view")[0]) $("#app-view")[0].scrollIntoView();
//     });
//     return (
//       <div className="row app-layout">
//         <SideBar show={show} toggle={this.toggle} />
//         <div className="app-content">
//           <UserBar />
//           <div className="content">
//             <span id="app-view" />
//             <div className="content-body">{children}</div>
//             <div className="footer">Copyright © 2021 GBT. All Rights Reserved.</div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default useTranslation()(AppLayout)
