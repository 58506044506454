import { Form, Formik } from "formik";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { Button, FormGroup } from "reactstrap";
import { FormikInput } from "../../../../components/FormikFields";
import apiClient from "../../../../utils/apiClient";
import Alert from "../../../../components/Alert";

export default function ApprovalForm({ data, t, onSubmit, userData }) {
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const [rejectionComment, setRejectionComment] = useState('');
  const oldUser = userData[0]
  const handleApproveRequest = (id) => {
    // const { t, onSubmit } = this.props;
    var bodyData = {
      isActive: true,
      status: "approved",
    };
    apiClient
      .put("admin-company/approve-user/" + id, bodyData)
      .then(({ status, data }) => {
        if (status === 200) {
          toast.success("Approved Successfully");
          onSubmit();
        } else {
          toast.error(data);
        }
      });
  };

  const handleConfirmRejectRequest = (id) => {
    // const { t } = this.props;
    Alert.info(t("ad.rejectConform"), t("ad.company"), () => {
      setIsCommentModalOpen(true);
    });
  };

  const handleRejectRequest = (id) => {
    // const { onSubmit } = this.props;
    const bodyData = {
      userComments: rejectionComment,
      status: "rejected",
    };
    apiClient
      .put("admin-company/approve-user/" + id, bodyData)
      .then(({ status, data }) => {
        if (status === 200) {
          onSubmit();
          toast.success("Rejected Successfully");
        }
      });
  };
  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik enableReinitialize initialValues={data}>
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              {isCommentModalOpen ? (
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("approval.comment")}
                    name="userComments"
                    value={rejectionComment}
                    onChange={(e, value) => setRejectionComment(value)}
                  />
                  <Button
                    onClick={() => handleRejectRequest(data.userId)}
                    color="success"
                    className="my-4 mx-auto"
                  >
                    Reject
                  </Button>
                </FormGroup>
              ) : (
                <>
                  <FormGroup className="col-6 px-4 text-center">{t("old_details")}</FormGroup>
                  <FormGroup className="col-6 px-4 text-center">{t("new_details")}</FormGroup>
                  <FormGroup className="col-6 px-4 text-center">
                    <FormikInput
                      label={t("approval.companyName")}
                      name="companyName"
                      disabled
                      value={oldUser.companyName}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4 text-center">
                    <FormikInput
                      label={t("approval.companyName")}
                      name="companyName"
                      disabled
                      value={values.companyName}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.address")}
                      name="addressLine1"
                      disabled
                      error={touched.email && errors.email}
                      value={oldUser.addressLine1}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.address")}
                      name="addressLine1"
                      disabled
                      error={touched.email && errors.email}
                      value={values.addressLine1}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.city")}
                      name="city"
                      disabled
                      error={touched.email && errors.email}
                      value={oldUser.city}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.city")}
                      name="city"
                      disabled
                      error={touched.email && errors.email}
                      value={values.city}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.state")}
                      name="state"
                      disabled
                      error={touched.password && errors.password}
                      value={oldUser.state}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.state")}
                      name="state"
                      disabled
                      error={touched.password && errors.password}
                      value={values.state}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.zipCode")}
                      name="zipCode"
                      disabled
                      error={touched.email && errors.email}
                      value={oldUser.zipCode}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("approval.zipCode")}
                      name="zipCode"
                      disabled
                      error={touched.email && errors.email}
                      value={values.zipCode}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("student.country")}
                      name="country"
                      disabled
                      error={touched.email && errors.email}
                      value={oldUser.country}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-4">
                    <FormikInput
                      label={t("student.country")}
                      name="country"
                      disabled
                      error={touched.email && errors.email}
                      value={values.country}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  {/* <FormikInput
                        label={t("student.country")}
                        name="profileimg"
                        disabled
                        error={touched.profileimg && errors.profileimg}
                        value={values.profileimg}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup> */}
                  {
                    oldUser.profileimg && oldUser.profileimg ?
                      <FormGroup className="col-6 px-4 text-center">
                        <img className="col-5" alt="profile img" src={oldUser.profileimg} width={100} />
                      </FormGroup>
                      :
                      <FormGroup className="col-6 px-4 text-center mt-4">
                        No profile Img Found
                      </FormGroup>
                  }
                  {
                    data.profileimg && data.profileimg ?
                      <FormGroup className="col-6 px-4 text-center">
                        <img className="col-5" alt="profile img" src={data.profileimg} width={100} />
                      </FormGroup>
                      :
                      <FormGroup className="col-6 px-4 text-center mt-4">
                        No Image Found
                      </FormGroup>
                  }
                  <FormGroup className="col-12 px-5">
                    <FormikInput
                      label={t("approval.email")}
                      name="email"
                      disabled
                      value={values.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <Button
                    onClick={() => handleApproveRequest(data.userId)}
                    color="success"
                    className="my-4 mx-auto"
                  >
                    Accept
                  </Button>
                  <Button
                    onClick={() => handleConfirmRejectRequest(data.userId)}
                    color="danger"
                    className="my-4 mx-auto"
                  >
                    Reject
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
