import React, { useState } from "react";
import Ad from './ReviewedAds/Ad'
import NewAds from './NewAds/NewAds'
import { useTranslation } from "react-i18next";
import TabNav from '../../../../components/TabNav'
import Tab from '../../../../components/Tab'

export default function Advertisement({ heading }) {
  const { t } = useTranslation();
  const [selected, setselected] = useState(t("ad.new"))

  /* select the job */
  const setSelected = (tab) => {
    setselected(tab)
  }

  return (
    <div className="subscription-management">
      <span className="page-header">{t("ad.title")}</span>
      <TabNav tabs={[t("ad.new"), t("ad.reviewed")]} selected={selected} setSelected={setSelected}>
        <Tab isSelected={selected === t("ad.new")}>
          <NewAds type="terminals" label={t("ad.new")} t={t} />
        </Tab>
        <Tab isSelected={selected === t("ad.reviewed")}>
          <Ad type="terminals" label={t("ad.reviewed")} t={t} />
        </Tab>
      </TabNav>
    </div>
  );
}
