import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup } from "reactstrap";
import Alert from "../../components/Alert";
import { FormikInput } from "../../components/FormikFields";
import apiClient from "../../utils/apiClient";
import { history } from "../../utils/Utils";
import { changePassSchema } from "../../utils/validationSchema";

class ChangePassword extends Component {
  handleSubmit = ({ old_password, password }) => {
    const { userData } = this.props;
    var bodyData = {
      oldPassword: old_password,
      password: password
    };
    console.log(bodyData)
    apiClient.put("users/change-password/" + userData.id, bodyData).then(({ status }) => {
      if (status === 200) {
        Alert.success("Password Changed", "Your password has been updated successfully.");
        history.push("/app");
      }
    });
  };

  handleBack = async () => {
    history.push("/app");
  };
  render() {
    const { t } = this.props
    return (
      <div className="loginContainer">
        <div className="login-content">
          <div>
            <Formik
              initialValues={{
                old_password: "",
                password: "",
                confirm_password: ""
              }}
              validationSchema={changePassSchema}
              onSubmit={this.handleSubmit}>
              {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                <Form className="mt-4">
                  <FormGroup className="mb-4">
                    <FormikInput
                      label={t("password.old")}
                      name="old_password"
                      type="password"
                      icon="flaticon-padlock"
                      error={touched.old_password && errors.old_password}
                      value={values.old_password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormikInput
                      label={t("password.new")}
                      name="password"
                      type="password"
                      icon="flaticon-padlock"
                      error={touched.password && errors.password}
                      value={values.password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormikInput
                      label={t("password.confirm")}
                      name="confirm_password"
                      type="password"
                      icon="flaticon-padlock"
                      error={touched.confirm_password && errors.confirm_password}
                      value={values.confirm_password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <Button className="login-button submit-btn w-100 mt-4" type="submit">
                    {t("password.button")}
                  </Button>
                </Form>
              )}
            </Formik>
            <Button type="button" color="white mt-5" onClick={() => this.handleBack()}>
              <i className="fi flaticon-left-arrow" />
              {t("password.back")}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(({ user: { userData } }) => ({ userData }))(ChangePassword);
