import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikSelect, FormikRating } from "../../../../../components/FormikFields";
import FileUpload from '../../../../../components/FileUpload'
import { FaStar, FaStarHalfAlt } from 'react-icons/fa'

export default class AdForm extends Component {
  constructor() {
    super();
    this.state = {
      adVideo: [],
      adVideoInFr: [],
      imageAd: []
    };
  }


  render() {
    const { data, onSubmit, mode, t, categorData } = this.props;
    const { adVideo, adVideoInFr, imageAd } = this.state
    return (
      <div className="add-counter">
        <div className="fields col-11 mx-auto">
          <Formik
            // validationSchema={addCounterSchema}
            enableReinitialize
            initialValues={{ ...data, adVideo, adVideoInFr, imageAd }}
            onSubmit={onSubmit}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("title")}
                    name="title"
                    disabled={mode === "view"}
                    value={values.title}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                {mode === 'reject' ?
                  <div className="col-12">
                    <FormGroup className="col-12 px-4">
                      <FormikInput
                        label={t("approval.comment")}
                        name="adminComment"
                        default="1"
                        value={values.adminComment}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                  </div>
                  :
                  <React.Fragment>
                    <FormGroup className="col-6 px-4">
                      <FormikSelect
                        label={t("ad.category")}
                        name="adCategory"
                        error={errors.adCategory && !values.adCategory ? errors.adCategory : false}
                        value={values.adCategory}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        options={categorData}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("ad.duration")}
                        name="duration"
                        disabled
                        value={values.duration}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("ad.postedBy")}
                        name="postedBy"
                        disabled
                        value={values.postedBy}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("course.price")}
                        name="price"
                        disabled
                        value={values.price}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("ad.website")}
                        name="website"
                        disabled={mode === "view"}
                        value={values.website}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4 mt-1">
                      <FormikInput
                        label={t("ad.description")}
                        name="description"
                        // type="email"
                        multiline={true}
                        error={touched.email && errors.email}
                        value={values.description}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4 mt-3">
                      <FormikInput
                        label={t("ad-link")}
                        name="adLink"
                        // type="email"
                        error={touched.adLink && errors.adLink}
                        value={values.adLink}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("student.mobile")}
                        name="phone"
                        // type="email"
                        error={touched.phone && errors.phone}
                        value={values.phone}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-4">
                      <FormikInput
                        label={t("ad.email")}
                        name="referalEmail"
                        error={touched.referalEmail && errors.referalEmail}
                        value={values.referalEmail}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                    <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                    <FormGroup className="col-6 ">
                      <FileUpload
                        name="adVideo"
                        type="video/*"
                        profile
                        label={t("quiz.uploadVideo")}
                        disabled={mode === "view"}
                        error={errors.adVideo && !values.adVideo ? errors.adVideo : false}
                        value={values.adVideo}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-6 ">
                      <FileUpload
                        name="adVideoInFr"
                        type="video/*"
                        profile
                        label={t("quiz.uploadVideo")}
                        disabled={mode === "view"}
                        error={touched.adVideoInFr && errors.adVideoInFr}
                        value={values.adVideoInFr}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    <FormGroup className="col-12 my-4 text-center">
                      <FileUpload
                        name="imageAd"
                        type="image/*"
                        profile
                        label={t("img.update")}
                        disabled={mode === "view"}
                        error={touched.imageAd && errors.imageAd}
                        value={values.imageAd}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                      />
                    </FormGroup>
                    {data.imageAd &&
                      <FormGroup className="col-12 my-2">
                        {t("ad.imageurl")}
                        <br />{" "}
                        <a href={data.imageAd}>
                          {data.imageAd ? (
                            data.imageAd
                          ) : (
                            <b>{t("course.document")}</b>
                          )}
                        </a>
                      </FormGroup>
                    }
                    {data.adVideo &&
                      <FormGroup className="col-12 my-2">
                        {t("announcement.videoUrl")}
                        <br />{" "}
                        <a href={data.adVideo}>
                          {data.adVideo ? (
                            data.adVideo
                          ) : (
                            <b>{t("course.document")}</b>
                          )}
                        </a>
                      </FormGroup>
                    }
                    {data.adVideo &&
                      <FormGroup className="col-12 my-2">
                        {t("announcement.videoUrlinFrench")}
                        <br />{" "}
                        <a href={data.adVideoInFr}>
                          {data.adVideoInFr ? (
                            data.adVideoInFr
                          ) : (
                            <b>{t("course.document")}</b>
                          )}
                        </a>
                      </FormGroup>
                    }
                  </React.Fragment>
                }
                {
                  mode === 'view' &&
                  <>
                    <label className="px-4">{t("job.rating")}</label>
                    <FormikRating
                      name="rating"
                      emptyIcon={<FaStar size={30} />}
                      halfIcon={<FaStarHalfAlt size={40} />}
                      filledIcon={<FaStar size={30} />}
                      value={values.rating}
                      size={70}
                      count={5}
                      color={"#E0E0E0"}
                      activeColor={"#005288"}
                      a11y={true}
                      isHalf={true}
                      onChange={(e) => values.rating = e}
                      error={errors.rating}
                    />
                    <FormGroup className="col-12 px-4">
                      <FormikInput
                        label={t("job.review")}
                        name="ratingComments"
                        value={values.ratingComments}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.ratingComments}
                      />
                    </FormGroup>
                  </>
                }
                <div className="col-12 text-center">
                  {mode !== "view" && (
                    <div className="d-flex text-center">
                      <Button
                        type="submit"
                        color={mode == 'block' && data.blockingStatus == 'active' ? "danger" : 'success'}
                        className="my-4 mx-auto"
                      >
                        {mode == 'block' ?
                          data.blockingStatus == 'active' ? t("company.block") : t("company.unBlock") : t("price.update")}
                      </Button>
                    </div>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
