import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, FormGroup } from "reactstrap";
import Alert from "../../components/Alert";
import { FormikInput } from "../../components/FormikFields";
import apiClient from "../../utils/apiClient";
import { history } from "../../utils/Utils";
import { updateVersionSchema } from "../../utils/validationSchema";

class UpdateVersion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      androidVersion: '',
      iosVersion: '',
      editRow: ''
    }
  }


  handleSubmit = ({ androidVersion, iosVersion }) => {
    const { editRow } = this.state;
    var bodyData = {
      androidVersion: androidVersion,
      iosVersion: iosVersion
    };
    console.log(bodyData)
    apiClient.put("users/update-version/" + editRow, bodyData).then(({ status }) => {
      if (status === 200) {
        Alert.success("Version Updated", "App Version has been updated successfully..!");
        history.push("/app");
      }
    });
  };

  componentDidMount() {
    this.getData()
  }

  /* get current version */
  getData = async () => {
    await apiClient.get('/users/version').then(({ status, data: { data } }) => {
      if (status === 200) {
        this.setState({
          androidVersion: data['androidVersion'],
          iosVersion: data['iosVersion'],
          editRow: data['_id']
        })
      }
    });
  };

  handleBack = async () => {
    history.push("/app");
  };
  render() {
    const { t } = this.props
    const { androidVersion, iosVersion } = this.state
    return (
      <div className="loginContainer">
        <div className="login-content">
          <div>
            <Formik
              enableReinitialize
              initialValues={{
                "androidVersion": androidVersion,
                "iosVersion": iosVersion
              }}
              validationSchema={updateVersionSchema}
              onSubmit={this.handleSubmit}>
              {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                <Form className="mt-4">
                  <FormGroup className="mb-4">
                    <FormikInput
                      label={t("version.android")}
                      name="androidVersion"
                      icon="flaticon-padlock"
                      error={touched.androidVersion && errors.androidVersion}
                      value={values.androidVersion}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormikInput
                      label={t("version.ios")}
                      name="iosVersion"
                      icon="flaticon-padlock"
                      error={touched.iosVersion && errors.iosVersion}
                      value={values.iosVersion}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <Button className="login-button submit-btn w-100 mt-4" type="submit">
                    {t("version.update")}
                  </Button>
                </Form>
              )}
            </Formik>
            <Button type="button" color="white mt-5" onClick={() => this.handleBack()}>
              <i className="fi flaticon-left-arrow" />
              {t("password.back")}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(({ user: { userData } }) => ({ userData }))(UpdateVersion);
