import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Alert from "../../../../components/Alert";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import UserForm from "./UserForm";

export default class UserList extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      show_date: moment(),
      userData: [],
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1
    };
  }

  componentDidMount() {
    this.getData();

  }

  getData = () => {
    const { page, size } = this.state;
    apiClient.get("students/get-student", { params: { page, size } }).then(({ status, data: { page, size, data, totalCount } }) => {

      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data: data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      }
      this.getuserData(data.length > 0 ? data[0].userId : null);
    });
  };

  getuserData = (id) => {
    apiClient.get("users/getUser/" + id,).then(({ status, data: { data, } }) => {
      if (status === 200) {
        this.setState({
          userData: data,
        });
      }
    });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleSubmit = (data) => {
    this.toggleModal();
    this.getData()
  };

  handleChange = (show_date) => {
    this.setState({ show_date });
  };

  handleDelete = (id) => {
    apiClient.delete("complaints/delete/" + id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  render() {
    const { mode, edit_row, data, total_page, size, page, userData } = this.state;
    const { label = "", t } = this.props;
    const userDatas = [];
    userDatas.push(userData && userData)
    return (
      <>
        <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              {mode && (
                <UserForm
                  data={edit_row}
                  userData={userDatas}
                  mode={mode}
                  title="Add new Complaint"
                  t={t}
                  getData={this.getData}
                  onSubmit={this.handleSubmit}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          column={[
            // { title: t("student.firstName"), field: "firstName" },
            { title: t("approval.email"), field: "email" },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              // tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("view", rowData)
            },
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: false,
            isCollapsable: true,
            expandActions: true,
            pagination: { showPagination: true, pageSelection: true, total_page, page, size }

          }}
        />
      </>
    );
  }
}
