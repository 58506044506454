// const t = useTranslation();
export const baseURL =
  /* local */
  // window.location.protocol +
  // "//" +
  // "http://0.0.0.0:3000/api/v1";
  // "http://localhost:3000/api/v1";

  /*   dev_spiderweb  */
  // 'http://3.97.202.8:3000/api/v1';
  // 'http://3.98.28.196:3000/api/v1';
  // vasanth@knovatek.co
  // vasanth@123

  /* production */
  'https://api.groupe-btu.com/api/v1';
  // vasanth@knovatek.co
  // blasongblasong22

export const pageSizes = [5, 10, 20, 30, 50, 100];

export const statusData = [
  { label: "Approve", value: true },
  { label: "InApprove", value: false },
];

export const countsData = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "10", value: 10 },
];

export const booleanData = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const languageData = [
  { label: "English", value: "english" },
  { label: "French", value: "french" },
];

// export const customData = [
//   { label: "S", value: "sunday" },
//   { label: "M", value: "monday" },
//   { label: "T", value: "tuesday" },
//   { label: "W", value: "wednesday" },
//   { label: "T", value: "thursday" },
//   { label: "F", value: "friday" },
//   { label: "S", value: "saturday" }
// ];

export const ticketStatusData = [
  { label: "Inprogress", value: "inprogress" },
  { label: "Completed", value: "completed" },
  { label: "Hold", value: "hold" },
  { label: "Rejected", value: "rejected" },
  { label: "Pending", value: "pending" },
];

export const durationData = [
  { label: "1 Day", value: "oneDay" },
  { label: "Week", value: "week" },
  { label: "1 Month", value: "1month" },
  { label: "6 Month", value: "6month" },
  { label: "1 Year", value: "year" },
];

export const durationDataAds = [
  { label: "1 Day", value: "oneDay" },
  { label: "Week", value: "week" },
  { label: "14 Days", value: "14days" },
  { label: "1 Month", value: "1month" },
  { label: "6 Month", value: "6month" },
  { label: "1 Year", value: "year" },
];

export const priceData = [
  { label: "$1", value: "1" },
  { label: "$2", value: "2" },
  { label: "$3", value: "3" },
  { label: "$4", value: "4" },
  { label: "$5", value: "5" },
];

export const durationDataFr = [
  { label: "un jour", value: "oneDay" },
  { label: "Semaine", value: "week" },
  { label: "1 mois", value: "1month" },
  { label: "6 mois", value: "6month" },
  { label: "1 année", value: "year" },
];

export const durationDataFrAds = [
  { label: "un jour", value: "oneDay" },
  { label: "Semaine", value: "week" },
  { label: "14 jours", value: "14days" },
  { label: "1 mois", value: "1month" },
  { label: "6 mois", value: "6month" },
  { label: "1 année", value: "year" },
];

export const subjectData = [
  { label: "Advertisement", value: "Advertisement" },
  { label: "Project", value: "Project" },
  { label: "Course", value: "Course" },
  { label: "Job", value: "Job" },
  // { label: "Announcement", value: "announcement" },
];

export const subjectDataFr = [
  { label: "Publicité", value: "Advertisement" },
  { label: "Projet", value: "Project" },
  { label: "Cours", value: "Course" },
  { label: "Emploi", value: "Job" },
];

export const categoryData = [
  { label: "Advertisement", value: "Advertisement" },
  { label: "Project", value: "Project" },
  { label: "All", value: "All" },
  { label: "Course", value: "Course" },
  { label: "Announcement", value: "Announcement" },
  { label: "Job", value: "Job" },
];

export const categoryDataFr = [
  { label: "Publicité", value: "Advertisement" },
  { label: "Projet", value: "Project" },
  { label: "Toute", value: "All" },
  { label: "Cours", value: "Course" },
  { label: "Annonce", value: "Announcement" },
];

export const categoryList = [
  { label: "Financial", value: "Financial" },
  { label: "Real estate", value: "Real estate" },
  { label: "Professional", value: "Professional" },
  { label: "Administrative services", value: "Administrative services" },
  { label: "Education", value: "Education" },
  { label: "Health", value: "Health" },
  { label: "Social assistance", value: "Social assistance" },
  { label: "Manufacturing", value: "Manufacturing" },
  { label: "Processing", value: "Processing" },
  { label: "Trade", value: "Trade" },
  { label: "Culture", value: "Culture" },
  { label: "Information", value: "Information" },
  { label: "Leisure and accommodation", value: "Leisure and accommodation" },
  { label: "Public administrations", value: "Public administrations" },
  { label: "Construction", value: "Construction" },
  {
    label: "Agriculture and related activities",
    value: "Agriculture and related activities",
  },
  { label: "Technologies", value: "Technologies" },
  { label: "Others", value: "Others" },
];

export const valueOfProjectData = [
  { label: "USD 1 to USD 100", value: "100" },
  { label: "USD 100 to USD 200", value: "200" },
  { label: "USD 200 to USD 300", value: "300" },
  { label: "USD 300 to USD 400", value: "400" },
  { label: "USD 400 to USD 500", value: "500" },
  { label: "USD 500 to USD 1000", value: "1000" },
];

export const ratingData = [
  { label: "1", value: "1" },
  { label: "1.5", value: "1.5" },
  { label: "2", value: "2" },
  { label: "2.5", value: "2.5" },
  { label: "3", value: "3" },
  { label: "3.5", value: "3.5" },
  { label: "4", value: "4" },
  { label: "4.5", value: "4.5" },
  { label: "5", value: "5" },
];

export const areaOptions = [
  { label: "CANADA", value: "CANADA" },
  { label: "AFRICA", value: "AFRICA" },
  { label: "EUROPE", value: "EUROPE" },
  { label: "USA", value: "USA" },
];

export const typeOptions = [
  { label: "CLIENTS", value: "CLIENTS" },
  { label: "PROSPECT", value: "PROSPECT" },
];
