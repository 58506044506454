import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { history } from "./utils/Utils";
import App from "./views";
import AppDashboard from "./views/Dashboard/AppDashboard";
import InternetLost from "./views/InternetLost";
import 'react-circular-progressbar/dist/styles.css';

class MainApp extends Component {
  constructor(props) {
    super(props)

    this.state = {
      internetFinder: window.navigator.onLine
    };
  };
  render() {
    const { isLoading, userData, progress } = this.props;
    return (
      <>
        {
          this.state.internetFinder === true ?
            <Router history={history}>
              {
                progress === true ?
                  null :
                  isLoading && <div className="loader"></div>
              }
              <Switch>
                {userData && <Route path="/app" component={AppDashboard} />}
                <Route path="/" component={App} />
              </Switch>
              <ToastContainer />
            </Router>
            :
            <InternetLost />
        }
      </>

    );
  }
}

export default connect(({ user: { userData, isLoading, progress } }) => ({ userData, isLoading, progress }))(MainApp);
