import { Form, Formik } from "formik";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, FormGroup } from "reactstrap";
import { FormikInput } from "../components/FormikFields";
import { setUserData } from "../redux/user/actions";
import apiClient from "../utils/apiClient";
import { history } from "../utils/Utils";
import {  loginSchema } from "../utils/validationSchema";
import imageName from '../assets/images/LOGO.jpeg'
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: new URLSearchParams(props.location.search.replace(/\+/g, "%2B")).get("email") || "",
      view: "login",
      token: "",
      userid: ""
    };
  }

  toggle = (view) => {
    this.setState({ view });
  };

  handleLogin = ({ email, password, userRole }) => {
    userRole = 'admin'
    apiClient.post("users/signin", { email: email, password, userRole }).then(({ status, data }) => {
      if (status === 200) {
        setUserData(data.data, data.token);
        toast.success("Sign-in Successfully");
        history.push("/app/job-offers");
      }
      else {
        toast.error(data);
      }
    });
  };

  renderItems = () => {
    const { email, view } = this.state;
    const { label = "" } = this.props;
    switch (view) {
      case "login":
        return (
          <>
            {/* <img src={imageName} className="logo" /> */}
            <h2>Login to {label !== "" ? label : "your"} Account</h2>
            <Formik
              enableReinitialize
              initialValues={{ email, password: "" }}
              validationSchema={loginSchema}
              onSubmit={this.handleLogin}>
              {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                <Form className="mt-5">
                  <FormGroup className="mb-4">
                    <FormikInput
                      label="Email"
                      name="email"
                      type="email"
                      icon="flaticon-mail"
                      error={touched.email && errors.email}
                      value={values.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      label="Password"
                      name="password"
                      type="password"
                      icon="flaticon-padlock"
                      error={touched.password && errors.password}
                      value={values.password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <Button className="login-button w-100 mt-4" type="submit">
                    Login Now
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="loginContainer">
        <div className="login-content">
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="app-logo">
          <img className="img" src={imageName} alt="GBT Logo" title="GBT" />
          <h4 style={{fontWeight:"bold"}}>GBT</h4>
         </div>

          <div>{this.renderItems()}</div>
        </div>
      </div>
    );
  }
}

export default connect(({ user: { userData } }) => ({ userData }))(Login);
