import { Form, Formik } from 'formik';
import React, { Component } from 'react';
import { Button, FormGroup } from 'reactstrap';
import { FormikInput, FormikRadioButtonGroup, FormikSelect } from '../../../../components/FormikFields';
import FileUpload from '../../../../components/FileUpload';
import { languageData } from '../../../../utils/Config';

export default class CustomForm extends Component {
  constructor() {
    super();
    this.state = {
      attachmentsinEn: [],
      attachmentsinFr: [],
    };
  }

  render() {
    const { onSubmit, mode, t, touchedData } = this.props;
    return (
      <div className="add-counter">
        <div className="fields col-11 mx-auto">
          <Formik
            // validationSchema={categorySchema}
            enableReinitialize
            initialValues={{
              subject: '',
              body: '',
              templateId: '',
              attachmentsinEn: [],
              attachmentsinFr: [],
              language: '',
            }}
            onSubmit={onSubmit}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <>
                  <FormGroup className=" px-5" style={{ boxSizing: 'border-box', paddingLeft: '5rem !important' }}>
                    <FormikSelect
                      label={t("templateId")}
                      name="templateId"
                      value={values.templateId}
                      onChange={setFieldValue}
                      disabled={mode === 'view'}
                      onBlur={setFieldTouched}
                      options={touchedData}
                      required={true}
                    />
                    <FormikInput
                      label={t('ad.subject')}
                      name="subject"
                      disabled={mode === 'view'}
                      error={touched.subject && errors.subject}
                      value={values.subject}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                    <FormikInput
                      label="Message"
                      name="message"
                      disabled={mode === 'view'}
                      error={errors.message && !values.message ? errors.message : false}
                      value={values.message}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      multiline={true}
                    />
                   
                    <h4 className="mt-3">Attachements</h4>
                    <FileUpload
                      name="attachmentsinEn"
                      profile
                      label="attachement"
                      disabled={mode === 'view'}
                      value={values.attachmentsinEn}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                </>
                {mode !== 'view' && (
                  <div className="col-12 d-flex my-4">
                    <Button type="submit" color="success" className="my-4 mx-auto">
                      {t('mail.send')}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}