import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Button, Input } from "reactstrap";
import CardList from "../../../../../components/CardList";
import apiClient from "../../../../../utils/apiClient";
import NewProjectForm from "./NewProjectForm";
import FilterProjectForm from "./FilterProjectForm";
import FilterListIcon from "@material-ui/icons/FilterList";
import clsx from "clsx";
import CampaignMailForm from "./CampaignMailform";
import { sendGridApi } from '../../../Campaigns/sendGrid'
import { progress } from '../../../../../redux/user/actions';
import Alert from "../../../../../components/Alert";

export default class NewProject extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      edit_row_id: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      loader: false,
      isOpen: false,
      openFilterPopup: false,
      openCampaignModel: false,
      activity: "",
      countyOfOrigin: "",
      valueOfProject: "",
      orderOnField: null,
      isOrderByAscending: false,
      search: "",
      selectedItems: [],
      lastChecked: null,
      finalSelectedItems: [],
      sendGridData: [],
      allData: [],
      isSelectEveryone: false,
    };
  }

  componentDidMount() {
    this.getUserData();
    this.getsendGridData();

  }


  /* fetch send grid data */
  getsendGridData = async () => {
    try {
      const result = await sendGridApi()
      console.log("result",result)
      if (result && result.length > 0) {
        this.setState({ sendGridData: result })
      }
    } catch (error) {
      console.error('Error fetching sendGridData:', error);
      // Handle the error, show a message, etc.
    }
  }
  



  /* filter model */
  handleFilterModal = () => {
    this.setState({ openFilterPopup: !this.state.openFilterPopup });
  };

  /* Campaign model */
  handleCampaignModal = () => {
    console.log('immnmm');
    this.setState({ openCampaignModel: !this.state.openCampaignModel });
  };

  // getUserData = () => {
  //   const { page, size, activity, valueOfProject, countyOfOrigin, orderOnField, isOrderByAscending, search } = this.state;
  //   const status = "new";
  //   apiClient
  //     .post("admin-project/get-project", {
  //       page,
  //       size,
  //       activity,
  //       valueOfProject,
  //       countyOfOrigin,
  //       status,
  //       search,
  //       orderOnField, isOrderByAscending,
  //     })
  //     .then(({ status, data: { page, size, data, allData, totalCount } }) => {
  //       if (status === 200) {
  //         size = size < 10 ? 10 : size;
  //         this.setState({
  //           page,
  //           size,
  //           data,
  //           totalCount,
  //           allData,
  //           total_page: Math.ceil(totalCount / size),
  //         });
  //       } else {
  //         // toast.error("No Data Found")
  //       }
  //     });
  // };
  getUserData = () => {
    const { page, size, activity, valueOfProject, countyOfOrigin, orderOnField, isOrderByAscending, search } = this.state;
    const status = "new";
    apiClient
      .post("admin-project/get-project", {
        page,
        size,
        activity,
        valueOfProject,
        countyOfOrigin,
        status,
        search,
        orderOnField,
        isOrderByAscending,
      })
      .then(({ status, data: { page, size, data, allData, totalCount } }) => {
        if (status === 200) {
          size = size < 10 ? 10 : size;
          
          // Apply filter function based on the title search query
          const filteredData = data.filter(item => item.title.toLowerCase().includes(search.toLowerCase()));
          // console.log("filteredData",filteredData)
          this.setState({
            page,
            size,
            data: filteredData,
            totalCount,
            allData:data,
            total_page: Math.ceil(totalCount / size),
          });
        } else {
           toast.error("No Data Found")
        }
      });
  };

  /* sorting */
  handleSortingChange = (keyName, isAscendingOrder) => {
    this.setState({
      orderOnField: keyName,
      isOrderByAscending: isAscendingOrder
    }, () => {
      this.getUserData()
    })
  }

  /*clear filter */
  clearFilterHandler = () => {
    this.setState(
      {
        activity: "",
        countyOfOrigin: "",
        valueOfProject: "",
      },
      () => {
        this.getUserData();
      }
    );
  };

  toggleModal = (
    mode = "",
    edit_row = { terminalName: "", startNo: "", status: "Active" }
  ) => {
    this.setState({ mode, edit_row });
  };

  handleSubmit = (data) => {
    var bodyData = {
      rating: data["rating"],
      ratingComments: data["ratingComments"],
    };
    apiClient
      .put("admin-project/updateProject/" + data._id, bodyData)
      .then(({ status, data }) => {
        if (status === 200) {
          this.getUserData();
          toast.success(data.message);
        }
      });
    this.toggleModal();
  };

  handleDelete = (data,isDelete)=>{
    // console.log("Data",data,isDelete)
    let bodyData={isDeleted:isDelete}
    apiClient.put(`admin-project/updateDeleteKey/${data._id}`,bodyData)
      .then(({ status, data }) => {
        if (status === 200) {
          this.getUserData();
          toast.success("Deleted Successfully");
        }
      })
      this.toggleModal();
  }
  // handleSubmit = (data, isDelete) => {
  //   console.log("data")
  //   if (isDelete) {
  //     // console.log("isDelete",isDelete)
     
  //       // .catch((error) => {
  //       //   console.error('Error deleting project:', error);
  //       // });
  //   } else {
  //      var bodyData = {
  //     rating: data["rating"],
  //     ratingComments: data["ratingComments"],
  //   };
  //   apiClient
  //     .put("admin-project/updateProject/" + data._id, bodyData)
  //     .then(({ status, data }) => {
  //       if (status === 200) {
  //         this.getUserData();
  //         toast.success(data.message);
  //       }
  //     });
  //   this.toggleModal();
  //   }
  // };


  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  /* apply filter */
  handleApplyFilter = (rowData) => {
    this.setState({
      activity: rowData.activity,
      countyOfOrigin: rowData.countyOfOrigin,
      valueOfProject: rowData.valueOfProject,
      openFilterPopup: false,
    });
    this.getUserData();
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getUserData());
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });

    if (value.length === 0) {
      this.setState({ [name]: "" }, () =>
        this.getUserData());

    } else if (value.length >= 3) {
      this.getUserData()
    }
  };

  onCheckEveryone = (allData) => {
    // console.log("allData",allData)
    if (!allData) {
      return;
    }
  
    if (this.state.isSelectEveryone) {
      // Remove all users
      this.setState({
        selectedItems: [],
        isSelectEveryone: false,
      });
    } else {
      // Add all users
      console.log("allData",allData)
      this.setState({
        selectedItems: allData.map((item) => item.email),
        isSelectEveryone: true,
      });
    }
  };

  /* on change for checkbox */
  // onCheckItem = (event, id,email) => {
  //   console.log("email",email)
  //   console.log("id",id)
  //   let selectedItems = this.state.selectedItems;

  //   if (selectedItems.includes(id)) {
  //     selectedItems = selectedItems.filter((item) => item !== id);
  //     this.setState({
  //       selectedItems,
  //     });
  //   } else {
  //     selectedItems.push(id,email);
  //   }
  //   this.setState({
  //     ...selectedItems,
  //   });
  // };

  onCheckItem = (event, email) => {
    console.log("email",email)
    let selectedItems = this.state.selectedItems;

    if (selectedItems.includes(email)) {
      selectedItems = selectedItems.filter((item) => item !== email);
      this.setState({
        selectedItems,
      });
    } else {
      selectedItems.push(email);
    }
    this.setState({
      ...selectedItems,
    });
  };


  handleSubmitCampaign = (data) => {
console.log("object",data)
//   if(this.state.selectedItems.length===2){
//   var lowercaseArray = [(this.state.selectedItems[1]).toLowerCase()]
// }else{
//   var lowercaseArray = this.state.selectedItems.map(x => x.toLowerCase())
// }

var lowercaseArray = this.state.selectedItems.map(x => x.toLowerCase())
    /* remove duplicates from arrray */
    var uSet = new Set(lowercaseArray);

    /* unique values */
    this.setState({ selectedItems: [...uSet] })
    // console.log("this.state.selectedItems",this.state.selectedItems)
    var bodyData = {
      question: data.question,
      questionInFr: data.questionInFr,
      answer: data.answer,
      answerInFr: data.answerInFr,
    };
    const rowData = this.state.edit_row;
    var form_data = new FormData();
    if (data) {
      form_data.append('mailIds', this.state.selectedItems);
      form_data.append('subject', data.subject);
      form_data.append('message', data.message);
      form_data.append('templateId', data.templateId);
      if (data.attachmentsinEn) {
        form_data.append('attachments', data.attachmentsinEn[0]);
      }
    }
    if (data.language == 'french') {
      form_data.append('mailIds', this.state.selectedItems);
      form_data.append('subject', data.subjectinFr);
      form_data.append('message', data.messageinFr);
      form_data.append('templateId', data.templateId);
      if (data.attachmentsinFr) form_data.append('attachements', data.attachmentsinFr[0]);
    }
    var options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.round((loaded * 100) / total);
        this.setState({
          progress: percent,
        });
        console.log(`${loaded} kb of ${total} kb | ${percent}%`);
      },
    };
    // if (mode === "add") {
    apiClient
      .put(
        'users/campaign-mail',
        form_data,
        options,
      )
      .then(({ status }) => {
        // this.setState({
        //   loader: true,
        // });
        if (status === 200) {
          // this.setState({ loader: false });
          this.getUserData();
          toast.success('Mail Sent Successfully');
          this.setState({ openCampaignModel: !this.state.openCampaignModel });

        }
      });
    this.toggleModal();
  };

  render() {
    const { mode, edit_row, data, size, total_page, page, totalCount, search, sendGridData } =
      this.state;
    const { label = "", t } = this.props;
    const touchedData = sendGridData.map((item) => ({ label: item.name, value: item.id }));

    return (
      <>
        <Modal
          className="terminals-modal"
          isOpen={Boolean(mode)}
          toggle={() => this.toggleModal()}
        >
          <ModalHeader toggle={() => this.toggleModal()}>
            {mode
              ? mode === "add"
                ? "Add New " + label
                : mode === "edit"
                  ? label
                  : t("ad.view") + " " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              {mode && (
                <NewProjectForm
                  data={edit_row}
                  mode={mode}
                  title="Add new Terminal"
                  t={t}
                  onSubmit={this.handleSubmit}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="terminals-modal"
          isOpen={this.state.openFilterPopup}
          toggle={() => this.handleFilterModal()}
        >
          <ModalHeader toggle={() => this.handleFilterModal()}>
            Filter Record
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              <FilterProjectForm
                data={edit_row}
                mode={mode}
                title="Add new User"
                onSubmit={this.handleApplyFilter}
                t={t}
              />
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="terminals-modal"
          isOpen={this.state.openCampaignModel}
          toggle={() => this.handleCampaignModal()}
        >
          <ModalHeader toggle={() => this.handleCampaignModal()}>
            Campaign mail
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              <CampaignMailForm
                data={edit_row}
                mode={mode}
                title="Add new User"
                onSubmit={this.handleSubmitCampaign}
                t={t}
                touchedData={touchedData}
              />
            </div>
          </ModalBody>
        </Modal>
        <div className="search-box row col-5">
          <i className="fi flaticon-loupe" />
          <Input
            type="search"
            placeholder="Search here.."
            value={search}
            onChange={(e) => this.handleChange("search", e.target.value)}
          />
        </div>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          subHeaderComponent={
            <>
              <Button
                className="ml-auto"
                color="success"
                onClick={() => {
                  if (this.state.selectedItems.length === 0) {
                    return toast.error('Please select atleast one Student');
                  } else {
                    this.handleCampaignModal();
                  }
                }}
              >
                {'+ Compose'}
              </Button>
              &nbsp;
              &nbsp;
              <Button
                color="success"
                onClick={() => this.handleFilterModal()}
              >
                {/* {"+ " + t("ct.add")} */}
                <FilterListIcon /> {" Filter"}
              </Button>
            </>
          }
          column={[
            { title: t("title"), field: "title" },
            {
              title: t("project.paid"),
              field: "projectStatus",
              render: (rowData) => <span className={clsx("email", rowData.projectStatus)}>{rowData.projectStatus == "REMAIN" ? "No" : rowData.projectStatus == "ACTIVE" ? 'Yes' : 'Expired'}</span>
            },
            {
              title: t("approval.email"),
              field: "email",
            },
            {
              title: t("ispaidVerification"),
              field: "isApplicableForVerification",
              render: (rowData) => <span className={clsx("isApplicableForVerification", rowData.isApplicableForVerification)}>{rowData.isApplicableForVerification == true ? "Yes" : "No"}</span>
            },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData)},
              {
                icon: "fi flaticon-delete-1",
                className: "btn-white text-danger p-0",
                onClick: (rowData) =>
                  Alert.info(
                    t("ad.deleteConfirm"),
                    t("project.msg"),
                    () => this.handleDelete(rowData,true)
                  )
              }
            
            // {
            //   icon: "fi flaticon-delete-1",
            //   className: "btn-white text-danger p-0",
            //   onClick: (rowData) =>
            //     Alert.info(
            //       t("ad.rejectConform"),
            //       t("jobs.msg"),
            //       () => this.toggleModal("reject", rowData)
            //     )
            // }
          ]}
          handlePageChange={this.onPageChange}
          clearFilterHandler={this.clearFilterHandler}
          handleSortingChange={this.handleSortingChange}
          category={this.state.activity}
          valueOfProject={this.state.valueOfProject}
          countyOfOrigin={this.state.countyOfOrigin}
          onCheckItem={this.onCheckItem}
          onCheckEveryone={this.onCheckEveryone}
          selectedItems={this.state.selectedItems}
          isSelectEveryone={this.state.isSelectEveryone}
          allData={this.state.allData}
          options={{
            subHeader: true,
            isCollapsable: true,
            expandActions: true,
            handleCheckBox: true,
            checkboxWithActions: true,
            pagination: {
              showPagination: true,
              pageSelection: true,
              total_page,
              page,
              size,
              totalCount,
            },
          }}
          key={data && data._id}
        />
      </>
    );
  }
}

