import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { Formik, Form } from "formik";
import { FormikInput } from "../components/FormikFields";
import { setUserData } from "../redux/user/actions";
import apiClient from "../utils/apiClient";
import { history } from "../utils/Utils";
import { loginSchema } from "../utils/validationSchema";
import imageName from '../assets/images/LOGO.jpeg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: new URLSearchParams(props.location.search.replace(/\+/g, "%2B")).get("email") || "",
      view: "login/appUser",
      token: "",
      userid: "",
      userRole: "student", // Default userRole
    };
  }

  toggle = (view) => {
    this.setState({ view });
  };

  handleLogin = ({ email, password }) => {
    const { userRole } = this.state;
    apiClient.post("users/signin", { email, password, userRole }).then(({ status, data }) => {
      if (status === 200) {
        console.log("data", data);
        localStorage.setItem("appUserId", data.data.id);
        localStorage.setItem("userRole", data.data.userRole);
        setUserData(data.data, data.token);
        toast.success("Sign-in Successfully");
        history.push("/home");
      } else {
        toast.error(data);
      }
    });
  };

  handleCheckboxChange = (event) => {
    this.setState({ userRole: event.target.value });
  };

  renderItems = () => {
    const { email, view, userRole } = this.state;
    switch (view) {
      case "login/appUser":
        return (
          <>
            <h2>Login App User</h2>
            <Formik
              enableReinitialize
              initialValues={{ email, password: "" }}
              validationSchema={loginSchema}
              onSubmit={this.handleLogin}
            >
              {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                <Form className="mt-5">
                  <FormGroup className="mb-4">
                    <FormikInput
                      label="Email"
                      name="email"
                      type="email"
                      icon="flaticon-mail"
                      error={touched.email && errors.email}
                      value={values.email}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <FormikInput
                      label="Password"
                      name="password"
                      type="password"
                      icon="flaticon-padlock"
                      error={touched.password && errors.password}
                      value={values.password}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <div className="mb-4" style={{display:"flex",justifyContent:"center"}}>
                    <div><Label>
                      <Input
                        type="checkbox"
                        value="student"
                        checked={userRole === "student"}
                        onChange={this.handleCheckboxChange}
                      />{" "}
                      Individual
                    </Label></div>
                    <div style={{marginLeft:"20px"}}>
                    <Label className="ml-3">
                      <Input
                        type="checkbox"
                        value="company"
                        checked={userRole === "company"}
                        onChange={this.handleCheckboxChange}
                      />{" "}
                      Company
                    </Label>
                    </div>
                  </div>
                  <Button className="login-button w-100 mt-4" type="submit">
                    Login Now
                  </Button>
                </Form>
              )}
            </Formik>
          </>
        );
      default:
        return null;
    }
  };

  render() {
    return (
      <div className="loginContainer">
        <div className="login-content">
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} className="app-logo">
            <img className="img" src={imageName} alt="GBT Logo" title="GBT" />
            <h4 style={{ fontWeight: "bold" }}>GBT</h4>
          </div>

          <div>{this.renderItems()}</div>
        </div>
      </div>
    );
  }
}

export default connect(({ user: { userData } }) => ({ userData }))(Login);
