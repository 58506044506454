import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import AppLayout from "../layout/AppLayout";
import { Button, Modal } from "reactstrap";
import apiClient from "../utils/apiClient";
import toast from "react-hot-toast";
import { history } from "../utils/Utils";
import Admin from "./Admin";
import { setUserData } from "../redux/user/actions";

class Home extends Component {
  constructor() {
    super();
    this.state = {
      deleteModelOpen: false,
      password: "",
      data: ""
    };
  }
  logout = () => {
    setUserData();
    history.push("/");
  };
  openDeleteAppUser = (rowData) => {
    this.setState({
      deleteModelOpen: !this.state.deleteModelOpen,
      data: rowData ? rowData._id : ""
    });
  };

  deleteAppUser = () => {
    const id = localStorage.getItem("appUserId");
    const userRole = localStorage.getItem("userRole");
    console.log("id",id)
    const obj = {
      id: id,
      password: this.state.password
    };

    apiClient.put(`users/deleteUser`, obj).then(({ status, data }) => {
      if (status === 200) {
        this.setState({
          deleteModelOpen: false
        });
        toast.success("User has been successfully deleted");
        history.push("/login/appUser");
      }
    });
  };

  render() {
    const {
      userData: { userRole }
    } = this.props;
    const url = '/app';

    return (
      <AppLayout>
        {userRole === "admin" && <Admin url={url} />}
        <Button
          onClick={() => this.openDeleteAppUser()}
          style={{ marginTop: "30px", marginLeft: "350px" }}
        >
          Do you want to delete your account
        </Button>
        <Modal
          isOpen={this.state.deleteModelOpen}
          toggle={() => this.openDeleteAppUser()}
        >
          <h2 className="text-center mt-2">
            <b>Alert</b>{" "}
          </h2>
          <p className="text-center px-3">
            Do you really want to delete this
            <b style={{ fontSize: 20, margin: 5, color: "red" }}>
            </b>
            account? This will be an irreversible action. Please enter your
            password and click delete to proceed
          </p>
          <div
            className="add-counter"
            style={{ height: "120px", marginTop: "20px" }}
          >
            <div className="fields col-10 mx-auto text-center">
              <input
                type="password"
                className="col-10 p-2"
                name="password"
                placeholder="Enter password"
                id="password"
                onChange={(e) => {
                  this.setState({
                    password: e.target.value
                  });
                }}
              />

              <div className="col-12 text-center ">
                <Button
                  type="submit"
                  className="my-4 mx-auto"
                  onClick={() => this.deleteAppUser()}
                >
                  Delete
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </AppLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.user.userData
});

const mapDispatchToProps = (dispatch) => ({
  setUserData: () => dispatch(setUserData())
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);





// import React, { Component } from "react";
// import { connect } from "react-redux";
// import AppLayout from "../../layout/AppLayout";
// import { setUserData } from "../../redux/user/actions";
// import { history } from "../../utils/Utils";
// import Admin from "../Admin";

// class AppDashboard extends Component {
//   constructor() {
//     super();
//     this.state = {
//       timeout: 1000 * 60 * 30, // 30 minutes
//       isTimedOut: false
//     };
//   }

//   logout = () => {
//     setUserData();
//     history.push("/");
//   };

//   renderItems = () => {
//     const {
//       match: { url },
//       userData: { userRole }
//     } = this.props;
//     switch (userRole) {
//       case "admin":
//         return <Admin url={url} />;
//       default:
//         return null;
//     }
//   };

//   render() {
//     return <AppLayout>{this.renderItems()}</AppLayout>;
//   }
// }

// export default connect(({ user: { userData } }) => ({ userData }))(AppDashboard);
