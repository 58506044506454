
import React, { Component } from "react";
import { toast } from "react-toastify";
import FilterListIcon from '@material-ui/icons/FilterList';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import AdUpdateForm from "../UpdatePrice/AdUpdateForm";
import Alert from "../../../../components/Alert";
import FilterFormPlan from "./FilterFormPlan";

export default class CreateAd extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      productId: '',
      orderOnField: null,
      isOrderByAscending: false,
      openFilterPopup: false,
      category: null,
      subject: null,
    };
  }

  componentDidMount() {
    this.getAdsPrice();
  }

  getAdsPrice = () => {
    const { page, size, orderOnField, isOrderByAscending, subject, category } = this.state;
    apiClient.post("lists/price-list-admin", { page, size, orderOnField, isOrderByAscending, subject, category }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data: data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      }
      else {
        console.log('')
      }
    });
  };

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  handleFilterModal = () => {
    this.setState({ openFilterPopup: !this.state.openFilterPopup });
  }

  toggleModal = (
    mode = "",
    edit_row = {
      duration: "",
      category: "",
      price: "",
      productId: ''
    }
  ) => {
    this.setState({ mode, edit_row });
  };


  handleSubmit = (rowData) => {
    var { mode } = this.state;
    const bodyData = {
      duration: rowData.duration.length > 0 ? rowData.duration : "NaN",
      consoleId: mode == 'add' ? rowData.price : rowData.price,
      category: rowData.category,
      subject: rowData.subject,
    }
    if (mode === "add") {
      apiClient.post("dashboard/create", bodyData).then(({ status, data }) => {
        if (status === 200) {
          toast.success(data.message);
          this.getAdsPrice();
        } else {
          toast.error('This duration already added')
        }
      });
      this.toggleModal();
    } else {
      apiClient.put("dashboard/update-price/" + rowData._id, bodyData).then(({ status, data }) => {
        if (status === 200) {
          toast.success(data.message);
          this.getAdsPrice();
        } else {
          toast.error(data.message);
        }
      });
      this.toggleModal();
    }
  };

  handleApplyFilter = (rowData) => {
    this.setState({
      subject: rowData.subject,
      category: rowData.category,
      openFilterPopup: false,
    })
    this.getAdsPrice()
  } 

  handleDelete = (id) => {
    apiClient.delete("dashboard/delete-price/" + id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getAdsPrice();
      } else {
        toast.error(data.message);
      }
    });
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getAdsPrice());
  };

  handleSortingChange = (keyName, isAscendingOrder) => {
    this.setState({
      orderOnField: keyName,
      isOrderByAscending: isAscendingOrder
    }, () => {
      this.getAdsPrice()
    })
  }

  clearFilterHandler = () => {
    this.setState({ category: null, subject: null }, () => {
      this.getAdsPrice()
    })
  }

  render() {
    const { mode, edit_row, data, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;
    return (
      <>
        <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>
            {mode
              ? mode === "add"
                ? t("price.add") + " " + label
                : mode === "edit"
                  ? t("") + " " + label
                  : t("ad.view") + " " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              {mode && (
                <AdUpdateForm
                  data={edit_row}
                  mode={mode}
                  title="Add new User"
                  onSubmit={this.handleSubmit}
                  t={t}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
        <Modal className="terminals-modal" isOpen={this.state.openFilterPopup} toggle={() => this.handleFilterModal()}>
          <ModalHeader toggle={() => this.handleFilterModal()}>
            Filter Record
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              <FilterFormPlan
                data={edit_row}
                mode={mode}
                title="Add new User"
                onSubmit={this.handleApplyFilter}
                t={t}
              />
            </div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          subHeaderComponent={
            <>
              <Button className="ml-auto" color="success" onClick={() => this.toggleModal("add")}>
                <AddCircleIcon /> {t("ct.add")}
              </Button>
              <Button className="ml-2" color="success" onClick={() => this.handleFilterModal()}>
                {/* {"+ " + t("ct.add")} */}
                <FilterListIcon /> {" Filter"}
              </Button>
            </>
          }
          key={data._id}
          // subHeaderComponent={
          //   // <Button className="ml-auto" color="primary" onClick={() => this.toggleModal("add")}>
          //   //   + Add New
          //   // </Button>
          // }
          column={
            [
              // { title: t("price.id"), field: "id" },
              { title: t("ad.duration"), field: "duration" },
              { title: t("price.doller"), field: "price" },
              { title: t("ad.category"), field: "category" },
              { title: t("ct.module"), field: "subject" },
            ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("view", rowData)
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData)
            },
            {
              icon: "fi flaticon-delete-1",
              className: "btn-white text-danger p-0",
              onClick: (rowData) =>
                Alert.info(
                  t("delete.msg"),
                  t('delete.d'),
                  () => this.handleDelete(rowData._id)
                )
            }
          ]}
          handlePageChange={this.onPageChange}
          handleSortingChange={this.handleSortingChange}
          clearFilterHandler={this.clearFilterHandler}
          category= {this.state.category}
          subject= {this.state.subject}
          options={{
            subHeader: true,
            isCollapsable: true,
            expandActions: true,
            pagination: { showPagination: true, pageSelection: true, total_page, page, size, totalCount }
          }}
        />
      </>
    );
  }
}
