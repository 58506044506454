import { Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikSelect, } from "../../../../../components/FormikFields";
import apiClient from "../../../../../utils/apiClient";
import { subjectData, valueOfProjectData, ratingData } from "../../../../../utils/Config";
import countries from "i18n-iso-countries";
// Import the languages you want to use
import enLocale from "i18n-iso-countries/langs/en.json";
import { projectSchema } from '../../../../../utils/validationSchema'



export default function FilterProjectForm({ onSubmit, t }) {
  const [categoryData, setcategoryData] = useState([])
  const [module, setmodule] = useState('')
  const [countryData, setcountryData] = useState([])
  countries.registerLocale(enLocale);
  // Returns an object not a list
  const countryObj = countries.getNames("en", { select: "official" });

  useEffect(() => {
    getAdsPrice();
    getCountry()
  }, [module])

  const getAdsPrice = () => {
    apiClient.get("category/get-categories", { params: { module: 'project' } }).then(({ status, data }) => {
      if (status === 200) {
        setcategoryData(data.data)
      } else {
        setcategoryData([])
      }
    });
  };

  const getCountry = () => {
    const countries = Object.entries(countryObj).map(([key, value]) => ({ label: value, value: value }))
    setcountryData(countries)
  }

  const touchedData = categoryData.map((item) => ({ label: item.name, value: item._id }));

  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik
          enableReinitialize
          initialValues={{
            activity: "",
            countyOfOrigin: "",
            valueOfProject: "",
            rating: ''
          }}
          // validationSchema={projectSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("ad.category")}
                  name="activity"
                  value={values.activity}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={touchedData}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("project.value")}
                  name="valueOfProject"
                  value={values.valueOfProject}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={valueOfProjectData}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("student.country")}
                  name="countyOfOrigin"
                  value={values.countyOfOrigin}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                  options={countryData}
                />
              </FormGroup>
              <div className="col-12 d-flex my-4">
                <Button type="submit" color="success" className="my-4 mx-auto">
                  Apply Filter
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}
