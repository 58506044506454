import moment from "moment";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader, FormGroup } from "reactstrap";
import Alert from "../../../../components/Alert";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import { Form, Formik } from "formik";
import { FormikInput } from "../../../../components/FormikFields";
import FileUpload from "../../../../components/FileUpload";
import { questionSchema } from "../../../../utils/validationSchema";

export default class QuizQuestionList extends Component {
  constructor() {
    super();
    this.state = {
      courseId: "",
      question: "",
      questionInFr: "",
      option1: "",
      option1InFr: "",
      option2: "",
      option2InFr: "",
      option3: "",
      option3InFr: "",
      option4: "",
      option4InFr: "",
      option5: "",
      option5InFr: "",
      validAnswer: "",
      validAnswerInFr: "",
      selectedRowId: "",
      mode: "",
      data: [],
    };
  }

  componentDidMount() {
    if (this.props.courseId) {
      this.setState({ courseId: this.props.courseId });
    }
    this.getData();
  }

  handleDelete = (id) => {
    apiClient
      .delete(`course/delete-quiz-question/${id}/${this.state.courseId}`)
      .then(({ status, data }) => {
        if (status === 200) {
          toast.success(data.message);
          this.getData();
        }
      });
  };

  getData = () => {
    apiClient
      .get(`course/getCoursebyId/${this.props.courseId}`)
      .then(({ status, data: { data } }) => {
        let quizInfo = data.quizQuestionList;
        quizInfo = quizInfo.map((q) => {
          return {
            ...q,
            optionsInString: q.options.join(","),
            optionsInStringInFr: q.optionsInFr.join(","),
          };
        });
        this.setState({
          data: quizInfo,
        });
      });
  };

  toggleModal = (mode = "", rowData) => {
    if (rowData) {
      const {
        question,
        questionInFr,
        options,
        optionsInFr,
        validAnswer,
        validAnswerInFr,
        _id,
      } = rowData;
      this.setState({
        selectedRowId: _id,
        question,
        questionInFr,
        option1: options[0],
        option1InFr: optionsInFr[0],
        option2: options[1],
        option2InFr: optionsInFr[1],
        option3: options[2],
        option3InFr: optionsInFr[2],
        option4: options[3],
        option4InFr: optionsInFr[3],
        option5: options[4],
        option5InFr: optionsInFr[4],
        validAnswer,
        validAnswerInFr,
      });
    }
    this.setState({
      mode,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = ({
    question,
    questionInFr,
    option1,
    option1InFr,
    option2,
    option2InFr,
    option3,
    option3InFr,
    option4,
    option4InFr,
    option5,
    option5InFr,
    validAnswer,
    validAnswerInFr,
  }) => {
    const { courseId } = this.state;
    if (this.state.mode === "edit") {
      const form_data = {
        courseId,
        question,
        questionInFr,
        options: [option1, option2, option3, option4, option5],
        optionsInFr: [
          option1InFr,
          option2InFr,
          option3InFr,
          option4InFr,
          option5InFr,
        ],
        validAnswer,
        validAnswerInFr,
      };
      form_data.options = form_data.options.filter(el => el !== undefined && el !== "");
      form_data.optionsInFr = form_data.optionsInFr.filter(el => el !== undefined && el !== "");

      apiClient
        .put(
          `course/update-quiz-question/${this.state.selectedRowId}/${courseId}`,
          form_data
        )
        .then(({ status }) => {
          if (status === 200) {
            this.setState({
              lessions: [],
            });
            this.getData();
            toast.success("Quiz Updated Successfully");
          }
          this.toggleModal();
        });
    } else {
      const form_data = {
        courseId,
        question,
        questionInFr,
        options: [option1, option2, option3, option4, option5],
        optionsInFr: [
          option1InFr,
          option2InFr,
          option3InFr,
          option4InFr,
          option5InFr,
        ],
        validAnswer,
        validAnswerInFr,
      };
      form_data.options = form_data.options.filter(el => el !== "");
      form_data.optionsInFr = form_data.optionsInFr.filter(el => el !== "");
      apiClient
        .post("course/create-quiz-question", form_data)
        .then(({ status }) => {
          if (status === 200) {
            this.setState({
              lessions: [],
            });
            this.getData();
            toast.success("Question created successfully");
          }
          this.toggleModal();
        });
    }
  };

  formUI() {
    const {
      question,
      questionInFr,
      option1,
      option1InFr,
      option2,
      option2InFr,
      option3,
      option3InFr,
      option4,
      option4InFr,
      option5,
      option5InFr,
      validAnswer,
      validAnswerInFr,
    } = this.state;
    const { t } = this.props;
    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            enableReinitialize
            initialValues={{
              question,
              questionInFr,
              option1,
              option1InFr,
              option2,
              option2InFr,
              option3,
              option3InFr,
              option4,
              option4InFr,
              option5,
              option5InFr,
              validAnswer,
              validAnswerInFr,
            }}
            onSubmit={this.handleSubmit}
            validationSchema={questionSchema}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("quiz.question")}
                    name="question"
                    disabled={this.state.mode === "view"}
                    value={values.question}
                    error={touched.question && errors.question}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("quiz.question")}
                    name="questionInFr"
                    disabled={this.state.mode === "view"}
                    value={values.questionInFr}
                    error={touched.questionInFr && errors.questionInFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <br />
                <br />
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 1"
                    name="option1"
                    disabled={this.state.mode === "view"}
                    value={values.option1}
                    error={touched.option1 && errors.option1}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 1"
                    name="option1InFr"
                    disabled={this.state.mode === "view"}
                    value={values.option1InFr}
                    error={touched.option1InFr && errors.option1InFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 2"
                    name="option2"
                    disabled={this.state.mode === "view"}
                    value={values.option2}
                    error={touched.option2 && errors.option2}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 2"
                    name="option2InFr"
                    disabled={this.state.mode === "view"}
                    value={values.option2InFr}
                    error={touched.option2InFr && errors.option2InFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 3"
                    name="option3"
                    disabled={this.state.mode === "view"}
                    value={values.option3}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 3"
                    name="option3InFr"
                    disabled={this.state.mode === "view"}
                    value={values.option3InFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 4"
                    name="option4"
                    disabled={this.state.mode === "view"}
                    value={values.option4}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 4"
                    name="option4InFr"
                    disabled={this.state.mode === "view"}
                    value={values.option4InFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 5"
                    name="option5"
                    disabled={this.state.mode === "view"}
                    value={values.option5}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Option 5"
                    name="option5InFr"
                    disabled={this.state.mode === "view"}
                    value={values.option5InFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <br />
                <br />
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("quiz.answer")}
                    name="validAnswer"
                    disabled={this.state.mode === "view"}
                    value={values.validAnswer}
                    error={touched.validAnswer && errors.validAnswer}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("quiz.answer")}
                    name="validAnswerInFr"
                    disabled={this.state.mode === "view"}
                    value={values.validAnswerInFr}
                    error={touched.validAnswerInFr && errors.validAnswerInFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                {this.state.mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button
                      type="submit"
                      color="success"
                      className="my-4 mx-auto"
                    >
                      {this.state.mode === "edit"
                        ? t("course.update")
                        : t("quiz.addQuestion")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  render() {
    const { mode, data } = this.state;
    const { label = "", t } = this.props;
    const selectedLanguage = localStorage.getItem("selectedLanguage");

    return (
      <>
        <Modal
          className="course-modal"
          isOpen={Boolean(mode)}
          toggle={() => this.toggleModal()}
        >
          <ModalHeader toggle={() => this.toggleModal()}>
            {mode
              ? mode === "add"
                ? "Add New " + label
                : mode === "edit"
                  ? "Edit " + label
                  : "View " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">{mode && <>{this.formUI(t)}</>}</div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          subHeaderComponent={
            <Button
              className="ml-auto"
              color="success"
              onClick={() => this.toggleModal("add")}
            >
              + {t("quiz.add")}
            </Button>
          }
          column={[
            {
              title: t("quiz.questionName"),
              field: selectedLanguage == "en" ? "question" : "questionInFr",
            },
            {
              title: "Options",
              field:
                selectedLanguage == "en"
                  ? "optionsInString"
                  : "optionsInStringInFr",
            },
            {
              title: t("quiz.answers"),
              field:
                selectedLanguage == "en" ? "validAnswer" : "validAnswerInFr",
            },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) =>
                this.toggleModal("view", rowData) && this.formUI(t),
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData),
            },
            {
              icon: "fi flaticon-delete-1",
              className: "btn-white text-danger p-0",
              onClick: (rowData) =>
                Alert.info(
                  "Confirm Deletion!",
                  `Are you sure want to delete the ${label}`,
                  () => this.handleDelete(rowData._id)
                ),
            },
          ]}
          options={{
            subHeader: true,
            isCollapsable: true,
            expandActions: true,
          }}
          key={data._id}
        />
      </>
    );
  }
}
