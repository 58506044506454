import React, { Component } from "react";
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Alert from "../../../../../components/Alert";
import CardList from "../../../../../components/CardList";
import apiClient from "../../../../../utils/apiClient";
import JobOffersForm from "./JobOffersForm";
import clsx from "clsx";


export default class JobOffers extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      edit_row_id: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      isOpen: false
    };
  }

  componentDidMount() {
    this.getUserData();
  }

  getUserData = () => {
    const { page, size } = this.state;
    const isOld = false
    apiClient.get("admin-company/getJob", { params: { page, size, isOld } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      } else {
        // toast.error("No Data Found")
      }
    });
  };

  toggleModal = (mode = "", edit_row = { terminalName: "", startNo: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  handleSubmit = (data) => {
    console.log('innn');
    const { mode } = this.state
    console.log(data)
    if (mode === 'edit') {
      var bodyData = {
        isApprove: true,
        status: 'approved',
        rating: data['rating'],
        ratingComments: data['ratingComments']
      };
      apiClient.put("admin-company/approve-job/" + data.id, bodyData).then(({ status, data }) => {
        if (status === 200) {
          this.getUserData();
          toast.success(data.message);
        }
      });
      this.toggleModal();
    } else {
      var bodyDatas = {
        isApprove: false,
        adminComment: data.adminComment,
        status: 'rejected'
      };
      apiClient.put("admin-company/approve-job/" + data.id, bodyDatas).then(({ status, data }) => {
        if (status === 200) {
          this.getUserData();
          toast.success('Rejected Successfully');
        }
      });
      this.toggleModal();
    }
  };


  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getUserData());
  };

  render() {
    const { mode, edit_row, data, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;

    return (
      <>
        <Modal className="terminals-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>
            {mode
              ? mode === "add"
                ? "Add New " + label
                : mode === "edit"
                  ? t("ad.edit") + " " + label
                  : t("ad.view") + " " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              {mode && (
                <JobOffersForm
                  data={edit_row}
                  mode={mode}
                  title="Add new Terminal"
                  t={t}
                  onSubmit={this.handleSubmit}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          column={[
            { title: t("title"), field: "title" },
            // { title: t("jobs.location"), field: "location" },
            { title: t("approval.city"), field: "city" },
            { title: t("student.country"), field: "country" },
            {
              title: t("jobs.postedBy"),
              field: "uploadedBy",
            },
            {
              title: t("isPaid"),
              field: "jobStatus",
              render: (rowData) => <span className={clsx("jobStatus", rowData.jobStatus)}>{rowData.jobStatus == "ACTIVE" ? "Yes" : "No"}</span>
            }
            ,
            {
              title: t("approve"),
              field: "status",
              render: (rowData) => <span className={clsx("s", rowData.status)}>{rowData.status == "approved" ? "Yes" : "No"}</span>
            }
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData)
            },
            {
              icon: "fi flaticon-delete-1",
              className: "btn-white text-danger p-0",
              onClick: (rowData) =>
                Alert.info(
                  t("ad.rejectConform"),
                  t("jobs.msg"),
                  () => this.toggleModal("reject", rowData)
                )
            }
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: true,
            isCollapsable: true,
            expandActions: true,
            pagination: { showPagination: true, pageSelection: true, total_page, page, size, totalCount }
          }}
        />

      </>
    );
  }
}
