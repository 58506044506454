import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup, Modal } from "reactstrap";
import FileUpload from '../../../../components/FileUpload'
import { FormikInput, FormikCheckbox, FormikSelect, FormikRadioButtonGroup } from "../../../../components/FormikFields";
import { announceSchema } from "../../../../utils/validationSchema";
import ReactPlayer from 'react-player'
import apiClient from "../../../../utils/apiClient";
import { Link } from 'react-router-dom'
import { categoryList, booleanData } from '../../../../utils/Config'
import { array } from "yup";
export default class CompanyStudForm extends Component {
  constructor() {
    super();
    this.state = {
      announcementImage: [],
      announcementVideo: [],
      announcementVideoInFr: [],
      datas: [],
      url: '',
      categoryData: [],
      isOpen: false
    }
  }

  fileSelectedHandler = event => {
    this.setState({
      announcementImage: event.target.files[0]
    })
  }
  componentDidMount() {
    this.getAdsPrice()
  }


  getAdsPrice = () => {
    apiClient.get("category/get-category", { params: { module: 'Announcement' } }).then(({ status, data }) => {
      if (status === 200) {
        this.setState({ categoryData: data.data })
      }
      else {
        this.setState({ categoryData: [] })
      }
    });
  };


  render() {
    const touchedData = this.state.categoryData.map((item) => ({ label: item.name, value: item.name }));
    const { announcementImage, announcementVideo, announcementVideoInFr } = this.state
    const { data, onSubmit, mode, t } = this.props;

    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            enableReinitialize
            validationSchema={announceSchema}
            initialValues={{ ...data, announcementImage, announcementVideo, announcementVideoInFr } || {
              title: '',
              titleinFr: '',
              category: '',
              videoUrlEn: '',
              videoUrlFr: '',
              discription: '',
              descriptioninFr: '',
              announcementVideo1: '',
              announcementVideoInFr: [],
              announcementVideoInFr1: '',
              announcementVideo: [],
              // both: '',
              // wesiteLink: '',
              forRole: '',
              announcementImage: []
            }}
            onSubmit={onSubmit}>
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("title")}
                    name="title"
                    disabled={mode === "view"}
                    error={touched.title && errors.title}
                    value={values.title}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="Titre"
                    name="titleinFr"
                    disabled={mode === "view"}
                    error={touched.titleinFr && errors.titleinFr}
                    value={values.titleinFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("ad.description")}
                    name="description"
                    multiline
                    disabled={mode === "view"}
                    error={touched.description && errors.description}
                    value={values.description}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label="la description"
                    name="descriptioninFr"
                    disabled={mode === "view"}
                    multiline
                    error={errors.descriptioninFr && !values.descriptioninFr ? errors.descriptioninFr : false}
                    value={values.descriptioninFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                {
                  mode !== 'view' &&
                  <FormGroup className="col-12 px-5 text-center">upload video or Add video url</FormGroup>
                }
                {
                  mode === 'view' ?
                    data.announcementVideo &&
                    <FormGroup className="col-12 px-4">
                      {t("announcement.videoUrl")}
                      <br />{" "}
                      <a href={data.announcementVideo}>
                        {data.announcementVideo ? (
                          data.announcementVideo
                        ) : (
                          <b>{t("announcement.document")}</b>
                        )}
                      </a>
                    </FormGroup>
                    : mode === 'edit' ?
                      <div className="col-12 row px-4">
                        {data.announcementVideo &&
                          <FormGroup className="col-12 my-4">
                            {t("announcement.videoUrl")}
                            <br />{" "}
                            <a href={data.announcementVideo}>
                              {data.announcementVideo ? (
                                data.announcementVideo
                              ) : (
                                <b>{t("announcement.document")}</b>
                              )}
                            </a>
                          </FormGroup>
                        }
                        {data.announcementVideoInFr &&
                          <FormGroup className="col-12 my-4">
                            {t("announcement.videoUrlinFrench")}
                            <br />{" "}
                            <a href={data.announcementVideoInFr}>
                              {data.announcementVideoInFr ? (
                                data.announcementVideoInFr
                              ) : (
                                <b>{t("announcement.document")}</b>
                              )}
                            </a>
                          </FormGroup>
                        }
                        <FormGroup className="col-6 ">
                          <FileUpload
                            name="announcementVideo"
                            type="video/mp4"
                            profile
                            label={t("quiz.uploadVideo")}
                            disabled={mode === "view"}
                            error={errors.announcementVideo && !values.announcementVideo ? errors.announcementVideo : false}
                            value={values.announcementVideo}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                        <FormGroup className="col-6 ">
                          <FileUpload
                            name="announcementVideoInFr"
                            type="video/mp4"
                            profile
                            label={t("quiz.uploadVideo")}
                            disabled={mode === "view"}
                            error={touched.announcementVideoInFr && errors.announcementVideoInFr}
                            value={values.announcementVideoInFr}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                        {/* <FormGroup className="col-5 mt-4">
                          <FormikInput
                            label={t("announcement.videoUrl")}
                            name="announcementVideo1"
                            disabled={mode === "view"}
                            error={touched.announcementVideo1 || errors.announcementVideo1}
                            value={values.announcementVideo1}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup> */}
                      </div>
                      :
                      <FormGroup className="col-6 text-center">
                        <FileUpload
                          name="announcementVideo"
                          type="video/mp4"
                          profile
                          label={t("quiz.uploadVideo")}
                          disabled={mode === "view" || values.announcementVideo1 && values.announcementVideo1.length > 0}
                          error={touched.announcementVideo && errors.announcementVideo}
                          value={values.announcementVideo}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                          onClick={() => console.log('pressed')}
                        />
                      </FormGroup>
                }
                {
                  mode === 'view' ?
                    data.announcementVideo &&
                    <FormGroup className="col-12 px-4">
                      {t("announcement.videoUrl")}
                      <br />{" "}
                      <a href={data.announcementVideoInFr}>
                        {data.announcementVideoInFr ? (
                          data.announcementVideoInFr
                        ) : (
                          <b>{t("announcement.document")}</b>
                        )}
                      </a>
                    </FormGroup>
                    : mode === 'edit' ?
                      <div className="col-12 text-center row">
                        {/* {data.announcementVideo &&
                          <FormGroup className="col-12 my-4">
                            {t("announcement.videoUrl")}
                            <br />{" "}
                            <a href={data.announcementVideo}>
                              {data.announcementVideo ? (
                                data.announcementVideo
                              ) : (
                                <b>{t("course.document")}</b>
                              )}
                            </a>
                          </FormGroup>
                        } */}
                        {/* <FormGroup className="col-6 px-5 mt-4">
                          <FormikInput
                            label={t("announcement.videoUrl")}
                            name="announcementVideo1"
                            disabled={mode === "view"}
                            error={touched.announcementVideo1 || errors.announcementVideo1}
                            value={values.announcementVideo1}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                        <FormGroup className="col-6 px-5 mt-4">
                          <FormikInput
                            label={t("announcement.videoUrl")}
                            name="announcementVideoInFr1"
                            disabled={mode === "view"}
                            error={touched.announcementVideoInFr1 || errors.announcementVideoInFr1}
                            value={values.announcementVideoInFr1}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup> */}
                      </div>
                      :
                      <FormGroup className="col-6 text-center">
                        <FileUpload
                          name="announcementVideoInFr"
                          type="video/mp4"
                          profile
                          label={t("quiz.uploadVideo")}
                          // disabled={mode === "view" || values.announcementVideo1 && values.announcementVideo1.length > 0}
                          error={touched.announcementVideoInFr && errors.announcementVideoInFr}
                          value={values.announcementVideoInFr}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                        />
                      </FormGroup>
                }

                <FormGroup className="col-6 px-5 mt-4">
                  <FormikInput
                    label={t("announcement.videoUrl")}
                    name="videoUrlEn"
                    disabled={mode === "view"}
                    error={touched.videoUrlEn && errors.videoUrlEn}
                    value={values.videoUrlEn}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>

                <FormGroup className="col-6 px-5 mt-4">
                  <FormikInput
                    label={t("announcement.videoUrl")}
                    name="videoUrlFr"
                    disabled={mode === "view"}
                    error={touched.videoUrlFr && errors.videoUrlFr}
                    value={values.videoUrlFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikSelect
                    label={t("ad.category")}
                    name="category"
                    error={errors.category && !values.category ? errors.category : false}
                    value={values.category}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={touchedData}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("ad.website")}
                    name="websiteLink"
                    // disabled={mode === "view"}
                    error={errors.websiteLink && !values.websiteLink ? errors.websiteLink : false}
                    value={values.websiteLink}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  // OnClick={() => window.location.href = "www.google.com"}
                  />
                </FormGroup>
                <FormGroup className="col-6">
                  <div className="col-12 px-4 mt-4">
                    <FormikCheckbox
                      name="both"
                      label={t("ad.both")}
                      value={values.both}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </div>
                </FormGroup>
                {
                  mode === 'view' ?
                    data.announcementImage &&
                    <div className="col-12 my-4 text-center">
                      <div className="display-inline-block mr-xs mb-xs" >
                        <img className="col-5" alt="..." src={mode !== 'add' ? data.announcementImage : ''} width={100} />
                      </div>
                    </div>
                    :
                    mode === 'edit' ?
                      <div className="col-12 my-4 text-center">
                        <div className="col-12 my-4 text-center">
                          <div className="display-inline-block mr-xs mb-xs backgroundImage" >
                            {
                              data.announcementImage &&
                              <img className="col-5" alt="..." src={data.announcementImage} width={100} />
                            }
                            <FormGroup className="col-12 my-4 text-center">
                              <FileUpload
                                name="announcementImage"
                                type="image/jpeg,image/png"
                                profile
                                label={t("img.update")}
                                disabled={mode === "view"}
                                error={touched.companyLogo && errors.companyLogo}
                                value={values.announcementImage}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                              />
                            </FormGroup>
                          </div>
                        </div>
                      </div>
                      :
                      <FormGroup className="col-6 text-center">
                        <FileUpload
                          name="announcementImage"
                          type="image/jpeg,image/png"
                          className="col-12"
                          profile
                          label={t("img.upload")}
                          disabled={mode === "view"}
                          error={touched.companyLogo && errors.companyLogo}
                          value={values.announcementImage}
                          onChange={setFieldValue}
                          onBlur={setFieldTouched}
                        />
                      </FormGroup>
                }
                {mode !== 'view' &&
                  <div className="col-12 d-flex my-4">
                    <Button type="submit" color="success" className="m-auto">
                      {mode === 'edit' ? t("price.update") : t("price.add")}
                    </Button>
                  </div>
                }
              </Form>
            )}
          </Formik>
        </div>
      </div >
    );
  }
}
