import React, { Component } from 'react'
import { toast } from "react-toastify";
import { Modal, ModalBody, ModalHeader, Button } from "reactstrap";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import FaqForm from "./FaqForm";
import Alert from "../../../../components/Alert";
import { progress } from "../../../../redux/user/actions";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
export default class FaqList extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      edit_row_id: "",
      totalCount: 10,
      size: 5,
      page: 1,
      total_page: 1,
      progress: '',
      loader: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, size } = this.state;
    apiClient.get('faq/get-faq/company', { params: { page, size } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 5 ? 5 : size;
        this.setState({
          page,
          size,
          data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      }
    });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  handleDelete = (id) => {
    apiClient.delete("faq/delete-faq/" + id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
  };

  handleSubmit = (data) => {
    const { mode } = this.state
    var bodyData = {
      question: data.question,
      questionInFr: data.questionInFr,
      answer: data.answer,
      answerInFr: data.answerInFr,
    };
    const rowData = this.state.edit_row
    var form_data = new FormData();
    form_data.append("question", data.question);
    form_data.append("role", 'company');
    form_data.append("questionInFr", data.questionInFr);
    form_data.append("answer", data.answer);
    form_data.append("answerInFr", data.answerInFr);
    if (data.answerImage && data.answerImage.length > 0) {
      form_data.append("answerVideo", data.answerImage[0]);
    } else {
      form_data.append("answerVideo", data.answerVideo[0]);
    }
    if (data.answerImageInFr && data.answerImageInFr.length > 0) {
      form_data.append("answerVideoInFr", data.answerImageInFr[0]);
    } else {
      form_data.append("answerVideoInFr", data.answerVideoInFr[0]);
    }
    var options = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        let percent = Math.round((loaded * 100) / total)
        this.setState({
          progress: percent
        })
        console.log(`${loaded} kb of ${total} kb | ${percent}%`)
      }
    }
    if (mode === "add") {
      apiClient.post("faq/create-faq", form_data,
        options,
        this.setState({
          loader: true
        }),
        progress(true)
      ).then(({ status }) => {
        this.setState({ loader: false })
        if (status === 200) {
          progress(false)
          this.getData();
          toast.success("Created Successfully")
        }
      })
    } else {
      apiClient.put("faq/update-faq/" + data._id, form_data,
        options,
        this.setState({
          loader: true
        }),
        progress(true)
      ).then(({ status }) => {
        this.setState({ loader: false })
        if (status === 200) {
          progress(false)
          this.getData();
          toast.success("Updated Successfully")
        }
      });
    }
    this.toggleModal();
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };

  render() {
    const { mode, edit_row, data, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;
    return (
      <>
        {this.state.loader ?
          <div className="progressBar">
            <CircularProgressbar value={this.state.progress} text={`${this.state.progress}%`} />
          </div>
          :
          <>
            <Modal className="course-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
              <ModalHeader toggle={() => this.toggleModal()}>
                {mode
                  ? mode === "add"
                    ? "Add New " + label
                    : mode === "edit"
                      ? t("ad.edit") + " " + label
                      : t("ad.view") + " " + label
                  : ""}
              </ModalHeader>
              <ModalBody>
                <div className="fields">
                  {mode && (
                    <FaqForm
                      data={edit_row}
                      mode={mode}
                      title="Add Faq"
                      onSubmit={this.handleSubmit}
                      t={t}
                    />
                  )}
                </div>
              </ModalBody>
            </Modal>
            <CardList
              containerClassName="mx-5 mb-5 mt-4"
              title={label}
              subHeaderComponent={
                <Button className="ml-auto" color="success" onClick={() => this.toggleModal("add")}>
                  {"+ " + t("price.add")}
                </Button>
              }
              column={[
                {
                  title: "question",
                  field: "question",
                  // render: (rowData) => <span className={clsx("paymentId", rowData.name)}>{rowData.name}</span>
                },
                {
                  title: t("answer"),
                  field: "answer",
                  // render: (rowData) => <span className={clsx("transaction_date", rowData.module)}>{rowData.module}</span>
                },
                // {
                //   title: t("pay.type"),
                //   field: "type",
                //   // render: (rowData) => <span className={clsx("duration", rowData.module)}>{rowData.module}</span>
                // },
              ]}
              data={data}
              actions={[
                {
                  icon: "fi flaticon-view-1",
                  tooltip: "View",
                  className: "btn-white text-primary p-0",
                  onClick: (rowData) => this.toggleModal("view", rowData)
                },
                {
                  icon: "fi flaticon-edit",
                  className: "btn-white text-primary p-0",
                  onClick: (rowData) => this.toggleModal("edit", rowData)
                },
                {
                  icon: "fi flaticon-delete-1",
                  className: "btn-white text-danger p-0",
                  onClick: (rowData) =>
                    Alert.info(
                      t("ct.deleteTitle"),
                      t('ct.msg'),
                      () => this.handleDelete(rowData._id)
                    )
                }
              ]}
              handlePageChange={this.onPageChange}
              options={{
                subHeader: true,
                isCollapsable: true,
                expandActions: true,
                pagination: {
                  showPagination: true,
                  pageSelection: true,
                  total_page,
                  totalCount,
                  page,
                  size
                }
              }}
            />
          </>
        }
      </>
    );
  }
}
