import React, { useState } from "react";
import UserList from './UserList'
import TabNav from '../../../../components/TabNav'
import Tab from '../../../../components/Tab'
import { useTranslation } from "react-i18next";
import Approval from "../../Company/approval/Approval";

export default function UserProfile({ heading }) {
  const { t } = useTranslation();
  const [selected, setselected] = useState(t("approval.student"))

  const setSelected = (tab) => {
    setselected(tab)
  }
  return (
    <div className="subscription-management">
      <span className="page-header">{t("approval.request")}</span>
      <TabNav tabs={[t("approval.company"), t("approval.student"),]} selected={selected} setSelected={setSelected} color={'white'}>
        <Tab isSelected={selected === t("approval.company")}>
          <Approval type="terminals" label={t("approval.company")} t={t} />
        </Tab>
        <Tab isSelected={selected === t("approval.student")}>
          <UserList type="terminals" label={t("approval.student")} t={t} />
        </Tab>
      </TabNav>
    </div>
  );
}
