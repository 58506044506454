import React, { useState, useEffect } from "react";
import QuizQuestionList from "./QuizQuestionList";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { history } from "../../../../utils/Utils";

export default function Lessons({ heading, ...props }) {
  const courseId = props.match.params.id;
  const { t } = useTranslation();
  const [page, setpage] = useState('')
  useEffect(() => {
    const pageNumber = localStorage.getItem('page')
    setpage(`/app/course/${pageNumber}`)
  }, [])
  return (
    <div className="subscription-management">
      <span className="page-header"><span className="course-back" onClick={() => history.push(page)}>{t("course.title")}</span> / {t("quiz.title")}</span>
      <QuizQuestionList type="terminals" label={t("quiz.title")} courseId={courseId} t={t} />
    </div>
  );
}
