import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikSelect } from "../../../components/FormikFields";
import { categoryData, categoryDataFr } from "../../../utils/Config";
import { categorySchema } from "../../../utils/validationSchema";
import i18n from "../../../i18n";

export default class CategoryForm extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const { data, onSubmit, mode, t } = this.props;
    const language = i18n.language
    return (
      <div className="add-counter">
        <div className="fields col-11 mx-auto">
          <Formik
            validationSchema={categorySchema}
            enableReinitialize
            initialValues={data}
            onSubmit={onSubmit}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <>
                  <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                  <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={t("ct.category")}
                      name="name"
                      disabled={mode === "view"}
                      error={touched.name && errors.name}
                      value={values.name}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label="Catégorie"
                      name="nameinFr"
                      disabled={mode === "view"}
                      error={errors.nameinFr && !values.nameinFr ? errors.nameinFr : false}
                      value={values.nameinFr}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 px-4 text-center">
                    <FormikSelect
                      label={t("ct.module")}
                      name="module"
                      // disabled={mode === "view" || mode === 'edit'}
                      duration
                      error={errors.module && !values.module ? errors.module : false}
                      value={values.module}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                      options={language == 'en' ? categoryData : categoryDataFr}
                    />
                  </FormGroup>
                </>
                {mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button
                      type="submit"
                      color="success"
                      className="my-4 mx-auto"
                    >
                      {mode === "edit" ? t("price.update") : t("ct.add")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}
