import React from "react";
import CampaignStudentList from './CampaignStudentList'
import { useTranslation } from "react-i18next";

export default function Compaign({ heading }) {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("student.Student")}</span>
      <CampaignStudentList type="terminals" label={t("student.Student")} t={t} />
    </div>
  );
}
