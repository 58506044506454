import React, { Component } from "react";
import { NavItem, Nav, Collapse } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import { Badge } from '@material-ui/core'

class MenuNavItem extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  componentDidMount() {
    const {
      children,
      to,
      location: { pathname },
    } = this.props;
    this.setState({
      isOpen: pathname === to || Boolean(children && children.find((item) => item.to === pathname)),
    });
  }

  componentDidUpdate(_prevProps, prevState) {
    const {
      children,
      to,
      location: { pathname },
    } = this.props;
    if (prevState === this.state) {
      this.setState({
        isOpen: pathname === to || Boolean(children && children.find((item) => item.to === pathname)),
      });
    }
  }

  setToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { name, toggle, icon, children, to , count } = this.props;
    const { isOpen } = this.state;
    return children && children.length > 0 ? (
      <>
        <div className={clsx("nav-link pointer d-flex", isOpen && "expand")} onClick={this.setToggle}>
          <i className={clsx(icon, "my-auto")} />
          <div className="text">{name}</div>
        </div>
        <Collapse isOpen={isOpen}>
          <Nav vertical>
            {children.map((item, i) => (
              <NavItem key={i}>
                <NavLink className="nav-link" to={item.to} onClick={toggle}>
                  <div className="text">{item.badge == true ? <Badge className="badges" badgeContent={item.count} color="primary"> {item.name} </Badge> : <span>{item.name}</span>}  </div>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </>
    ) : (
        <NavLink className="nav-link d-flex" to={to} onClick={toggle}>
          <i className={clsx(icon, "my-auto")} />
          <div className="text">{name}</div>
        </NavLink>
      );
  }
}

export default withRouter(MenuNavItem);
