import { Form, Formik } from "formik";
import React from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikSelect } from "../../../../components/FormikFields";
import { customUserFilterSchema } from "../../../../utils/validationSchema";
import { areaOptions, typeOptions } from "../../../../utils/Config";

function FilterCustomCampaign({ onSubmit, t }) {
  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik
          enableReinitialize
          initialValues={{ area: "", type: "" }}
          validationSchema={customUserFilterSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("area")}
                  name="area"
                  defaultValue=""
                  className="react-select"
                  classNamePrefix="react-select"
                  value={values.area || ""}
                  onChange={setFieldValue}
                  error={touched.area && errors.area}
                  onBlur={setFieldTouched}
                  options={areaOptions}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikSelect
                  label={t("type")}
                  defaultValue=""
                  name="type"
                  className="react-select"
                  classNamePrefix="react-select"
                  value={values.type || ""}
                  onChange={setFieldValue}
                  error={touched.type && errors.type}
                  onBlur={setFieldTouched}
                  options={typeOptions}
                />
              </FormGroup>
              <div className="col-12 d-flex my-4">
                <Button type="submit" color="success" className="my-4 mx-auto">
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default FilterCustomCampaign;
