import React from "react";
import AnnounceMent from './CompanyStudList'
import HeaderCard from '../../../../components/HeaderCards'
import { useTranslation } from "react-i18next";

export default function Announcements({ heading }) {
  const { t } = useTranslation();

  return (
    <div className="subscription-management">
      <span className="page-header">{t("company.announcement")}</span>
      <AnnounceMent type="terminals" label={t("company.announcement")} t={t} />
    </div>
  );
}
