import { Form, Formik } from "formik";
import React from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput, FormikCheckbox } from "../../../../components/FormikFields";
import { blockSchema } from "../../../../utils/validationSchema";

export default function ScreenAccessForm({ data, onSubmit, mode, t }) {
  return (
    <div className="add-terminal">
      <div className="fields col-11 mx-auto">
        <Formik
          enableReinitialize
          initialValues={data}
          validationSchema={blockSchema}
          onSubmit={onSubmit}>
          {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
            <Form className="row">
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.companyName")}
                  name="companyName"
                  disabled
                  value={values.companyName}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.email")}
                  name="email"
                  disabled
                  value={values.email}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.address")}
                  name="addressLine1"
                  disabled
                  value={values.addressLine1}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.city")}
                  name="city"
                  disabled
                  value={values.city}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.zipCode")}
                  name="zipCode"
                  disabled
                  value={values.zipCode}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
              <FormGroup className="col-6 px-4">
                <FormikInput
                  label={t("approval.state")}
                  name="state"
                  disabled
                  value={values.state}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </FormGroup>
                <FormGroup className="col-12 px-4">
                  <div className="col-12 mt-3">
                    <FormikCheckbox
                      name="isScreenRecording"
                      label={t("screenRecord")}
                      value={values.isScreenRecording}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </div>
                </FormGroup>
              {mode !== 'view' &&
                <div className="col-12 d-flex my-4">
                  <Button type="submit" color="success" className="m-auto">
                    {t("course.update")}
                  </Button>
                </div>
              }
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

