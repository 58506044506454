import clsx from "clsx";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Alert from "../../../components/Alert";
import CardList from "../../../components/CardList";
import apiClient from "../../../utils/apiClient";
import CategoryForm from "./CategoryForm";
import i18n from "../../../i18n";

export default class Category extends Component {
  constructor() {
    super();
    this.state = {
      mode: "",
      data: [],
      edit_row: "",
      edit_row_id: "",
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
      orderOnField: null,
      isOrderByAscending: false
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { page, size, orderOnField, isOrderByAscending } = this.state;
    apiClient.get('category/get-allCategory', { params: { page, size, orderOnField, isOrderByAscending } }).then(({ status, data: { page, size, data, totalCount } }) => {
      if (status === 200) {
        size = size < 10 ? 10 : size;
        this.setState({
          page,
          size,
          data,
          totalCount,
          total_page: Math.ceil(totalCount / size)
        });
      }
    });
  };

  toggleModal = (mode = "", edit_row = { name: "", status: "Active" }) => {
    this.setState({ mode, edit_row });
  };

  handleSubmit = (data) => {
    const { mode } = this.state
    var bodyData = {
      name: data.name,
      nameinFr: data.nameinFr,
      module: data.module,
    };
    if (mode === "add") {
      apiClient.post("category/create-category", bodyData).then(({ status }) => {
        if (status === 200) {
          this.getData();
          toast.success("Category Added Successfully");
        }
      })
    } else {
      apiClient.put("category/update-category/" + data._id, bodyData,).then(({ status }) => {
        if (status === 200) {
          this.getData();
          toast.success("Category Updated Successfully");
        }
      });
    }
    this.toggleModal();
  };

  handleDelete = (id) => {
    apiClient.delete("category/delete-category/" + id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
  };

  onPageChange = (page, size) => {
    this.setState({ page, size }, () => this.getData());
  };

  handleSortingChange = (keyName, isAscendingOrder) => {
    this.setState({
      orderOnField: keyName,
      isOrderByAscending: isAscendingOrder
    }, () => {
      this.getData()
    })
  }

  render() {
    const { mode, edit_row, data, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;
    return (
      <>
        <Modal className="counters-modal" isOpen={Boolean(mode)} toggle={() => this.toggleModal()}>
          <ModalHeader toggle={() => this.toggleModal()}>
            {mode
              ? mode === "add"
                ? "Add New " + label
                : mode === "edit"
                  ? t("ad.edit") + " " + label
                  : t("ad.view") + " " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">
              {mode && (
                <CategoryForm
                  data={edit_row}
                  mode={mode}
                  title="Add new counter"
                  onSubmit={this.handleSubmit}
                  t={t}
                />
              )}
            </div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          subHeaderComponent={
            <Button className="ml-auto" color="success" onClick={() => this.toggleModal("add")}>
              {"+ " + t("price.add")}
            </Button>
          }
          column={[
            {
              title: t("ct.category"),
              field: i18n.language === 'en' ? "name" : "nameinFr",
              render: (rowData) => <span className={clsx("adCategory", rowData.name)}>{rowData.name}</span>
            },
            {
              title: t("ct.module"),
              field: "module",
              render: (rowData) => <span className={clsx("duration", rowData.module)}>{rowData.module}</span>
            },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              tooltip: "View",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("view", rowData)
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData)
            },
            {
              icon: "fi flaticon-delete-1",
              className: "btn-white text-danger p-0",
              onClick: (rowData) =>
                Alert.info(
                  t("ct.deleteTitle"),
                  t('ct.msg'),
                  () => this.handleDelete(rowData._id)
                )
            }
          ]}
          handlePageChange={this.onPageChange}
          handleSortingChange={this.handleSortingChange}
          options={{
            subHeader: true,
            isCollapsable: true,
            expandActions: true,
            pagination: {
              showPagination: true,
              pageSelection: true,
              total_page,
              totalCount,
              page,
              size
            }
          }}
        />
      </>
    );
  }
}
