import CryptoJS from "crypto-js";
import { createBrowserHistory } from "history";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isNullOrUndefined } from "util";
import * as Yup from "yup";


export const history = createBrowserHistory();

const encyrpt = (value) =>
  process.env.NODE_ENV === "development"
    ? value
    : CryptoJS.AES.encrypt(value, process.env.REACT_APP_CRYPT_SALT).toString();

const decyrpt = (value) =>
  process.env.NODE_ENV === "development"
    ? value
    : CryptoJS.AES.decrypt(value, process.env.REACT_APP_CRYPT_SALT).toString(CryptoJS.enc.Utf8);

export const setLocalStorage = (key, value) => {
  if (isNullOrUndefined(value) || value === "") localStorage.removeItem(key);
  else localStorage.setItem(key, encyrpt(JSON.stringify(value)));
};

export const getLocalStorage = (key) => {
  try {
    return decyrpt(localStorage.getItem(key)) && JSON.parse(decyrpt(localStorage.getItem(key)));
  } catch (e) {
    if (["userData", "token"].includes(key)) clearLocalStorage();
    else localStorage.removeItem(key);
  }
};

export const setSessionStorage = (key, value) => {
  if (isNullOrUndefined(value) || value === "") sessionStorage.removeItem(key);
  else sessionStorage.setItem(key, encyrpt(JSON.stringify(value)));
};

export const getSessionStorage = (key) => {
  try {
    return decyrpt(sessionStorage.getItem(key)) && JSON.parse(decyrpt(sessionStorage.getItem(key)));
  } catch (e) {
    sessionStorage.removeItem(key);
  }
};

export const clearLocalStorage = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("userData");
};

String.prototype.pick = function (min, max) {
  var n,
    chars = "";

  if (typeof max === "undefined") {
    n = min;
  } else {
    n = min + Math.floor(Math.random() * (max - min + 1));
  }

  for (var i = 0; i < n; i++) {
    chars += this.charAt(Math.floor(Math.random() * this.length));
  }

  return chars;
};

String.prototype.shuffle = function () {
  var array = this.split("");
  var tmp,
    current,
    top = array.length;

  if (top)
    while (--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }

  return array.join("");
};

// export const generatePassword = () => {
//   var specials = "!@#$%^&*";
//   var lowercase = "abcdefghijklmnopqrstuvwxyz";
//   var uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
//   var numbers = "0123456789";

//   var all = specials + lowercase + uppercase + numbers;

//   var password = "";
//   password += specials.pick(1);
//   password += lowercase.pick(1);
//   password += uppercase.pick(1);
//   password += numbers.pick(1);
//   password += all.pick(3, 10);
//   password = password.shuffle();
//   return password;
// };

//Password validation
// Yup.addMethod(Yup.string, "password", function (message) {
//   return this.test(
//     "password",
//     message ||
//       "Password must be min 8 characters, and contain atleast one or more Special Character, Uppercase, Number and Lowercase",
//     function (value) {
//       return value ? /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) : true;
//     }
//   );
// });

//mobile no validation
Yup.addMethod(Yup.string, "mobile_no", function (message) {
  return this.test(
    "mobile_no",
    message || "Invalid Mobile Number (Enter phone no with code like +919787850895)",
    function (value) {
      return value ? isValidPhoneNumber(value) : true;
    }
  );
});

//number validation
Yup.addMethod(Yup.number, "number", function (message) {
  return this.test("number", message || "Value must be number", function (value) {
    return value ? /^[0-9]*$/.test(value) : true;
  });
});

