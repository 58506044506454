import clsx from 'clsx';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
class Media extends Component {
  constructor() {
    super();
    this.state = {
      element: null,
    };
  }

  async UNSAFE_componentWillMount() {
    const { file, id, removeFile } = this.props;
    console.log(file.type);
    var element = null;
    if (file.type.startsWith('image')) {
      var reader = new FileReader();
      reader.onload = (e) => {
        var src = e.target.result;
        element = (
          <div className="dropzone-thumbnail">
            {removeFile &&
              {
                /* <span className="remove" onClick={() => removeFile(id)}>
                <span>s</span>
              </span> */
              }}
            <img src={src} width="300" alt={file.name} />
          </div>
        );
        this.setState({ element });
      };
      reader.readAsDataURL(file);
    } else if (file.type.startsWith('video')) {
      var src = URL.createObjectURL(new Blob([file]));
      element = (
        <div className="dropzone-thumbnail">
          {removeFile &&
            {
              /* <span className="remove">
              <span onClick={() => removeFile(id)}>s</span>
            </span> */
            }}
          <video height="120">
            <source src={src} type={file.type} />
            Your browser does not support the video tag.
          </video>
        </div>
      );
      this.setState({ element });
    } else if (file.type.startsWith('application' || 'video' || 'image')) {
      var src = URL.createObjectURL(new Blob([file]));
      element = (
        <div className="dropzone-thumbnail">
          {removeFile &&
            {
              /* <span className="remove">
              <span onClick={() => removeFile(id)}>x</span>
            </span> */
            }}
          {console.log(file.type)}
          {console.log(src)}
          <iframe height="120">
            <source src={src} type={file.type} />
            Your browser does not support the video tag.
          </iframe>
        </div>
      );
      this.setState({ element });
    }
    // }
    else {
      element = (
        <li className="w-100 list-group-item mt-2 d-flex align-items-center list-group-item-success">
          <span>{file.name}</span>
          <button type="button" className="close ml-auto" onClick={() => removeFile(id)}>
            <span>&times;</span>
          </button>
        </li>
      );
      this.setState({ element });
    }
  }

  render() {
    const { element } = this.state;
    return element;
  }
}

class FileUpload extends Component {
  onDrop = (files) => {
    var { name, value, multiple, onChange } = this.props;
    if (multiple) value = value.concat(files);
    else value = files;
    onChange(name, value);
  };

  removeFile = (i) => (e) => {
    var { name, value, onChange } = this.props;
    value.splice(i, 1);
    onChange(name, value);
  };

  render() {
    const {
      value = [],
      error,
      maxSize,
      disabled,
      type,
      profile,
      label,
      multiple = false,
      className,
      thumbnail,
      listClassName,
    } = this.props;
    return (
      <>
        <Dropzone
          disabled={disabled}
          onDrop={this.onDrop}
          accept={type}
          maxSize={maxSize || 104857600}
          multiple={multiple}
        >
          {({ getRootProps, getInputProps, isDragActive, isDragReject, fileRejections }) => {
            const fileSize = fileRejections.map((item) => item.file.size > maxSize);

            const isFileTooLarge = fileSize + '';
            return (
              <div className={clsx('dropzone text-center', className)} {...getRootProps()}>
                <input {...getInputProps()} />

                <div className={clsx('bg-light p-3 border rounded', !disabled && 'pointer')}>
                  {profile && value && value.length > 0 ? (
                    <>
                      <Media key={value[0].lastModified} file={value[0]} />
                    </>
                  ) : (
                    <>
                      <center>
                        <i
                          className={clsx(
                            'fi flaticon-images-square-outlined-interface-button-symbol',
                            error && 'text-danger'
                          )}
                        />
                      </center>
                      {!isDragActive && label && (
                        <label className={clsx(!disabled && 'pointer', error && 'text-danger')}>{label}</label>
                      )}
                    </>
                  )}
                  {isDragActive && !isDragReject && 'Drop it!'}
                  {isDragReject && 'File type not accepted, sorry!'}
                  {isFileTooLarge && <div className="text-danger mt-2">File is too large.</div>}
                </div>
                <br />
              </div>
            );
          }}
        </Dropzone>
        {!profile ||
          (profile &&
            (thumbnail ? (
              <ul className={clsx('row mt-2 list-group justify-content-center', listClassName)}>
                {value.length > 0 &&
                  value.map((acceptedFile, i) => (
                    <Media file={acceptedFile} key={acceptedFile.name + i} removeFile={this.removeFile(i)} />
                  ))}
              </ul>
            ) : (
              <ul className={clsx('list-group justify-content-center', listClassName)}>
                {value &&
                  value.length > 0 &&
                  value.map((acceptedFile, i) => (
                    <li
                      key={acceptedFile.name + i}
                      className="list-group-item mt-2 d-flex align-items-center list-group-item-success"
                    >
                      <span>{acceptedFile.name}</span>
                      <button type="button" className="close ml-auto" onClick={this.removeFile(i)}>
                        <span>&times;</span>
                      </button>
                    </li>
                  ))}
              </ul>
            )))}
        {error && <div className="invalid-feedback">{error}</div>}
      </>
    );
  }
}

export default FileUpload;
