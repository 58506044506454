import { Form, Formik } from "formik";
import React, { Component } from "react";
import { Button, FormGroup } from "reactstrap";
import { FormikInput } from "../../../../components/FormikFields";
import FileUpload from '../../../../components/FileUpload'
export default class FaqForm extends Component {
  constructor() {
    super();
    this.state = {
      answerVideo: [],
      answerVideoInFr: [],
      answerImage: [],
      answerImageInFr: []
    };
  }

  render() {
    const { data, onSubmit, mode, t } = this.props;
    const { answerVideo, answerVideoInFr, } = this.state
    return (
      <div className="add-counter">
        <div className="fields col-11 mx-auto">
          <Formik
            enableReinitialize
            initialValues={{ ...data, answerVideo, answerVideoInFr } || {
              question: '',
              questionInFr: '',
              answerInFr: '',
              answer: '',
              answerVideo: '',
              answerVideoInFr: '',
            }}
            onSubmit={onSubmit}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <>
                  <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                  <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={"Question"}
                      name="question"
                      disabled={mode === "view"}
                      error={touched.question && errors.question}
                      value={values.question}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label="QuestionInFr"
                      name="questionInFr"
                      disabled={mode === "view"}
                      error={errors.questionInFr && !values.questionInFr ? errors.questionInFr : false}
                      value={values.questionInFr}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={t("answer")}
                      name="answer"
                      disabled={mode === "view"}
                      error={errors.answer && !values.answer ? errors.answer : false}
                      value={values.answer}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-6 px-5">
                    <FormikInput
                      label={t("answer")}
                      name="answerInFr"
                      disabled={mode === "view"}
                      error={errors.answerInFr && !values.answerInFr ? errors.answerInFr : false}
                      value={values.answerInFr}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </FormGroup>
                  <FormGroup className="col-12 px-5 text-center">{t("faq-video")}</FormGroup>
                  {mode == 'view' ?
                    <FormGroup className="col-12 px-4">
                      {t("announcement.videoUrl")}
                      <br />{" "}
                      <a href={data.answerVideo}>
                        {data.answerVideo && data.answerVideo != "undefined" ? (
                          data.answerVideo
                        ) : (
                          <b>{t("announcement.document")}</b>
                        )}
                      </a>
                    </FormGroup> :
                    mode == 'edit' ?
                      <>
                        <FormGroup className="col-12 px-4">
                          {t("announcement.videoUrl")}
                          <br />{" "}
                          <a href={data.answerVideo}>
                            {data.answerVideo && data.answerVideo != "undefined" ? (
                              data.answerVideo
                            ) : (
                              <b>{t("announcement.document")}</b>
                            )}
                          </a>
                        </FormGroup>
                        <FormGroup className="col-12 px-4">
                          {t("announcement.videoUrl")}
                          <br />{" "}
                          <a href={data.answerVideoInFr}>
                            {data.answerVideoInFr && data.answerVideoInFr != "undefined" ? (
                              data.answerVideoInFr
                            ) : (
                              <b>{t("announcement.document")}</b>
                            )}
                          </a>
                        </FormGroup>
                        <FormGroup className="col-6 my-4 text-center">
                          <FileUpload
                            name="answerImage"
                            type="image/*"
                            profile
                            className="profile-image"
                            label={t("img.update")}
                            disabled={mode === "view"}
                            value={values.answerImage}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                        <FormGroup className="col-6 my-4 text-center">
                          <FileUpload
                            name="answerImageInFr"
                            type="image/*"
                            profile
                            label={t("img.update")}
                            disabled={mode === "view"}
                            value={values.answerImageInFr}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                      </> :
                      <>
                        <FormGroup className="col-6 my-4 text-center">
                          <FileUpload
                            name="answerImage"
                            type="image/*"
                            profile
                            label={t("img.update")}
                            disabled={mode === "view"}
                            value={values.answerImage}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                        <FormGroup className="col-6 my-4 text-center">
                          <FileUpload
                            name="answerImageInFr"
                            type="image/*"
                            profile
                            label={t("img.update")}
                            disabled={mode === "view"}
                            value={values.answerImageInFr}
                            onChange={setFieldValue}
                            onBlur={setFieldTouched}
                          />
                        </FormGroup>
                      </>
                  }
                  {
                    mode == 'view' ?
                      <FormGroup className="col-12 px-4">
                        {t("announcement.videoUrl")}
                        <br />{" "}
                        <a href={data.answerVideoInFr}>
                          {data.answerVideoInFr && data.answerVideoInFr != "undefined" ? (
                            data.answerVideoInFr
                          ) : (
                            <b>{t("announcement.document")}</b>
                          )}
                        </a>
                      </FormGroup>
                      :
                      mode == 'edit' ?
                        <>
                          <FormGroup className="col-6 my-4 text-center">
                            <FileUpload
                              name="answerVideo"
                              type="video/*"
                              profile
                              label={t("quiz.uploadVideo")}
                              disabled={mode === "view"}
                              value={values.answerVideo}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                            />
                          </FormGroup>
                          <FormGroup className="col-6 my-4 text-center">
                            <FileUpload
                              name="answerVideoInFr"
                              type="video/*"
                              profile
                              label={t("quiz.uploadVideo")}
                              disabled={mode === "view"}
                              value={values.answerVideoInFr}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                            />
                          </FormGroup>
                        </> :
                        <>
                          <FormGroup className="col-6 my-4 text-center">
                            <FileUpload
                              name="answerVideo"
                              type="video/*"
                              profile
                              label={t("quiz.uploadVideo")}
                              disabled={mode === "view"}
                              value={values.answerVideo}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                            />
                          </FormGroup>
                          <FormGroup className="col-6 my-4 text-center">
                            <FileUpload
                              name="answerVideoInFr"
                              type="video/*"
                              profile
                              label={t("quiz.uploadVideo")}
                              disabled={mode === "view"}
                              value={values.answerVideoInFr}
                              onChange={setFieldValue}
                              onBlur={setFieldTouched}
                            />
                          </FormGroup>
                        </>
                  }
                </>
                {mode !== "view" && (
                  <div className="col-12 d-flex my-4">
                    <Button
                      type="submit"
                      color="success"
                      className="my-4 mx-auto"
                    >
                      {mode === "edit" ? t("price.update") : t("ct.add")}
                    </Button>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

