import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// import Students from "./Students/index";
import Courses from './Students/Courses/index'
import Lesson from './Students/Lesson'
import QuizQuestions from './Students/Quiz'
import Users from './Students/UserProfile/index'
import Block from './Students/Block/index'
import AnnounceMent from './Students/CompanyAndStud/index'
import JobOffers from "./Company/jobOffers/index";
import AdUpdateForm from "./Company/UpdatePrice/index";
import UsersList from "./Company/Users/index";
import Ad from "./Company/Ads/index";
import CompanyStudListC from "./Company/CompanyAndStud/index";
import Category from './Category/index'
import PaymentHistory from "./PaymentHistory/index";
import Faq from './Faq/index'
import Price from './Price/index'
import ChangePassword from '../Admin/ChangePassword'
import { useTranslation } from "react-i18next";
import UpdateVersion from "./UpdateVersion";
import CampaignStudent from "./Campaigns/Student";
import CampaignCompany from "./Campaigns/Company";
import CampaignCustom from './Campaigns/Custom'
import Project from './Students/Projects'

export default function Admin({ url }) {
  const { t } = useTranslation();
  return (
    <Switch>
      <Route exact path={`${url}/job-offers`} render={(e) => <JobOffers heading="Job Offers" {...e} />} />
      <Route exact path={`${url}/prices`} render={(e) => <AdUpdateForm heading="Update Price" {...e} />} />
      <Route exact path={`${url}/block-company`} render={(e) => <UsersList heading="Companies" {...e} />} />
      <Route exact path={`${url}/addvertisement`} render={(e) => <Ad heading="Advertisement" {...e} />} />
      <Route exact path={`${url}/announcement-company`} render={(e) => <CompanyStudListC heading="Announcements" {...e} />} />
      <Route
        exact
        path={`${url}/course/:page`}
        render={(e) => <Courses heading="Courses" {...e} />}
      />
      <Route
        exact
        path={`${url}/course/:page/lesson/:id`}
        render={(e) => <Lesson heading="Lesson" {...e} />}
      />
      <Route
        exact
        path={`${url}/course/:page/quiz-questions/:id`}
        render={(e) => <QuizQuestions heading="Quiz Questions" {...e} />}
      />
      {/* <Route
        exact
        path={`${url}/approval`}
        render={(e) => <Users heading="Approval Request" {...e} />}
      /> */}
      <Route
        exact
        path={`${url}/block-students`}
        render={(e) => <Block heading="Students" {...e} />}
      />
      <Route
        exact
        path={`${url}/announcement-students`}
        render={(e) => <AnnounceMent heading="AnnounceMents" {...e} />}
      />
      <Route
        exact
        path={`${url}/announcement`}
        render={(e) => <AnnounceMent heading="AnnounceMents" {...e} />}
      />
      <Route
        exact
        path={`${url}/category`}
        render={(e) => <Category heading="Category" {...e} />}
      />
      <Route
        exact
        path={`${url}/payment-history`}
        render={(e) => <PaymentHistory heading="Category" {...e} />}
      />
      <Route
        exact
        path={`${url}/faq-admin`}
        render={(e) => <Faq heading="Category" {...e} />}
      />
      <Route
        exact
        path={`${url}/price-productId`}
        render={(e) => <Price heading="Price" {...e} />}
      />
      <Route
        exact
        path={`${url}/change-password`}
        render={(e) => <ChangePassword heading="Change Password" {...e} t={t} />}
      />
      <Route
        exact
        path={`${url}/update-version`}
        render={(e) => <UpdateVersion heading="Update Version" {...e} t={t} />}
      />
      <Route
        exact
        path={`${url}/campaign-student`}
        render={(e) => <CampaignStudent heading="Compaign " {...e} t={t} />}
      />
      <Route
        exact
        path={`${url}/campaign-company`}
        render={(e) => <CampaignCompany heading="Compaign " {...e} t={t} />}
      />

      <Route
        exact
        path={`${url}/campaign-custom`}
        render={(e) => <CampaignCustom heading="Compaign " {...e} t={t} />}
      />

      <Route
        exact
        path={`${url}/projects`}
        render={(e) => <Project heading="Projects " {...e} t={t} />}
      />

    </Switch>
  );
}
