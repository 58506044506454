import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { setUserData } from "../redux/user/actions";
import { history } from "../utils/Utils";
import { withTranslation } from "react-i18next";
import "../i18n";
import i18n from "../i18n";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
class UserBar extends Component {
  constructor() {
    super();
    this.state = {
      selectedLanguage: i18n.language ? i18n.language : 'en'
    }
  }

  handleLogout = async (e) => {
    e.preventDefault();
    setUserData();
    toast.success("LoggedOut Successfully");
    history.push("/");
  };

  openProfile = () => {
    history.replace("/app/profile");
  };

  onChangePassword = () => {
    history.push("/app/change-password");
  }

  onUpdateVersion = () => {
    history.push("/app/update-version");
  }

  componentDidMount() {
    localStorage.setItem('selectedLanguage', 'en')
    i18n.changeLanguage('en');
  }

  render() {
    const { userData, i18n, t } = this.props;
    const handleChange = (e) => {
      this.setState({
        selectedLanguage: e.target.value
      })
      localStorage.setItem('selectedLanguage', e.target.value)
      i18n.changeLanguage(e.target.value);
      window.location.reload();
    };

    return (
      <div className="user-bar">
        <div className="right">
          <FormControl style={{ marginRight: "50px" }}>
            <InputLabel id="demo-simple-select-label">Language</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              onChange={handleChange}
              value={this.state.selectedLanguage}
            >
              <MenuItem>Select</MenuItem>
              <MenuItem value="en">English</MenuItem>
              <MenuItem value="fe">French</MenuItem>
            </Select>
          </FormControl>
          <nav className="navbar navbar-dark navbar-expand-sm">
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-list-4" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbar-list-4">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <AccountCircleIcon color="primary" style={{ fontSize: "44px" }} />
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <span className="dropdown-item" onClick={this.onChangePassword}>{t("password.button")}</span>
                    <span className="dropdown-item" onClick={this.onUpdateVersion}>{t("version.update")}</span>
                    <span className="dropdown-item" id="logout" onClick={this.handleLogout}>{t("logout")}</span>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default withTranslation()(
  connect(({ user: { userData } }) => ({
    userData,
  }))(UserBar)
);
