// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"primary_color": "#005288",
	"secondary_color": "#005288",
	"success_color": "#005288",
	"danger_color": "#e2142d"
};
module.exports = exports;
