import React, { Component } from "react";
import { toast } from "react-toastify";
import { Button, Modal, ModalBody, ModalHeader, FormGroup } from "reactstrap";
import Alert from "../../../../components/Alert";
import CardList from "../../../../components/CardList";
import apiClient from "../../../../utils/apiClient";
import { Form, Formik } from "formik";
import {
  FormikInput,
  FormikSelect,
  FormikCheckbox,
} from "../../../../components/FormikFields";
import { history } from "../../../../utils/Utils";
import { courseSchema } from "../../../../utils/validationSchema";
import { priceData } from "../../../../utils/Config";

export default class LessonList extends Component {
  constructor() {
    super();
    this.state = {
      courseName: "",
      courseNameInFr: "",
      selectedRowId: "",
      description: "",
      descriptionInFr: "",
      productId: "",
      duration: '',
      category: "",
      price: "",
      priceForProductId: '',
      consoleId: '',

      isCourseFree: false,
      mode: "",
      data: [],
      categoryData: [],
      priceData: [],
      totalCount: 10,
      size: 10,
      page: 1,
      total_page: 1,
    };
  }

  componentDidMount() {
    const pageNumber = localStorage.getItem('page')
    const sizeQuantity = localStorage.getItem('size')
    var page = pageNumber
    var size = sizeQuantity
    this.setState({ page, size }, () => this.getData());
    this.getAdsPrice();
    this.getCoursePrice();
  }

  getAdsPrice = () => {
    apiClient
      .get("category/get-category", { params: { module: "Course" } })
      .then(({ status, data }) => {
        if (status === 200) {
          this.setState({
            categoryData: data.data,
          });
        } else {
          this.setState({
            categoryData: [],
          });
        }
      });
  };

  getCoursePrice = () => {
    apiClient
      .get("dashboard/course-price")
      .then(({ status, data }) => {
        if (status === 200) {
          this.setState({
            priceData: data.data,
          });
        } else {
          this.setState({
            priceData: [],
          });
        }
      });
  };

  handleDelete = (id) => {
    apiClient.delete("course/delete-course/" + id).then(({ status, data }) => {
      if (status === 200) {
        toast.success(data.message);
        this.getData();
      }
    });
  };

  getData = () => {
    const { page, size } = this.state;
    apiClient
      .get("course/getCourse-admin", { params: { page, size } })
      .then(({ status, data: { page, size, data, totalCount } }) => {
        size = size < 10 ? 10 : size;
        if (status === 200) {
          this.setState({
            page,
            size,
            data,
            totalCount,
            total_page: Math.ceil(totalCount / size),
          });
        }
      });
  };

  toggleModal = (mode = "", rowData) => {
    if (rowData) {
      const {
        courseName,
        courseNameInFr,
        description,
        descriptionInFr,
        category,
        price,
        id,
        isCourseFree,
        productId,
        duration,
        consoleId
      } = rowData;
      this.setState({
        selectedRowId: id,
        courseName,
        courseNameInFr,
        description,
        descriptionInFr,
        category,
        price,
        isCourseFree,
        productId,
        duration,
        consoleId
      });
    }
    this.setState({
      mode,
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = ({
    courseName,
    courseNameInFr,
    description,
    descriptionInFr,
    category,
    price,
    duration,
    isCourseFree,
    productId
  }) => {
    let requestInfo = {
      courseName,
      courseNameInFr,
      category,
      description,
      descriptionInFr,
      // price,
      isCourseFree: isCourseFree ? isCourseFree : false,
      // productId
      consoleId: price,
      duration
    };
    const categoryList = this.state.categoryData;
    requestInfo.category = categoryList.find(
      (categoryItem) => categoryItem.name == requestInfo.category
    )._id;
    if (this.state.mode === "edit") {
      apiClient
        .put(`course/update-course/${this.state.selectedRowId}`, requestInfo)
        .then(({ status }) => {
          if (status === 200) {
            this.setState({
              lessions: [],
            });
            this.getData();
            toast.success("Course Updated Successfully");
          }
        });
      this.toggleModal();
    } else {
      apiClient.post("course/create-course", requestInfo).then(({ status }) => {
        if (status === 200) {
          this.setState({
            lessions: [],
          });
          this.getData();
          toast.success("Course Added Successfully");
        }
      });
      this.toggleModal();
      this.props.courseProductId("0000")
    }
  };

  formUI(t) {
    const {
      courseName,
      courseNameInFr,
      description,
      descriptionInFr,
      category,
      price,
      isCourseFree,
      productId,
      duration,
      consoleId
    } = this.state;
    const touchedData = this.state.categoryData.map((item) => ({
      label: item.name,
      value: item.name,
    }));
    const pricedData = this.state.priceData.map((item) => ({
      label: item.price,
      value: item.consoleId,
    }));

    return (
      <div className="add-terminal">
        <div className="fields col-11 mx-auto">
          <Formik
            enableReinitialize
            initialValues={this.state.mode === "add" ? {
              courseName: '',
              courseNameInFr: '',
              description: '',
              descriptionInFr: '',
              category: '',
              price: '',
              isCourseFree: '',
              productId: "",
              duration: "",
              consoleId: ''
            } : {
              courseName,
              courseNameInFr,
              description,
              descriptionInFr,
              category,
              price: pricedData.find(p => p.label == this.state.price).value,
              isCourseFree,
              productId,
              duration,
              consoleId
            }}
            onSubmit={this.handleSubmit}
            validationSchema={courseSchema}
          >
            {({ errors, touched, setFieldTouched, setFieldValue, values }) => (
              <Form className="row">
                <FormGroup className="col-6 px-5 text-center">English</FormGroup>
                <FormGroup className="col-6 px-5 text-center">French</FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("course.name")}
                    name="courseName"
                    disabled={this.state.mode === "view"}
                    value={values.courseName}
                    error={touched.courseName && errors.courseName}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("course.name")}
                    name="courseNameInFr"
                    disabled={this.state.mode === "view"}
                    value={values.courseNameInFr}
                    error={touched.courseNameInFr && errors.courseNameInFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("course.Description")}
                    name="description"
                    multiline={true}
                    disabled={this.state.mode === "view"}
                    value={values.description}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-5">
                  <FormikInput
                    label={t("course.Description")}
                    name="descriptionInFr"
                    multiline={true}
                    disabled={this.state.mode === "view"}
                    value={values.descriptionInFr}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikSelect
                    label={t("course.category")}
                    name="category"
                    disabled={this.state.mode === "view"}
                    error={touched.category && errors.category}
                    value={values.category}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={touchedData}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikSelect
                    label={t("price.doller")}
                    name="price"
                    disabled={this.state.mode === "view"}
                    price
                    error={touched.price && errors.price}
                    value={values.price}
                    // required={!values.isCourseFree}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    options={pricedData}
                  />
                </FormGroup>
                <FormGroup className="col-6 px-4">
                  <FormikInput
                    label={t("price.id")}
                    name="productId"
                    disabled={this.state.mode === "view" || this.state.mode === "edit" || this.state.mode === "add"}
                    value={values.productId = this.props.productId || values.productId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <FormikInput
                    label={t("ad.duration")}
                    name="duration"
                    disabled={this.state.mode === "view" || this.state.mode === "edit" || this.state.mode === "add"}
                    value={
                      values.duration = this.props.duration || values.duration
                    }
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                  />
                </FormGroup>
                <FormGroup className="col-12 px-4">
                  <div className="col-12 mt-3">
                    <FormikCheckbox
                      name="isCourseFree"
                      label={t("course.free")}
                      value={values.isCourseFree}
                      onChange={setFieldValue}
                      onBlur={setFieldTouched}
                    />
                  </div>
                </FormGroup>
                {this.state.mode == 'add' ? values.price && values.price.length > 1 && this.props.courseProductId(values.price) : this.props.courseProductId(values.consoleId) || values.price && values.price.length > 5 && this.props.courseProductId(values.price)}
                {/* {console.log("console" + this.props.duration)} */}
                {/* {console.log("console" + values)} */}
                {/* {console.log("price" + values.duration)} */}
                {/* {console.log("duration" + values.duration)} */}
                {/* {console.log(this.state.price)} */}
                {/* {console.log("price" + values.price.length)} */}
                {/* {this.state.mode == 'add' && values.price && values.price.length > 1 && this.props.courseProductId(values.price)} */}
                {/* {this.state.mode == 'edit' && values.price && values.price.length > 1 && this.props.courseProductId(values.price)} */}
                {this.state.mode !== "view" && (
                  <>
                    <div className="col-12 d-flex my-4">
                      <Button
                        type="submit"
                        color="success"
                        className="my-4 mx-auto"
                      >
                        {this.state.mode === "edit"
                          ? t("course.update")
                          : t("course.add")}
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    );
  }

  onPageChange = (page, size) => {
    history.push(`${page}`)
    localStorage.setItem('page', page)
    localStorage.setItem('size', size)
    this.setState({ page, size }, () => this.getData());
  };
  onClose = () => {
    this.toggleModal()
    this.props.courseProductId("0000")
  }

  render() {
    const { mode, data, size, total_page, page, totalCount } = this.state;
    const { label = "", t } = this.props;
    const selectedLanguage = localStorage.getItem('selectedLanguage')
    return (
      <>
        <Modal
          className="course-modal"
          isOpen={Boolean(mode)}
          toggle={() => this.toggleModal()}
        >
          <ModalHeader toggle={() => this.onClose()}>
            {mode
              ? mode === "add"
                ? "Add New " + label
                : mode === "edit"
                  ? "Edit " + label
                  : "View " + label
              : ""}
          </ModalHeader>
          <ModalBody>
            <div className="fields">{mode && <>{this.formUI(t)}</>}</div>
          </ModalBody>
        </Modal>
        <CardList
          containerClassName="mx-5 mb-5 mt-4"
          title={label}
          subHeaderComponent={
            <Button
              className="ml-auto"
              color="success"
              onClick={() => this.toggleModal("add")}
            >
              + {t("course.add")}
            </Button>
          }
          column={[
            { title: t("course.name"), field: selectedLanguage == 'en' ? "courseName" : "courseNameInFr" },
            { title: t("course.category"), field: "category" },
            { title: t("course.Description"), field: selectedLanguage == 'en' ? "description" : "descriptionInFr" },
          ]}
          data={data}
          actions={[
            {
              icon: "fi flaticon-view-1",
              className: "btn-white text-primary p-0",
              onClick: (rowData) =>
                this.toggleModal("view", rowData) && this.formUI(),
            },
            {
              icon: "fi flaticon-edit",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => this.toggleModal("edit", rowData),
            },
            {
              icon: "fi flaticon-delete-1",
              className: "btn-white text-danger p-0",
              onClick: (rowData) =>
                Alert.info(
                  t("delete.msg"),
                  t("lesson.deletemsg") + `${label}`,
                  () => this.handleDelete(rowData.id)
                ),
            },
            {
              icon: "fi flaticon-question",
              className: "btn-white text-primary p-0",
              onClick: (rowData) =>
                history.push(`${page}/quiz-questions/${rowData.id}`),
            },
            {
              icon: "fi flaticon-email-2",
              tooltip: "View Lessions",
              className: "btn-white text-primary p-0",
              onClick: (rowData) => history.push(`${page}/lesson/${rowData.id}`),
            },
          ]}
          handlePageChange={this.onPageChange}
          options={{
            subHeader: false,
            isCollapsable: true,
            expandActions: true,
            pagination: {
              showPagination: true,
              pageSelection: true,
              total_page,
              page,
              size,
              totalCount,
            },
          }}
          key={data && data.id}
        />
      </>
    );
  }
}
