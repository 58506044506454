import React, { useState } from "react";
import StudentFaqList from './students/FaqList'
import CompanyFaqList from './companies/FaqList'
import { useTranslation } from "react-i18next";
import TabNav from '../../../components/TabNav'
import Tab from '../../../components/Tab'



export default function PaymentHistory({ heading }) {
  const { t } = useTranslation();
  const [selected, setselected] = useState(t("student.Student"))

  const setSelected = (tab) => {
    setselected(tab)
  }

  return (
    <div className="subscription-management">
      <span className="page-header">Faq</span>
      <TabNav tabs={[t("student.Student"), t("company.company")]} selected={selected} setSelected={setSelected}>
        <Tab isSelected={selected === t("student.Student")}>
          <StudentFaqList type="terminals" label="Faq" t={t} />
        </Tab>
        <Tab isSelected={selected === t("company.company")}>
          <CompanyFaqList type="terminals" label="Faq" t={t} />
        </Tab>
      </TabNav>
    </div>
  );
}
